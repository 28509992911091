import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Collapse, Skeleton } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { getFaq } from '../../actions/settings';
import { useDispatch } from 'react-redux';
import { CaretRightOutlined } from '@ant-design/icons';
import notFound from "../../assets/img/not-found.png";
import parse from 'html-react-parser';

const { Content } = Layout;
const { Panel } = Collapse;

function Faq() {
    const dispatch = useDispatch();
    const [faq, setFaq] = useState('')
    const [loading, setLoading] = useState(false);

    function getData() {
        setLoading(true)
        dispatch(getFaq({
            successCb: async (response) => {
                setFaq(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setFaq('')
                setLoading(false)
            }
        }))
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="">
                            <Col className="gutter-row pb-4" xs={24} lg={21}>
                                <div>
                                    <h5 className='color-theme semi-bold'>FAQ</h5>
                                </div>
                            </Col>
                            <Col className="gutter-row pb-2" xs={24} lg={18}>
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={['0']}
                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    className="site-collapse-custom-collapse"
                                >
                                    {faq.length > 0 && faq ? faq.map((element, index) => {
                                        return (
                                            <Panel header={"Question: " + element.question} key={index} className="site-collapse-custom-panel">
                                                <div className='ps-4'>
                                                    <span>Answer: </span> {parse(element.answer)}
                                                </div>
                                            </Panel>
                                        )
                                    }) :
                                        <Row className='center pt-5 mt-5 '>
                                            <Col className='col-12 text-center'>
                                                <img src={notFound} style={{ width: "300px" }} />
                                                <h5 className='pt-5' >No faq found!</h5>
                                            </Col>
                                        </Row>
                                    }
                                </Collapse>
                            </Col>
                        </Row>
                    </Skeleton>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Faq