import {
    VideoCameraOutlined,
    UserOutlined,
    CreditCardOutlined,
    BookOutlined,
    CopyOutlined,
    BellOutlined,
    PhoneOutlined,
    LogoutOutlined,
    ShopFilled,
    CoffeeOutlined,
    DiffOutlined,
    CrownOutlined,
    ProfileOutlined,
    SwapOutlined,
    UndoOutlined,
    CloseCircleOutlined,
    IdcardOutlined,
    CommentOutlined,
    InfoCircleFilled
} from '@ant-design/icons';
import { Layout, Menu, Spin, Drawer, Button, Row, Col, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo/logo.gif'
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { updateProfile } from '../../actions/settings';
import { drawerEvent } from '../../actions/users';
const { Sider } = Layout;

const Sidebar_ = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const userType = JSON.parse(sessionStorage.getItem('user_'));
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const drawerType = useSelector(state => state?.users?.open);
    const readCountChat = useSelector(state => state?.users?.chatcounter);


    useEffect(() => {
        if (drawerType === 'open') {
            setOpen(true)
        }
    }, [])

    const onClick = (e) => {
        let data = {
            open: "close"
        }
        dispatch(drawerEvent({
            data
        }))

        if (e.key === 'switch') {
            setLoading(true);
            let data = {
                special_previlage: userType.special_previlage ? false : true
            }
            //////
            dispatch(updateProfile({
                data,
                successCb: async (response) => {
                    addToast(response.message, { appearance: 'success', autoDismiss: true });
                    sessionStorage.setItem('user_', JSON.stringify(response.data))
                    setTimeout(() => {
                        setLoading(false);
                    }, 5000);
                },
                failureCb: (response) => {
                    addToast(response.message, { appearance: 'error', autoDismiss: true });
                }
            }))
        }
        else {
            document.location.href = "#" + e.key
        }
    };

    const onClose = () => {
        setOpen(false);
        let data = {
            open: "close"
        }
        dispatch(drawerEvent({
            data
        }))
    };

    return (
        <>
            <Drawer
                className='dnone'
                title="Basic Drawer"
                placement="left"
                closable={false}
                open={drawerType === 'open' ? true : false}
                key={placement}
                extra={
                    <Space>
                        <CloseCircleOutlined onClick={onClose} style={{ fontSize: "22px" }} />
                    </Space>
                }
            >
                <Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                    }}
                    trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo pt-4 pb-4 ps-2 pe-2">
                        <a href='#'>
                            <img src={logo} />
                        </a>
                    </div>

                    <Menu
                        className='pt-3 sider_'
                        theme="light"
                        mode="inline"
                        onClick={onClick}
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: 'events',
                                icon: <VideoCameraOutlined />,
                                label: 'events',
                            },
                            {
                                key: 'membership',
                                icon: <CrownOutlined />,
                                label: 'Membership',
                            },
                            // userType.user_type === "0" ? {
                            //     key: 'account-details',
                            //     icon: <IdcardOutlined />,
                            //     label: 'Account Details',
                            // } : "",
                            // userType.user_type === "2" ? {
                            //     key: 'switch',
                            //     icon: <SwapOutlined />,
                            //     label: <Spin spinning={loading}>{userType.special_previlage ? 'Switch To Vendor' : 'Switch To Merchant'}</Spin>,
                            // } : "",
                            {
                                key: 'card',
                                icon: <CreditCardOutlined />,
                                label: 'Card',
                            },
                            {
                                key: 'favorite',
                                icon: <BookOutlined />,
                                label: 'Favorites',
                            },
                            userType.user_type === "1" || userType.special_previlage ? {
                                key: 'stores',
                                icon: <ShopFilled />,
                                label: 'Stores',
                            } : "",
                            userType.user_type === "1" || userType.special_previlage ? {
                                key: 'offers',
                                icon: <CoffeeOutlined />,
                                label: 'Add Offers',
                            } : "",
                            userType.user_type === "1" || userType.special_previlage ? {
                                key: 'event-history',
                                icon: <UndoOutlined />,
                                label: 'Event History',
                            } : "",
                            {
                                key: 'chat',
                                icon: <CommentOutlined />,
                                label: 'Chat',
                            },
                            {
                                key: 'notification',
                                icon: <BellOutlined />,
                                label: 'Notification',
                            },
                            {
                                key: 'profile',
                                icon: <UserOutlined />,
                                label: 'Profile',
                            },
                            {
                                key: 'terms',
                                icon: <CopyOutlined />,
                                label: 'Terms & Conditions',
                            },
                            {
                                key: 'privacy',
                                icon: <DiffOutlined />,
                                label: 'Privacy Policy',
                            },
                            {
                                key: 'faq',
                                icon: <ProfileOutlined />,
                                label: 'FAQ',
                            },
                            {
                                key: 'contact-us',
                                icon: <PhoneOutlined />,
                                label: 'Contact Us',
                            },
                            {
                                key: '8',
                                icon: <LogoutOutlined />,
                                label: 'Logout',
                            },
                        ]}
                    />
                </Sider>
            </Drawer>
            {/* ////for desktop */}
            <Sider
                className='mnone'
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                trigger={null} collapsible collapsed={collapsed}>
                <div className="logo pt-4 pb-4 ps-2 pe-2">
                    <a href='#'>
                        <img src={logo} />
                    </a>
                </div>
                <Menu
                    className='pt-3 sider_'
                    theme="light"
                    mode="inline"
                    onClick={onClick}
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: 'events',
                            icon: <VideoCameraOutlined />,
                            label: 'events',
                        },
                        {
                            key: 'membership',
                            icon: <CrownOutlined />,
                            label: 'Membership',
                        },
                        // userType.user_type === "0" ? {
                        //     key: 'account-details',
                        //     icon: <IdcardOutlined />,
                        //     label: 'Account Details',
                        // } : "",
                        // userType.user_type === "2" ? {
                        //     key: 'switch',
                        //     icon: <SwapOutlined />,
                        //     label: <Spin spinning={loading}>{userType.special_previlage ? 'Switch To Vendor' : 'Switch To Merchant'}</Spin>,
                        // } : "",
                        {
                            key: 'card',
                            icon: <CreditCardOutlined />,
                            label: 'Card',
                        },
                        {
                            key: 'favorite',
                            icon: <BookOutlined />,
                            label: 'Favorites',
                        },
                        userType.user_type === "1" || userType.special_previlage ? {
                            key: 'stores',
                            icon: <ShopFilled />,
                            label: 'Stores',
                        } : "",
                        userType.user_type === "1" || userType.special_previlage ? {
                            key: 'offers',
                            icon: <CoffeeOutlined />,
                            label: 'Add Offers',
                        } : "",
                        userType.user_type === "1" || userType.special_previlage ? {
                            key: 'event-history',
                            icon: <UndoOutlined />,
                            label: 'Event History',
                        } : "",
                        {
                            key: 'chat',
                            icon: <CommentOutlined />,
                            label: <span>Chat{readCountChat>0?<span>&nbsp;&nbsp;&nbsp;<InfoCircleFilled /></span>:""}</span>,
                        },
                        {
                            key: 'profile',
                            icon: <UserOutlined />,
                            label: 'Profile',
                        },
                        {
                            key: 'terms',
                            icon: <CopyOutlined />,
                            label: 'Terms & Conditions',
                        },
                        {
                            key: 'privacy',
                            icon: <DiffOutlined />,
                            label: 'Privacy Policy',
                        },
                        {
                            key: 'faq',
                            icon: <ProfileOutlined />,
                            label: 'FAQ',
                        },
                        {
                            key: 'contact-us',
                            icon: <PhoneOutlined />,
                            label: 'Contact Us',
                        },
                        {
                            key: '8',
                            icon: <LogoutOutlined />,
                            label: 'Logout',
                        },
                    ]}
                />
                {/* <div className="wrapper">
                    <div className="wave"></div>
                </div> */}
            </Sider>

        </>
    );
};

export default Sidebar_;