import React, { useState, useEffect } from 'react';
import { Layout, Button, Card, Menu, Row, Col, List, Input, Space, Spin, Form, Skeleton } from 'antd';
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled, PushpinFilled } from '@ant-design/icons';
import logo from '../assets/img/logo/logo-1.gif'
import logoWhite from '../assets/img/logo/logo-white.png'
import appStore from '../assets/img/icons/app-store.png'
import google from '../assets/img/icons/google.png'
import { getHomeData, sendContact } from '../actions/home';
import { getTermsPrivacy } from '../actions/settings';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { fileUrl } from "../constants/const";
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;
const { Meta } = Card;

function Home() {
    const [loading, setLoading] = useState(false);
    const [home, setHome] = useState('')
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [fields, setFields] = useState([]);
    const [terms, setTerms] = useState('')

    function getData() {
        setLoading(true);
        dispatch(getHomeData({
            successCb: async (response) => {
                setHome(response.data)
                setLoading(false);
                getTermsData();
            },
            failureCb: (response) => {
                setHome('')
                setLoading(false);
            }
        }))
    }


    function getTermsData() {
        dispatch(getTermsPrivacy({
            successCb: async (response) => {
                setTerms(response.data)
            },
            failureCb: (response) => {
                // addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const onFinish = (values) => {
        setLoading(true);
        let data = {
            email: values.email,
            name: values.full_name,
            message: values.message
        }
        dispatch(sendContact({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setFields([
                    {
                        name: ['email'],
                        value: "",
                    },
                    {
                        name: ['full_name'],
                        value: "",
                    },
                    {
                        name: ['message'],
                        value: "",
                    },
                ])
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    };

    function gotoSelection(id) {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        let currentURL = window.location.href;
        // Check if the URL contains the parameter 'payment_intent'
        if (currentURL.includes('payment_intent=')) {
            // Close the current window
            setTimeout(() => {
                window.close();
                console.log('Window is now closed because payment_intent parameter exists in the URL');    
            }, 1000);        
        }
    }, [])

    return (
        <Layout className="layout">
            <Content>
                <Skeleton loading={loading} active avatar style={{ padding: "15rem" }}>
                    <div className='container top-head pt-4 pb-5'>
                        <Row>
                            <Col lg={8} xs={16}>
                                <div className="logo">
                                    <a href='#'>
                                        <img src={logo} />
                                    </a>
                                </div>
                            </Col>
                            <Col lg={16} xs={8}>
                                <Menu
                                    theme="light"
                                    mode="horizontal"
                                    selectable={true}
                                    defaultSelectedKeys={['1']}
                                    style={{ "justifyContent": "end" }}

                                >
                                    <Menu.Item key="1">Home</Menu.Item>
                                    <Menu.Item key="2" onClick={() => gotoSelection('about')}>About Us</Menu.Item>
                                    <Menu.Item key="3">
                                        <a href="https://blog.4cornerconcepts.com/" target="_blank">Blog</a>
                                    </Menu.Item>
                                    <Menu.Item key="4" onClick={() => gotoSelection('team')}>Our Team</Menu.Item>
                                    <Menu.Item key="5" onClick={() => gotoSelection('contact')}>Contact Us</Menu.Item>
                                    <Menu.Item key="6">
                                        <a href="#login">
                                            <Button type="primary" size='large' >SIGN IN</Button>
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </Col>
                        </Row>
                        <Row className='pt-lg-5 pb-lg-5 pt-md-2 pt-xs-2'>
                            {home.bannersTop?.length > 0 && home.bannersTop?.map((element, index) => {
                                return (
                                    <>
                                        <Col lg={6} xs={24} className="mt-4 pt-3">
                                            <div className="card blur-box head-card p-3">
                                                <div className="card-body pe-lg-5 pe-xs-0">
                                                    {parse(home ? element.title : "")}
                                                    <div>
                                                        {parse(home ? element.description : "")}
                                                        <a href="#">
                                                            <Button type="primary" size='large' >LEARN MORE</Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={18} xs={24}>
                                            <img src={fileUrl + element.image}></img>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </div>

                    {/* about section */}

                    <section className='section bg-white pb-5' id="about">
                        <div className='container'>
                            <Row>
                                <Col lg={10} xs={24}>
                                    <div className='mb-lg-5 pb-lg-5 mb-xs-4 pb-xs-3'>
                                        {home ? parse(home.bannersMiddle?.[0]?.title) : ''}
                                        {home ? parse(home.bannersMiddle?.[0]?.description) : ""}
                                        <div>
                                            <h6 className='color-theme'>Read More</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2}></Col>
                                <Col lg={12} xs={24}>
                                    <div className='text-center mobile_'>
                                        <img src={fileUrl + home.bannersMiddle?.[0].image} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    {/* our team */}

                    <section className='section bg-white pt-0' id="team">
                        <div className='container'>
                            <div className='pt-3 text-team'>
                                <h2>
                                    <span className='color-primary'>Our </span>
                                    <span className='color-theme'>Team</span>
                                </h2>
                            </div>
                            <Row gutter={24}>
                                {home.team?.length > 0 && home.team?.map((element, index) => {
                                    return (
                                        <>
                                            <Col className="gutter-row mb-4" lg={8} md={12} xs={24}>
                                                <Card
                                                    hoverable
                                                    cover={<div alt="team" style={{
                                                        background: 'url("' + fileUrl + element.image + '")', height: "400px"
                                                        , backgroundSize: "cover", backgroundPosition: "center", width: "100%"
                                                    }} />}
                                                >
                                                    <Meta title={element.title} description={element.description} style={{ minHeight: "100px" }} />
                                                </Card>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </div>
                    </section>

                    {/* membership plan */}

                    <section className='section memb_'>
                        <div className='container membership'>
                            <Row gutter={24}>
                                <Col className="gutter-row pb-4" lg={24}>
                                    <h2 className='pt-3 text-center'>
                                        <span className='color-primary'>Choose your Membership </span>
                                        <span className='color-theme'>Plan</span>
                                    </h2>
                                    <h4 className='text-center'>Subscribe & get exclusive offers</h4>
                                </Col>
                                <Col className="gutter-row mb-4" lg={8} xs={24}>
                                    <Card
                                        hoverable
                                    >
                                        <h3>{home?.vendor?.title}</h3>
                                        <List
                                            itemLayout="horizontal"
                                        >
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={parse(home ? home?.vendor?.description : "")}
                                                />
                                            </List.Item>
                                        </List>
                                        <div className='text-center pb-4'>
                                            <a href="#login">
                                                <Button type="primary" size='large' className='col-6 member-btn' >GET STARTED</Button>
                                            </a>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className="gutter-row middle-membership mb-4" lg={8} xs={24}>
                                    <Card
                                        hoverable
                                    >
                                        <h3>Merchant</h3>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={parse(home ? home?.merchant?.description : "")}
                                            />
                                        </List.Item>
                                        <div className='text-center pb-4'>
                                            <a href="#login">
                                                <Button type="primary" size='large' className='col-6 member-btn' >GET STARTED</Button>
                                            </a>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <Card
                                        hoverable
                                    >
                                        <h3>Producer</h3>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={parse(home ? home?.producer?.description : "")}
                                            />
                                        </List.Item>
                                        <div className='text-center pb-4'>
                                            <a href="#login">
                                                <Button type="primary" size='large' className='col-6 member-btn' >GET STARTED</Button>
                                            </a>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    {/* download app section */}

                    <section className='section'>
                        <div className='container'>
                            <Row>
                                <Col lg={8} xs={24}>
                                    <div className='mb-5 pb-5 pt-lg-5 mt-lg-5 pt-xs-1'>
                                        <h2 className='pt-3'>
                                            <span className='color-download'> {home ? home?.app[1]?.title : ""} </span>
                                        </h2>
                                        <p className='text-light-gray'>
                                            {home ? parse(home?.app[1]?.description) : ""}
                                        </p>
                                        <Row gutter={24} className="pt-3">
                                            <Col className="gutter-row" span={12}>
                                                <img src={appStore} />
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <img src={google} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={16} xs={24}>
                                    <div className='text-center mobiles_'>
                                        <img src={home ? fileUrl + home?.app[1]?.image : ""} className="floating" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    {/* contact us section */}

                    <section className='section bg-white' id="contact">
                        <div className='container'>
                            <Row>
                                <Col lg={24} xs={24}>
                                    <div className='mb-5  text-center '>
                                        <h2 className='pt-3'>
                                            <span className='color-primary'>Get in Touch with  </span>
                                            <span className='color-theme'>Us!</span>
                                        </h2>
                                        <p className='text-light-gray'>
                                            Fill this form and our team will get back to you within 24 hours.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col className="gutter-row" lg={12} xs={24}>
                                    <div className='mb-5 pb-5 '>
                                        <Card className='p-3 contact-card'>
                                            <h3 className='pt-3'>
                                                <span className='color-download'>Contact Us  </span>
                                            </h3>
                                            <Form
                                                fields={fields}
                                                name="normal_login"
                                                className="pt-3"
                                                onFinish={onFinish}
                                            >
                                                <Form.Item
                                                    name="full_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Full Name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Full Name" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Email address!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Email address" />
                                                </Form.Item>

                                                <Form.Item
                                                    name="message"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Message!',
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea style={{ height: "100px" }} placeholder="Message" />
                                                </Form.Item>

                                                <Form.Item className='pe-5 ps-5 mt-5'>
                                                    <Spin spinning={loading}>
                                                        <Button type="primary" htmlType="submit" style={{ height: "55px" }} className="login-form-button col-lg-12">
                                                            Submit
                                                        </Button>
                                                    </Spin>
                                                </Form.Item>
                                            </Form>
                                        </Card>
                                    </div>
                                </Col>
                                <Col className="gutter-row" lg={12} xs={24}>
                                    <div className='mb-5 pb-5 mt-3'>
                                        <div className='mb-5'>
                                            <h4><strong>Address</strong>:</h4>
                                            <h5>{terms?.address}</h5>
                                        </div>
                                        <div className='mb-5'>
                                            <h4><strong>Email:</strong></h4>
                                            <h5>{terms?.email}</h5>
                                        </div>
                                        <div className='mb-3'>
                                            <h4><strong>Follow Us:</strong></h4>
                                            <Space className='pt-3'>
                                                <a href={terms?.facebook}>
                                                    <FacebookFilled style={{ fontSize: "30px", color: "#4D2424", cursor: "pointer" }} />
                                                </a>
                                                <a href={terms?.twitter}>
                                                    <TwitterSquareFilled style={{ fontSize: "30px", color: "#4D2424", cursor: "pointer" }} />
                                                </a>
                                                <a href={terms?.linkedin}>
                                                    <LinkedinFilled style={{ fontSize: "30px", color: "#4D2424", cursor: "pointer" }} />
                                                </a>
                                                <a href={terms?.pinterest}>
                                                    <PushpinFilled style={{ fontSize: "30px", color: "#4D2424", cursor: "pointer" }} />
                                                </a>
                                            </Space>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>

                    {/* footer section */}

                    <section className='section footer pb-2'>
                        <div className='container'>
                            <Row className='footer-menu '>
                                <Col className="text-center col-12">
                                    <ul className='center'>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Why Us</a></li>
                                        <li><a href="#">Contact Us</a></li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="center col-12 logo d-flex pt-5 pb-5">
                                    <div className="logo">
                                        <a href='#'>
                                            <img src={logoWhite} />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <section className='section footer pt-3 pb-0 border-top'>
                        <div className='container'>
                            <Row>
                                <Col className="text-center pb-0 col-12">
                                    <p className='text-white'>© The Cornerstone- 2022</p>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </Skeleton>
            </Content>
        </Layout>
    )
}

export default Home