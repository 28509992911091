import { USER_DETAILS, LOGOUT_SUCCESS, DRAWER_LIST, CHAT_NOTI } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_DETAILS:
            return {
                ...state,
                ...payload,
                ...action.payload,
                isLoggedIn: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };

        case DRAWER_LIST:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: true,
            };
        case CHAT_NOTI:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: true,
            };

        default:
            return state
    }
}