import React, { useState } from 'react';
import { Layout, Button, Form, Input, Row, Col, Spin } from 'antd';
import logo from '../../assets/img/logo/ice-logo.svg'
import bg from '../../assets/img/bg/verification.png'
import { verifyUser, resendOtpNow } from '../../actions/auth';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';

const { Content } = Layout;

function Verification() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const gotEmail = useSelector(state => state.authreducer.data.email);
    const authType = useSelector(state => state.authreducer.type);
    const [otp, setOtp] = useState('');
 
    const onFinish = (values) => {
        setLoading(true);
        let data = {
            email: gotEmail,
            otp
        }
        dispatch(verifyUser({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });
                window.location.href = authType === 'register' ? '#login' : '#reset-password'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    };

    function resendOtp() {
        setLoading(true);
        let data = {
            email: gotEmail
        }
        dispatch(resendOtpNow({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });
                setOtp('')
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }


    return (
        <Layout className="layout">
            <Content>

                {/* verification section */}

                <div className='container-fluid'>
                    <Row gutter={24} className="bg-white" style={{ height: "100vh" }}>
                        <Col className="gutter-row background-theme mnone" lg={12} xs={24}>
                            <div className='auth-bg mt-5 ball text-center'>
                                <img src={bg} style={{ width: "250px" }} />
                            </div>
                        </Col>
                        <Col className="gutter-row auth xs-plr-1 xs-mt-0 p-5 mt-5" lg={12} xs={24}>
                            <div className="text-center center d-flex pb-5 pt-5">
                                <div className="logo">
                                    <a href='#'>
                                        <img style={{ height: "150px" }} src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4><strong>Verification</strong></h4>
                                <p className='text-gray'>Please enter the 4-digit verification code sent on your email ID</p>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form pt-3 pe-5 ps-5"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >
                                <Row gutter={24} className="center verification">

                                    <Col className="gutter-row text-center jus_"  lg={24}>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                            renderInput={(props) => <input {...props} />}
                                            className="ant-input"
                                            // placeholder='****'
                                            

                                        />
                                    </Col>

                                    </Row>
                                    <Row gutter={24} className="center verification pt-2">
                                    <Col className="gutter-row" lg={24}>
                                        <div>
                                            <p onClick={() => resendOtp()} className="login-form-button col-lg-2 pull-right text-right pointer color-theme">
                                                RESEND
                                            </p>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" lg={24}>
                                        <Form.Item className=' '>

                                            <Spin spinning={loading}>
                                                <Button type="primary" htmlType="submit" className="login-form-button col-lg-12 mt-1 pt-4 pb-5">
                                                    NEXT
                                                </Button>
                                            </Spin>
                                        </Form.Item>
                                    </Col>

                                </Row>



                            </Form>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default Verification