import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button, DatePicker, Radio, Select } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { getOfferCategories, getOfferSubCategories ,createOffer} from '../../actions/offers';
import moment from 'moment';
import timezone from 'moment-timezone';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

function AddOffer() {
    const [loading, setLoading] = useState(false);
    const [dateType, setDateType] = useState("");
    const [categories, setCategories] = useState("");
    const [categoriesId, setCategoriesId] = useState("");
    const [subCategories, setSubCategories] = useState("");
    const [subCategoriesId, setSubCategoriesId] = useState("");
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [defaultNo, setdefaultNo] = useState('0');
    const [timeUse, setTimeUse] = useState([]);
    const [fields, setFields] = useState([]);

    let timeZ = timezone.tz.guess();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const onChangeDateType = (e) => {
        setDateType('0');
        setFields([
            {
                name: ['end_date'],
                value: "",
            }
        ])     
    };

    const onChangeTimeUse = (e) => {       
        setTimeUse(e.target.value);
    };

    const selectCategory = (id) => {
        setCategoriesId(id);
        getSubCategories(id);
    };

    const selectSubCategory = (id) => {
        setSubCategoriesId(id);
    };

    ////calander part
    const onChangeStartTime = (value, dateString) => {
        setStartTime(dateString)        
    };

    const onChangeEndTime = (value, dateString) => {
        if (dateString === "") {            
            setEndTime('')
            setDateType('');
            setFields([
                {
                    name: ['end_date'],
                    value: "",
                }
            ])
        }
        else {
            setEndTime(dateString)
            setDateType(dateString)
        }
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    ////calander part end

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const onFinish = (value) => {   
        // if(endTime==="" && dateType===""){
        //     addToast("please select one end date!", { appearance: 'error', autoDismiss: true });
        //     return;
        // }
        if(value.discount < 15){
            addToast("discount value should be greater then 15", { appearance: 'error', autoDismiss: true });
            return;
        }
        
        const formData = new FormData();
        formData.append('discount', value.discount);
        formData.append('description', value.offer_detail);
        formData.append('sub_tag_id', value.sub_tag_id);
        formData.append('tag_id', value.tag_id);
        formData.append('time_zone', timeZ);
        formData.append('valid_from', startTime);
        formData.append('valid_till', endTime === "" ? "" :  endTime);
        formData.append('type', timeUse);
        formData.append('custom_tag', 0);
        formData.append('custom_sub_tag', 0);
        
        setLoading(true);
        dispatch(createOffer({
            formData,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#offers'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const getCategories = () => {
        dispatch(getOfferCategories({
            successCb: async (response) => {
                setCategories(response.data);
            },
            failureCb: (response) => {
                //////
            }
        }))
    }

    const getSubCategories = (id) => {
        dispatch(getOfferSubCategories({
            id,
            successCb: async (response) => {
                setSubCategories(response.data);
            },
            failureCb: (response) => {
                //////
            }
        }))
    }

    useEffect(() => {
        getCategories();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////Offer create section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" xs={24} lg={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Add Offer</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" xs={24} lg={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5"
                                initialValues={{
                                    remember: true,
                                }}
                                getValueFromEvent={normFile}
                                onFinish={onFinish}
                                valuepropname="fileList"
                                fields={fields}
                            >
                                <Form.Item
                                    name="tag_id"
                                    rules={[{
                                        required: true,
                                        message: 'Please select category!',
                                    }]}
                                >
                                    <Select
                                        defaultValue="Select Category"
                                        className='col-12'
                                        style={{
                                            background: "#F6F6F6",
                                        }}
                                        onChange={selectCategory}
                                    >
                                        {categories.length > 0 && categories ? categories.map((element, index) => {
                                            return (
                                                <Option value={element._id}>{element.name}</Option>
                                            )
                                        }) :
                                            <Option value="">Select Category</Option>
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="sub_tag_id"
                                    rules={[{
                                        required: true,
                                        message: 'Please select sub category!',
                                    }]}
                                >
                                    <Select
                                        defaultValue="Select sub category"
                                        className='col-12'
                                        style={{
                                            background: "#F6F6F6",
                                        }}
                                        onChange={selectSubCategory}
                                    >
                                        {subCategories.length > 0 && subCategories ? subCategories.map((element, index) => {
                                            return (
                                                <Option value={element._id}>{element.name}</Option>
                                            )
                                        }) :
                                            <Option value="">Select sub category</Option>
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                 name="start_date"
                                 rules={[{
                                     required: true,
                                     message: 'Enter start date',
                                 }]}
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={disabledDate}
                                        onChange={onChangeStartTime}
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm'),
                                        }}
                                        placeholder="Start Date"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="end_date"                                 
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={disabledDate}
                                        onChange={onChangeEndTime}
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm'),
                                        }}
                                        placeholder="End Date"
                                    />
                                </Form.Item>
                                <input
                                    type="radio"
                                    value="0"                                   
                                    className='mb-4'
                                    onChange={onChangeDateType}
                                    checked={dateType === "" ? "checked" : "" }
                                />&nbsp;&nbsp;<strong>No End Date</strong>

                                {/* <Radio.Group onChange={onChangeDateType} className="mb-4">
                                    <Radio value={"0"}>No End Date</Radio>
                                </Radio.Group> */}

                                <Form.Item
                                    name="discount"
                                    rules={[{
                                        required: true,
                                        message: 'Enter Discount In % / Description',
                                    }]}
                                >
                                    <Input placeholder="Enter Discount In % / Description" />
                                </Form.Item>

                                <Form.Item
                                    name="offer_detail"
                                    rules={[{
                                        required: true,
                                        message: 'Enter offer detail',
                                    }]}
                                >
                                    <Input placeholder="Enter Details" />
                                </Form.Item>

                                <Radio.Group defaultValue={"1"} onChange={onChangeTimeUse} value={timeUse} className="mb-4">
                                    <Radio value={"1"}>One time use</Radio>
                                    <Radio value={"0"}>Multiple time use</Radio>
                                </Radio.Group>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Col className="gutter-row text-center" lg={24}>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>CREATE</Button>
                                        </Col>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default AddOffer