import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, Upload, Checkbox, Select } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { HighlightOutlined } from '@ant-design/icons';
import { fetchUserData, updateProfile } from '../../actions/users';
import { getCategoriesData, getSubCategoriesData } from '../../actions/auth';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import { useToasts } from 'react-toast-notifications';
import { googleKey } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import firebase from 'firebase';
import TextArea from 'antd/lib/input/TextArea';
import AutoComplete from "react-google-autocomplete";

const { Content } = Layout;
const { Option } = Select;

function ProfileUpdate() {
    const [updateForm] = Form.useForm();
    const [profile, setProfile] = useState(0);
    const dispatch = useDispatch();
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [locationName, setLocationName] = useState('');
    const [locations, setLocations] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumberInput] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [catId, setcatId] = useState('');
    const [catSubId, setcatSubId] = useState('');
    const [catData, setCatData] = useState([]);
    const [catSubData, setSubCatData] = useState([]);
    const { addToast } = useToasts();

    const firebaseConfig = {
        apiKey: "AIzaSyABkwzAPHGydveK2dx21sKWxH-hV5ci_lk",
        authDomain: "thecorner-stone.firebaseapp.com",
        projectId: "thecorner-stone",
        storageBucket: "thecorner-stone.appspot.com",
        messagingSenderId: "350307653009",
        appId: "1:350307653009:web:56843f8a22d660dbd2b630",
        measurementId: "G-4F5SFHTDX5"
    };
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
    var db = firebase.firestore();

    const profileDetails = async () => {
        let data = {}
        dispatch(fetchUserData({
            data,
            successCb: (response) => {
                let list = response?.data;
                setProfile(list);
                setPhoneNumberInput(list?.phone_number)
                let locate = {
                    "cordinates":
                    {
                        "lat": list?.location?.cordinates?.lat,
                        "long": list?.location?.cordinates?.long
                    },
                    "name": list?.location?.name
                }
                setLocations(locate)
                setLocationName(list?.location?.name)

                if (list?.user_type === '1' && list?.category_id?._id === '630f2e8252de247cce6c76a4') {
                    selectCategory("630f2e8252de247cce6c76a4")
                    setcatSubId(list?.subcategory_id?._id)
                }
                updateForm.setFieldsValue({
                    location: list?.location?.name,
                    full_name: list?.full_name,
                    user_name: list?.user_name,
                    email: list?.email,
                    website: list?.website,
                    description: list?.description,
                    company: list?.company,
                    working_days: list?.availability_days,
                    category_id: list?.category_id?.name,
                    subcategory_id: list?.subcategory_id?.name
                })
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
        getCategories()
    }

    const getCategories = () => {
        dispatch(getCategoriesData({
            successCb: async (response) => {
                setCatData(response.data);
            },
            failureCb: (response) => {
                //////
                setCatData([]);
            }
        }))
    }

    const selectCategory = (value) => {

        if (value === "630f2e8252de247cce6c76a4") {
            setcatId(value);
            dispatch(getSubCategoriesData({
                successCb: async (response) => {
                    setSubCatData(response.data);
                },
                failureCb: (response) => {
                    //////
                    setSubCatData([]);
                }
            }))
        }
        else {
            setcatId(value);
            setSubCatData([]);
        }
    };

    const selectSubCategory = (value) => {
        setcatSubId(value)
    };


    const setPhoneNumber = (e) => {
        setPhoneNumberInput(e.target.value);
    };

    const handlePlaceChanged = (place) => {
        if (place) {
            let locate = {
                "cordinates":
                {
                    "lat": place.geometry.location.lat(),
                    "long": place.geometry.location.lng()
                },
                "name": place.formatted_address
            }
            updateForm.setFieldsValue({
                location: locate?.name
            })
            setLocations(locate);
        }
    }

    const onChangeProfile = (e) => {
        setProfilePic(e.file.originFileObj);
    };

    ///update event
    const onFinish = (value) => {      
        const formData = new FormData();
        formData.append('full_name', value.full_name);
        formData.append('country_code', countryCode === '' ? profile.country_code : countryCode);
        formData.append('phone_number', phoneNumber === '' ? profile.phone_number : phoneNumber);
        formData.append('website', value.website);
        formData.append('description', value.description);
        formData.append('company', value.company);
        formData.append('availability_days', JSON.stringify(value.working_days));
        formData.append('location', JSON.stringify(locations));

        if (profile?.user_type === "1") {
            formData.append('category_id', catId);
            if (catId === '630f2e8252de247cce6c76a4')
                formData.append('subcategory_id', catSubId);
        }

        if (profilePic.length != 0)
            formData.append('profile_pic', profilePic);
        //////
        setLoading(true);
        dispatch(updateProfile({
            formData,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                updateFireInfo(response.data)
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const updateFireInfo = async (data) => {
        let response;
        await db
            .collection('Users')
            .doc(data?._id)
            .update({
                _id: data?._id,
                username: data?.full_name,
                profile_pic: data?.profile_pic,
            })
            .then(() => (response = true))
            .catch(() => (response = false));
        let userRoomids = [];
        await db
            .collection('Chatrooms')
            .where('members', 'array-contains', data?._id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    userRoomids.push(doc.data()._id);
                });
            });

        for (let i = 0; i < userRoomids.length; i++) {
            await db
                .collection('Chatrooms')
                .doc(userRoomids[i])
                .update({
                    UsersUpdatedstatus: Math.random(),
                })
                .then(() => (response = true))
                .catch(() => (response = false));
        }
        window.location.href = '#profile'
        return response;

    };

    const onChange = (checkedValues) => {
        console.log(JSON.stringify(checkedValues));
    };

    useEffect(() => {
        profileDetails();
    }, [])

    const props = {
        listType: 'picture',
        beforeUpload(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const img = document.createElement('img');
                    img.src = reader.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.naturalWidth;
                        canvas.height = img.naturalHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);
                        ctx.fillStyle = 'red';
                        ctx.textBaseline = 'middle';
                        ctx.font = '33px Arial';
                        ctx.fillText('Ant Design', 20, 20);
                        canvas.toBlob((result) => resolve(result));
                    };
                };
            });
        },
    };

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Profile</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" md={24} xs={24} lg={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5"
                                valuepropname="fileList"
                                fields={fields}
                                onFinish={onFinish}
                                form={updateForm}
                            >
                                <Form.Item label="" valuepropname="fileList">
                                    <div className='mb-3'>

                                        <div className="bg-cover profile-u" style={{ width: "100%", height: "250px", textAlign: "right", borderRadius: "4px", background: `url( ${profile.profile_pic === '' ? defaultIcon : fileUrl + profile.profile_pic})` }} >
                                            {/* <Upload className='pic_uploader' onChange={onChangeProfile}>
                                                <Button style={{ borderRadius: "44px", float: "right" }} icon={<HighlightOutlined />}></Button>
                                            </Upload> */}
                                            <Upload {...props} onChange={onChangeProfile}>
                                                <Button style={{ borderRadius: "44px", float: "right", marginTop: "15px", marginRight: "15px" }} icon={<HighlightOutlined />}></Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Form.Item>
                                <label><b>Full Name</b></label>
                                <Form.Item
                                    name="full_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Full Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Full Name" />
                                </Form.Item>
                                <label><b>Username</b></label>
                                <Form.Item
                                    name="user_name"
                                >
                                    <Input disabled placeholder="Username" />
                                </Form.Item>
                                <label><b>Email</b></label>
                                <Form.Item
                                    name="email"
                                >
                                    <Input disabled placeholder="Email" />
                                </Form.Item>
                                <label><b>Location</b></label>
                                <Form.Item
                                    name="location"
                                >
                                    {locationName &&
                                        <AutoComplete
                                            apiKey={googleKey}
                                            onPlaceSelected={(e) => handlePlaceChanged(e)}
                                            className='ant-input'
                                        />
                                    }
                                </Form.Item>
                                <label><b>Phone</b></label>
                                <Form.Item
                                    name="phone_number"
                                >
                                    <div className='d-flex'>
                                        <PhoneInput
                                            enableSearch={true}
                                            country={'us'}
                                            value={countryCode}
                                            onChange={(countryCode) => setCountryCode(countryCode)}
                                            style={{ width: "120px" }}
                                        />
                                        <input className="ant-input" onChange={(phoneNumber) => setPhoneNumber(phoneNumber)} value={phoneNumber} placeholder="Enter phone number" />
                                    </div>
                                </Form.Item>
                                <label><b>Company</b></label>
                                <Form.Item
                                    name="company"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your company / business name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="company / business name" />
                                </Form.Item>

                                <label><b>Website</b></label>
                                <Form.Item
                                    name="website"
                                >
                                    <Input placeholder="website" />
                                </Form.Item>

                                <label><b>Description</b></label>
                                <Form.Item
                                    name="description"
                                >
                                    <TextArea placeholder="description" />
                                </Form.Item>

                                <label><b>Category</b></label>
                                {profile?.user_type === '1' ?
                                    <Form.Item
                                        name="category_id"
                                        rules={[{
                                            required: true,
                                            message: 'Please select category!',
                                        }]}
                                    >
                                        <Select
                                            defaultValue=""
                                            className='col-12'
                                            style={{
                                                background: "#F6F6F6",
                                            }}
                                            onChange={selectCategory}
                                        >

                                            {catData.length > 0 && catData ? catData.map((element, index) => {
                                                return (
                                                    <Option value={element._id}>{element.name}</Option>
                                                )
                                            }) :
                                                <Option value="">Select Category</Option>
                                            }
                                        </Select>
                                    </Form.Item>
                                    : ""}

                                {catId === "630f2e8252de247cce6c76a4" && profile?.category_id?._id === '630f2e8252de247cce6c76a4' || catId === "630f2e8252de247cce6c76a4" ?
                                    <><label><b>Sub Category</b></label>
                                        <Form.Item
                                            name="subcategory_id"
                                            rules={[{
                                                required: true,
                                                message: 'Please select subcategory!',
                                            }]}
                                        >
                                            <Select
                                                defaultValue=""
                                                className='col-12'
                                                style={{
                                                    background: "#F6F6F6",
                                                }}
                                                onChange={selectSubCategory}
                                            >

                                                {catSubData.length > 0 && catSubData ? catSubData.map((element, index) => {
                                                    return (
                                                        <Option value={element._id}>{element.name}</Option>
                                                    )
                                                }) :
                                                    <Option value="">Select Sub Category</Option>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </>
                                    : ""}


                                {/* <label className='mb-3 bold'>Business Hours</label>
                                <Form.Item
                                    name="start"
                                >
                                    <Input placeholder="start" />
                                    <Input placeholder="end" />
                                </Form.Item> */}

                                <label className='mb-3 bold'>Working Days</label>
                                <Form.Item
                                    name="working_days"
                                >
                                    <Checkbox.Group
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={onChange}
                                    >
                                        <Row>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'S', 'value': 0 }}>S</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'M', 'value': 1 }}>M</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'T', 'value': 2 }}>T</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'W', 'value': 3 }}>W</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'T', 'value': 4 }}>T</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'F', 'value': 5 }}>F</Checkbox>
                                            </Col>
                                            <Col span={2}>
                                                <Checkbox value={{ 'text': 'S', 'value': 6 }}>S</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                                <label className='mb-3 bold'>Profile Images</label>
                                <Form.Item className='pe-5'>
                                    <Row gutter={24}>
                                        {profile ? profile?.profile_images.length > 0 && profile?.profile_images ? profile?.profile_images.map((element, index) => {
                                            return (
                                                <Col className="gutter-row mb-3" span={6}>
                                                    <div style={{ background: `url(${fileUrl + element.value})`, width: "200px", height: "200px", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                                </Col>
                                            )
                                        }) :
                                            <Row className='center pt-5 mt-5'>
                                                <Col>
                                                    <h3 className='pt-5'>No images found yet!</h3>
                                                </Col>
                                            </Row>
                                            : ""}
                                    </Row>
                                </Form.Item>

                                <Form.Item className='pe-5'>
                                    <a href="#/add-images">
                                        <Button type="primary" size='large' className='pe-5 ps-5'> + Add More Images</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </a>
                                    <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Update</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default ProfileUpdate