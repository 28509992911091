import { USER_DETAILS, LOGOUT_SUCCESS, PROFILE_UPDATE, CHANGE_PASSWORD ,DRAWER_LIST, CHAT_NOTI} from "./types";
import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const fetchUserData = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}profile`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: USER_DETAILS,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getLinkAccount = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}producer/linkingurl`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchOtherUserData = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}other_profile?_id=`+data._id,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const updateProfile = ({
    formData,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
        },
        url: `${baseUrl}profile`,
        "data": formData
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: PROFILE_UPDATE,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const updatePassword = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}change_password`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: CHANGE_PASSWORD,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const deleteMyProfile = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}delete_account`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);     
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const markAsFavorite = ({
    data,   
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json'
        },
        url: `${baseUrl}/fav`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const drawerEvent = ({
    data,  
}) => async dispatch => {    
    dispatch({
        type: DRAWER_LIST,
        payload: data
    });
}

export const chatCounter = ({
    data,  
}) => async dispatch => {    
    dispatch({
        type: CHAT_NOTI,
        payload: data
    });
}


export const logoutAction = () => async dispatch => {
    dispatch({
        type: LOGOUT_SUCCESS
    })

}