import { EVENT_LIST, EVENT_DETAILS, EVENT_CREATE, EVENT_TYPE, EVENT_DELETE, EVENT_UPDATE ,EVENT_TYPE_LIST} from "./types";
import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const fetchEvents = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/event/?time_zone=` + data.time_zone + "&type=" + data.type + "&joined=" + data.joined,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_LIST,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchEventsBySearch = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {

    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/event/?time_zone=${data.time_zone}&event_type=${!data.event_type ? '' : data.event_type}&lat=${!data.lat ? '' : data.lat}&long=${!data.long ? '' : data.long}&radius=${!data.radius ? '' : data.radius}&joined=''&type=0`,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_LIST,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchEventsDetails = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/event?_id=` + data._id,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_DETAILS,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const createEvent = ({
    formData,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
        },
        url: `${baseUrl}producer/event`,
        "data": formData
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_CREATE,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const updateEvent = ({
    formData,
    pathID,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
        },
        url: `${baseUrl}producer/event/` + pathID,
        "data": formData
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_UPDATE,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getEventTypeData = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        url: `${baseUrl}type`
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_TYPE,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const deleteEvents = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'delete',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}producer/event/` + data.event_id,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: EVENT_DELETE,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}


export const markAsFavorite = ({
    data,   
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json'
        },
        url: `${baseUrl}${data.user_type}/save_event/${data.event_id}`
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchFavoriteEvents = ({
    data,   
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json'
        },
        url: `${baseUrl}${data.user_type}/event/?time_zone=` + data.time_zone + "&is_saved=1"
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchFavoriteProducers = ({
    data,   
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: {
            Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json'
        },
        url: `${baseUrl}/fav?user_type=${data.user_type}`
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {

        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchMerchants = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/event_merchants/?_id=` + data._id,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchVendors = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/event_vendors/?_id=` + data._id,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const joinEventData = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}${data.user_type}/join_event/` + data.event_id+"/"+data.description,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const addFeedack = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}feedback`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const createIntent = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}merchant/generate_payment_intent?_id=`+data._id,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}


export const eventTypeList = ({
    data,  
}) => async dispatch => {
    dispatch({
        type: EVENT_TYPE_LIST,
        payload: data
    });
}