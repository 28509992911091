import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Modal, Button, Skeleton ,Avatar} from 'antd';
import { DeleteFilled ,EditOutlined} from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchOffers, delOffer } from '../../actions/offers';
import notFound from "../../assets/img/not-found.png";
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { fileUrl } from '../../constants/const';

const { Content } = Layout;

function Offers() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState('');
    const [modalDelOpen, setModalDelOpen] = useState(false);
    const [offerId, setOfferId] = useState(' ');

    const getOffers = async () => {
        setLoading(true)
        dispatch(fetchOffers({
            successCb: (response) => {
                setLoading(false)
                setOffers(response.data)
            },
            failureCb: (response) => {
                setLoading(false)
                setOffers('')
            }
        }))
    }

    function onDelete() {
        let data = {
            _id: offerId,
            status: 2
        }
        dispatch(delOffer({
            data,
            successCb: (response) => {
                addToast("Deleted successfully!", { appearance: 'success', autoDismiss: true });
                setModalDelOpen(false)
                getOffers();
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }

    function hideModal() {
        setModalDelOpen(false)
    }

    function openDelOffers(id) {
        setModalDelOpen(true)
        setOfferId(id)
    }

    useEffect(() => {
        getOffers();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="">
                            <Col className="gutter-row pb-2" xs={24} lg={16}>
                                <div>
                                    <h5 className='color-theme semi-bold'>Offers</h5>
                                </div>
                            </Col>
                            <Col className="gutter-row pb-2" xs={24} lg={16}>
                                {offers.length > 0 && offers ? offers.map((element, index) => {
                                    return (
                                        <Row className="gutter-row p-3 pb-1 bg-gray mt-3" span={24}>
                                            <Col className="gutter-row" span={10}>
                                                <p>Category:</p>
                                                <p>Sub-Category:</p>
                                                <p>Discount:</p>
                                                <p>Offer Type:</p>
                                                <p>Valid From:</p>
                                                <p>Valid Till:</p>
                                                <p>Offer Details:</p>
                                            </Col>
                                            <Col className="gutter-row text-right offers_" span={10}>
                                                <h6><Avatar src={fileUrl+element.tag?.tag_image} style={{width:"35px"}}/> &nbsp;&nbsp;{element.tag?.name}</h6>
                                                <h6><Avatar src={fileUrl+element.sub_tag?.tag_image} style={{width:"35px"}}/> &nbsp;&nbsp;{element.sub_tag?.name}</h6>
                                                <h6>{element.discount}%</h6>
                                                <h6>{element.type === "0" ? "Multiple Time use" : "One Time use"}</h6>
                                                <h6>{element.valid_from}</h6>
                                                <h6>{element.valid_till === '' ? '--' : element.valid_till}</h6>
                                                <h6>{element.description}</h6>
                                            </Col>
                                            <Col className="gutter-row text-right" span={2}>
                                                <a href={"#edit-offer/"+element._id}>
                                                    <EditOutlined style={{ fontSize: "30px", color: "#ec220d" }} />
                                                </a>
                                            </Col>
                                            <Col className="gutter-row text-right" span={2}>
                                                <DeleteFilled style={{ fontSize: "30px", color: "#ec220d" }} onClick={() => openDelOffers(element._id)} />
                                            </Col>
                                        </Row>
                                    )
                                }) :
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No offers found yet!</h3>
                                        </Col>
                                    </Row>
                                }

                                <Row gutter={24} className="center">
                                    <Col className="gutter-row text-center pt-5 pb-5 pt0 mp0" xs={24} lg={6}>
                                        <a href='/#/add-offer'>
                                            <Button type="primary" size='large' className='pe-5 ps-5 '>Add Offer</Button>
                                        </a>
                                    </Col>
                                    <Col className="gutter-row text-center pt-5 pb-5 pt0 mp0" xs={24} lg={6}>
                                        <a href='/#/add-custom-offer'>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Custom Offer</Button>
                                        </a>
                                    </Col>
                                </Row>

                                <Modal
                                    title="Delete Offer"
                                    visible={modalDelOpen}
                                    onOk={onDelete}
                                    onCancel={hideModal}
                                    cancelText="No"
                                    okText="Yes"
                                    className='filter-modal'
                                >
                                    <div className='mb-4 pt-3 text-center'>
                                        <p>Are you sure you want to<br></br> delete this offer?</p>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Skeleton>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Offers