import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button, Spin, Modal, Skeleton } from 'antd';
import Footer_ from '../../components/Footer_'
import { useLocation } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { fetchSavedCards, fetchDefaultCards, addCard, doDefaultCard, doDelDefaultCard } from '../../actions/card';
import { DeleteOutlined } from '@ant-design/icons';
import notFound from "../../assets/img/not-found.png";

const { Content } = Layout;

function CardApp() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [number, SetNumber] = useState("");
    const [name, SetName] = useState("");
    const [month, SetMonth] = useState("");
    let [expiry, SetExpiry] = useState("");
    let [year, SetYear] = useState("");
    const [cvc, SetCvc] = useState("");
    const [focus, SetFocus] = useState("");
    const [cards, setCards] = useState("");
    const [cardsDefault, setCardsDefault] = useState("");
    const [modal2Open, setModal2Open] = useState(false);
    const [cardId, setCardId] = useState('');
    const location = useLocation();
    const token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    sessionStorage.setItem("token", token);

    const handleDate = (e) => {
        SetMonth(e.target.value);
        SetExpiry(e.target.value);
    };
    const handleExpiry = (e) => {
        SetExpiry(month.concat(e.target.value));
        SetYear(e.target.value);
    };

    const fetchDetails = async () => {
        setLoading(true);
        dispatch(fetchSavedCards({
            successCb: (response) => {
                setCards(response.data)
                setLoading(false);
            },
            failureCb: (response) => {
                setCards('')
                setLoading(false);
            }
        }))
    }

    const fetchDefaultDetails = async () => {
        setLoading(true);
        dispatch(fetchDefaultCards({
            successCb: (response) => {
                setCardsDefault(response.data.default_source)
                fetchDetails();
                setLoading(false);
            },
            failureCb: (response) => {
                setCardsDefault('')
                setLoading(false);
            }
        }))
    }

    const addNewCard = async () => {
        let data = {
            "number": number,
            "exp_month": month,
            "exp_year": year,
            "name": name
        }
        setLoading(true);
        dispatch(addCard({
            data,
            successCb: (response) => {
                setLoading(false);
                SetNumber('');
                SetName('');
                SetMonth('');
                SetExpiry('');
                SetYear('');
                SetCvc('');
                setCards(response.data)
                fetchDefaultDetails();
                document.location.href="https://4cornerconcepts.com";
            },
            failureCb: (response) => {
                console.log(response)
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function defaultCheckMark(id) {
        let data = {
            "default_source": id,
        }
        setLoading(true);
        dispatch(doDefaultCard({
            data,
            successCb: (response) => {
                fetchDefaultDetails();
                setLoading(false);
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function confirmDel(id) {
        setModal2Open(true);
        setCardId(id)
    }

    function onDelete() {
        let data = {
            "_id": cardId,
        }
        setLoading(true);
        dispatch(doDelDefaultCard({
            data,
            successCb: (response) => {
                fetchDefaultDetails();
                setModal2Open(false);
            },
            failureCb: (response) => {
                setModal2Open(false);
            }
        }))
    }

    useEffect(() => {
        fetchDefaultDetails();
    }, [])

    return (
        <Layout
            className="site-layout"
        >
            <Layout className="site-layout">
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}

                    <Row gutter={24}>
                        <Col className="gutter-row pb-2" lg={24} xs={24}>
                            <Row gutter={24} className="">
                                <Col className="gutter-row pb-2 pt-5" xs={24}>
                                    <div clasName="rccs__card rccs__card--unknown">
                                        <Cards
                                            number={number}
                                            name={name}
                                            expiry={expiry}
                                            cvc={cvc}
                                            focused={focus}
                                        />
                                    </div>
                                </Col>
                                <Col className="gutter-row pb-2 pt-5" xs={24}>
                                    <Row>
                                        <Col xs={24} className="pb-3">
                                            <label for="name">Card Number</label>
                                            <input
                                                type="tel"
                                                className="form-control ant-input"
                                                value={number}
                                                name="number"
                                                maxlength="16"
                                                pattern="[0-9]+"
                                                onChange={(e) => {
                                                    SetNumber(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                                placeholder="***************"
                                            ></input>
                                        </Col>
                                        <Col xs={24} className="pb-3">
                                            <label for="name">Cardholder Name</label>
                                            <input
                                                type="text"
                                                className="form-control ant-input"
                                                value={name}
                                                name="name"
                                                onChange={(e) => {
                                                    SetName(e.target.value);
                                                }}
                                                onFocus={(e) => SetFocus(e.target.name)}
                                                placeholder="Enter card name"
                                            ></input>
                                        </Col>
                                    </Row>
                                    <Row gutter={24} className="pb-3">
                                        <Col xs={24} className="gutter-row pb-2">
                                            <div>
                                                <label for="month">Expiration Month</label>
                                            </div>
                                            <div>
                                                <select
                                                    className="form-control ant-input"
                                                    name="expiry"
                                                    onChange={handleDate}
                                                >
                                                    <option value=" ">Month</option>
                                                    <option value="01">Jan</option>
                                                    <option value="02">Feb</option>
                                                    <option value="03">Mar</option>
                                                    <option value="04">April</option>
                                                    <option value="05">May</option>
                                                    <option value="06">June</option>
                                                    <option value="07">July</option>
                                                    <option value="08">Aug</option>
                                                    <option value="09">Sep</option>
                                                    <option value="10">Oct</option>
                                                    <option value="11">Nov</option>
                                                    <option value="12">Dec</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col xs={24} className="gutter-row pb-3">
                                            <div>
                                                <label for="month">Expiration Year</label>
                                            </div>
                                            <div>
                                                <select
                                                    className="form-control ant-input"
                                                    name="expiry"
                                                    onChange={handleExpiry}
                                                >
                                                    <option value=" ">Year</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                    <option value="2034">2034</option>
                                                    <option value="2035">2035</option>
                                                </select>
                                            </div>

                                        </Col>
                                        <Col xs={24} className="gutter-row pb-2">
                                            <div>
                                                <label for="month">CVV</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="tel"
                                                    name="cvc"
                                                    maxlength="3"
                                                    className=" form-control card ant-input"
                                                    value={cvc}
                                                    pattern="\d*"
                                                    onChange={(e) => {
                                                        SetCvc(e.target.value);
                                                    }}
                                                    onFocus={(e) => SetFocus(e.target.name)}
                                                    placeholder="CVV"
                                                ></input>
                                            </div>
                                        </Col>
                                        <Col xs={24} className='center text-center pt-3'>
                                            <Spin spinning={loading}>
                                                <Button onClick={() => addNewCard()} type="primary" size='large' className='pe-5 ps-5'>Add Card</Button>
                                            </Spin>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                        <Col className="gutter-row pb-2" style={{ borderRight: "1px dashed #424242", height: "600px", overflowX: "scroll" }} xs={24} >
                            <Row gutter={24}>
                                <Col className="gutter-row pb-2" span={24}>
                                    <div>
                                        <h5 className='color-theme semi-bold text-center'>Saved Card list</h5>
                                    </div>
                                </Col>
                                <Skeleton loading={loading} active avatar>
                                    {cards.length > 0 && cards ? cards.map((element, index) => {
                                        var classs = "credit-card selectable " + element.brand.toLowerCase();
                                        return (
                                            <Col className="gutter-row pb-3" style={{ borderBottom: "1px dashed #424242" }} span={24}>
                                                <div className={classs}>
                                                    <div className="credit-card-last4">
                                                        {element.last4}
                                                    </div>
                                                    <div className="credit-card-expiry">
                                                        {element.exp_month + "/" + element.exp_year}
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <Row gutter={24}>
                                                        <Col className="gutter-row" span={12}>
                                                            <input type="radio"
                                                                checked={element.id == cardsDefault ? "checked" : ""}
                                                                onChange={() => defaultCheckMark(element.id)}
                                                                name="saved"
                                                                value={element.id}
                                                            />
                                                        </Col>
                                                        <Col className="gutter-row" span={12}>
                                                            <DeleteOutlined onClick={() => confirmDel(element.id)} style={{ cursor: "pointer" }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )
                                    }) :
                                        <Row className='center pt-5 mt-5 '>
                                            <Col className='col-12 text-center'>
                                                <img src={notFound} style={{ width: "300px" }} />
                                                <h5 className='pt-5' >No saved cards!</h5>
                                            </Col>
                                        </Row>
                                    }
                                </Skeleton>
                            </Row>
                        </Col>

                    </Row>
                    {/* /////modal */}
                    <Modal
                        title="Card Delete"
                        visible={modal2Open}
                        onOk={onDelete}
                        onCancel={() => setModal2Open(false)}
                        cancelText="Cancel"
                        okText="Delete"
                        className='filter-modal'
                    >

                        <div className='mb-4 pt-3 text-center'>
                            <p>Are you sure want to delete this card?</p>
                        </div>

                    </Modal>
                    {/* /////modal */}

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default CardApp