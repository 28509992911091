import React, { useState, useEffect, useRef } from 'react';
import {
    UnorderedListOutlined,
    AppstoreOutlined,
    EnvironmentFilled,
    ClockCircleFilled,
    CalendarFilled
} from '@ant-design/icons';
import { Layout, Row, Col, Button, Card, Avatar, Modal, Input, Space, Tabs, Select, Slider, Skeleton } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchEvents, fetchEventsBySearch, markAsFavorite, eventTypeList } from '../../actions/event';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import timezone from 'moment-timezone';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import notFound from "../../assets/img/not-found.png";
import filter from "../../assets/img/icons/filter.png";
import bookmarkun from "../../assets/img/icons/bookmarkun.png";
import bookmark from "../../assets/img/icons/bookmark.png";
import { useToasts } from 'react-toast-notifications';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { googleKey } from '../../constants/const';

const { Content } = Layout;
const { Meta } = Card;
const { Search } = Input;
const { Option } = Select;

function Events() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [events, setEvents] = useState(0);
    const [modal2Open, setModal2Open] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputSlider, setInputSlider] = useState(100);
    const [location, setLocation] = useState('');
    const [eventTypeData, setEventTypeData] = useState('');
    const [noSearch, setNoSearch] = useState(false);
    const inputRef = useRef();
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    let timeZ = timezone.tz.guess();

    const loadEventData = async (type) => {
        setLoading(true)
        let data = {
            time_zone: timeZ,
            type: 0,
            joined: type == 1 ? type : "",
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchEvents({
            data,
            successCb: (response) => {
                setEvents(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setEvents('')
                setLoading(false)
            }
        }))
    }

    const marks = {
        0: '0 mi',
        100: '100 mi',
    };

    const onChangeTab = (key) => {
        loadEventData(key);
    };

    const onChangeSlider = (newValue) => {
        setInputSlider(newValue)
    }

    const eventType = (value) => {
        setEventTypeData(value)
    }

    const onSearch = (e) => {
        var filter = e.target.value.toLowerCase();
        var nodes = document.getElementsByClassName('target');

        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].innerText.toLowerCase().includes(filter)) {
                nodes[i].style.display = "block";
                setNoSearch(false)
            } else {
                nodes[i].style.display = "none";
                setNoSearch(true)
            }
        }
    }

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            let locate = {
                "lat": place.geometry.location.lat(),
                "long": place.geometry.location.lng()
            }
            setLocation(locate);
        }
    }

    const onFilter = () => {
        setModal2Open(false);
        let data = {
            time_zone: timeZ,
            radius: inputSlider,
            lat: location.lat,
            long: location.long,
            event_type: eventTypeData,
            joined: "",
            type: 0,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchEventsBySearch({
            data,
            successCb: (response) => {
                setEvents(response.data)
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    };

    const removeFilter = () => {
        setModal2Open(false);
        setEventTypeData('');
        setLocation('');
        setInputSlider('');
        loadEventData(0);
    }

    function joinedMerchants(items) {

        return (
            <>
                {items.length > 0 && items ? items.map((element, index) => {
                    return (
                        <Avatar src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                    )
                }) :
                    ''
                }
            </>
        )
    }

    function goToEvent(id, type) {
        let data = {
            type
        }
        dispatch(eventTypeList({
            data
        }))

        document.location.href = "#event-detail/" + id
    }

    function markFavorite(id) {
        let data = {
            event_id: id,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(markAsFavorite({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                loadEventData(0);
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'success', autoDismiss: true });
            }
        }))
    }

    useEffect(() => {
        loadEventData(0);
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////search section */}
                    <Row gutter={24} className="pe-5 ps-5 mp0">
                        <Col className="gutter-row pb-5" xs={22} sm={22} lg={22}>
                            <Space className="col-lg-12 col-md-12 search_" direction="vertical">
                                <Search

                                    placeholder="Search Events..."
                                    onChange={onSearch}
                                    style={{
                                        height: "30px",
                                    }}
                                />
                            </Space>
                        </Col>
                        <Col className="gutter-row pt-3" xs={2} sm={2} lg={2}>
                            <img src={filter}
                                onClick={() => setModal2Open(true)}
                                style={{
                                    width: "35px",
                                    float: "right"
                                }}
                            />
                        </Col>
                    </Row>

                    {/* /////user detail and creat section */}
                    {userDetail.user_type === "0" ?
                        <Row gutter={24} className="pe-5 ps-5 mp0 mt-4 mb-4">
                            <Col className="gutter-row" xs={12} lg={20}>
                                <div>Welcome</div>
                                <h4><strong>{userDetail?.full_name}</strong></h4>
                            </Col>
                            <Col className="gutter-row" xs={12} lg={4}>
                                <a href="#event-create">
                                    <Button type="primary" style={{float:"right"}} size='large'>Create Event</Button>
                                </a>
                            </Col>
                        </Row>
                        : ""}

                    {/* /////event list section */}

                    <Tabs defaultActiveKey="0"  className={userDetail.user_type == "0" ? "pe-5 ps-5 mp0 event-tab pro-e" : "pe-5 ps-5 mp0 event-tab"} onChange={onChangeTab}>
                        <Tabs.TabPane  tab={userDetail.user_type == "0" ? "" : "Upcoming"} key="0" >
                            <Skeleton loading={loading} active avatar>
                                {events.length > 0 && events ? events.map((element, index) => {
                                    return (
                                        <Row gutter={24} className="pt-1 pb-5 target" >
                                            <Col className="gutter-row" span={24}>
                                                <Row gutter={24} className="event-price_top">
                                                    <Col className="gutter-row text-center" xs={8} md={8} lg={4}>
                                                        {element.event_type == 1 ?
                                                            <div className='price-type_top'>
                                                                <p className='text-white p-2'>${element.amount}</p>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </Col>
                                                    {userDetail.user_type != "0" ?
                                                        <Col className="gutter-row event-avtar" onClick={() => markFavorite(element._id)} md={18} xs={18} lg={20}>
                                                            {element.is_saved ?
                                                                <img src={bookmark} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                                :
                                                                <img src={bookmarkun} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                            }
                                                        </Col>
                                                        : ""}
                                                </Row>

                                                <Card
                                                    onClick={() => goToEvent(element._id, 'upcoming')}
                                                    hoverable
                                                    cover={<div alt={events.event_name} className='card-cover' style={{ background: 'url(' + fileUrl + element.event_image + '' }} />}
                                                >
                                                    <Row gutter={24} className="event-price">
                                                        <Col className="gutter-row text-center" xs={8} md={4} lg={3}>
                                                            {element.event_type == 1 ?
                                                                <div className='price-type'>
                                                                    <p className='text-white p-1'>Paid</p>
                                                                </div>
                                                                :
                                                                <div className='price-type-free '>
                                                                    <p className='text-white p-1'>Free</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className="gutter-row event-avtar" xs={16} md={20} lg={21}>
                                                            <Avatar.Group
                                                                maxCount={2}
                                                                style={{ float: "right" }}>
                                                                {joinedMerchants(element.merchant_joined)}
                                                            </Avatar.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={24}>
                                                        <Col className="gutter-row" span={22}>
                                                            <Meta title={element.event_name} />
                                                        </Col>
                                                        {/* <Col className="gutter-row" span={2}>
                                                        <img src={share} style={{ width: "20px" }} />
                                                    </Col> */}
                                                    </Row>

                                                    <Row gutter={24} className="event-locate">
                                                        <Col className="gutter-row d-flex pt-2 pb-2" span={24}>
                                                            <EnvironmentFilled className='pt-1'/>
                                                            <span className='ps-2'>{element.location?.name}</span>
                                                        </Col>
                                                        <Col className="gutter-row pt-2" xs={6} lg={4}>
                                                            <CalendarFilled />
                                                            <span className='ps-2'>{moment(element.event_start_time).format(" Do MMM YYYY")}</span>
                                                        </Col>
                                                        <Col className="gutter-row pt-2" xs={12} lg={6}>
                                                            <ClockCircleFilled />
                                                            <span className='ps-2'>{moment(element.event_start_time).format("h:mm a")}</span>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                }) :
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No event found yet!</h3>
                                        </Col>
                                    </Row>
                                }
                                {noSearch ?
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No result found yet!</h3>
                                        </Col>
                                    </Row>
                                    : ""}
                            </Skeleton>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={userDetail.user_type == "0" ? "" : "Joined"} key="1">
                            <Skeleton loading={loading} active avatar>
                                {events.length > 0 && events ? events.map((element, index) => {
                                    return (
                                        <Row gutter={24} className="pt-1 pb-5">
                                            <Col className="gutter-row" span={24}>
                                                <Row gutter={24} className="event-price_top">
                                                    <Col className="gutter-row text-center" xs={8} md={8} lg={4}>
                                                        {element.event_type == 1 ?
                                                            <div className='price-type_top'>
                                                                <p className='text-white p-2'>${element.amount}</p>
                                                            </div>
                                                            : ""
                                                        }
                                                    </Col>
                                                    <Col className="gutter-row event-avtar" span={21}>
                                                        <img src={bookmarkun} style={{ width: '30px', float: "right" }} />
                                                    </Col>
                                                </Row>

                                                <Card
                                                    onClick={() => goToEvent(element._id, 'joined')}
                                                    hoverable
                                                    cover={<div className='card-cover' style={{ background: 'url(' + fileUrl + element.event_image + '' }} />}
                                                >
                                                    <Row gutter={24} className="event-price">
                                                        <Col className="gutter-row text-center" span={2}>
                                                            {element.event_type == 1 ?
                                                                <div className='price-type'>
                                                                    <p className='text-white p-1'>Paid</p>
                                                                </div>
                                                                :
                                                                <div className='price-type-free '>
                                                                    <p className='text-white p-1'>Free</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className="gutter-row event-avtar" span={21}>
                                                            <Avatar.Group
                                                                maxCount={2}
                                                                style={{ float: "right" }}>
                                                                {joinedMerchants(element.merchant_joined)}
                                                            </Avatar.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={24}>
                                                        <Col className="gutter-row" span={22}>
                                                            <Meta title={element.event_name} />
                                                        </Col>
                                                        {/* <Col className="gutter-row" span={2}>
                                                        <img src={share} style={{ width: "20px" }} />
                                                    </Col> */}
                                                    </Row>

                                                    <Row gutter={24} className="event-locate">
                                                        <Col className="gutter-row d-flex pt-2 pb-2" span={24}>
                                                            <EnvironmentFilled />
                                                            <span className='ps-2'>{element.location?.name}</span>
                                                        </Col>
                                                        <Col className="gutter-row pt-2" xs={12} lg={4}>
                                                            <CalendarFilled />
                                                            <span className='ps-2'>{moment(element.event_start_time).format(" Do MMM YYYY")}</span>
                                                        </Col>
                                                        <Col className="gutter-row pt-2" xs={12} lg={4}>
                                                            <ClockCircleFilled />
                                                            <span className='ps-2'>{moment(element.event_start_time).format("h:mm a")}</span>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>

                                    )
                                }) :
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No event found yet!</h3>
                                        </Col>
                                    </Row>
                                }
                            </Skeleton>
                        </Tabs.TabPane>
                    </Tabs>


                    {/* filter modal */}

                    <Modal
                        title="Filter"
                        visible={modal2Open}
                        onOk={onFilter}
                        onCancel={removeFilter}
                        cancelText="Remove"
                        okText="Apply Filter"
                        className='filter-modal'
                    >

                        <div className='mb-4 pt-3'>
                            <Select
                                defaultValue=""
                                className='col-12'
                                style={{
                                    background: "#F6F6F6",
                                }}
                                onChange={eventType}
                            >
                                <Option value="">Event Type</Option>
                                <Option value="1">Paid</Option>
                                <Option value="0">Free</Option>
                            </Select>
                        </div>
                        <div className='pb-3'>
                            <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                <StandaloneSearchBox
                                    onLoad={ref => inputRef.current = ref}
                                    onPlacesChanged={handlePlaceChanged}
                                >
                                    <input placeholder="Search by geolocation"
                                        style={{
                                            height: "30px",
                                            background: "#F6F6F6"
                                        }}
                                        type="text"
                                        className="ant-input"
                                    />
                                </StandaloneSearchBox>
                            </LoadScript>
                        </div>
                        <div className='pb-3'>
                            <Slider marks={marks} defaultValue={37}
                                onChange={onChangeSlider}
                            />
                        </div>
                    </Modal>

                    {/* /////content end */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Events