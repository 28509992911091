import React, { useState, useEffect } from 'react';
import { Layout, Avatar, List, Button, Row, Col } from 'antd';
import { UserOutlined, BellFilled, MenuFoldOutlined } from '@ant-design/icons';
import { fetchUserData, drawerEvent } from '../../actions/users';
import { useDispatch } from 'react-redux';
import { LOGOUT_SUCCESS } from '../../actions/types'
import { fileUrl } from "../../constants/const";
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import { useToasts } from 'react-toast-notifications';

const { Header } = Layout;

function Header_() {
    const dispatch = useDispatch();
    const [userDetail, setUserDetails] = useState('');
    const { addToast } = useToasts();

    const loadUserData = async () => {
        dispatch(fetchUserData({
            successCb: (response) => {
                setUserDetails(response.data);
                if(!response?.data?.account_linked){                 
                    localStorage.setItem('is_linked','no')
                   return false;
                }
            },
            failureCb: (response) => {
                logoutService();
            }
        }))
    }

    function logoutService() {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({
            payload: false,
            type: LOGOUT_SUCCESS,
        });

        setTimeout(() => {
            document.location.href = "";
        }, 1000);
    }

    function openDrawer(open) {
        let data = {
            open
        }
        dispatch(drawerEvent({
            data
        }))
    }

    useEffect(() => {
        loadUserData();
    }, [])


    return (
        <>
            <Header
                className="site-layout-background bg-head"
                style={{
                    padding: 0,
                    height: "108px"
                }}
            >
                <List
                    itemLayout="horizontal"
                >
                    <List.Item className='pt-0'>
                        <List.Item.Meta
                            avatar={<Avatar
                                style={{ width: "50px", height: "50px" }}
                                src=
                                {userDetail.profile_pic === '' ? defaultIcon : fileUrl + userDetail.profile_pic}
                            />
                            }
                            title={userDetail.full_name}
                            description={userDetail.email}
                        />
                        <Row gutter={24}>
                            <Col className='mnone'>
                                <a href='#profile'>
                                    <UserOutlined />
                                </a>
                            </Col>
                            <Col className='mnone'>
                                <a href='#/notifications'>
                                    <BellFilled />
                                </a>
                            </Col>
                            <Col>
                                <MenuFoldOutlined style={{ fontSize: "24px" }} className="dnone dn" onClick={() => openDrawer('open')} />
                                <Button className="mnone" type="primary" size='large' onClick={() => logoutService()}>Logout</Button>
                            </Col>
                        </Row>
                    </List.Item>
                </List>
            </Header>
        </>
    )
}

export default Header_