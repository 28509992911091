import { LOGIN_SUCCESS, REGISTER_SUCCESS, OTP_SUCCESS, FORGOT_PASSWORD_SUCCESS, RESET_PASSWORD_SUCCESS } from "./types";
import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const loginUser = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}login`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        sessionStorage.setItem("token", res.data.data.token);
        sessionStorage.setItem("user_", JSON.stringify(res.data.data));     
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

       successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const registerUser = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}register`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        sessionStorage.setItem("token", res.data.data.token);
        sessionStorage.setItem("user_", JSON.stringify(res.data.data)); 
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });

        successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const verifyUser = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}verify_otp`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: OTP_SUCCESS,
            payload: res.data
        });

        successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const forgotPassword = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}forgot_password`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: res.data
        });

        successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const resendOtpNow = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}resend_otp`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getCategoriesData = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}categories`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getSubCategoriesData = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}subcategories`,
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const resetPassword = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}reset_password`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: res.data
        });

        successCb(res.data.message)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}
