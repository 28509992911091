import { EVENT_LIST, EVENT_DETAILS, EVENT_TYPE_LIST } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case EVENT_LIST:
            return {
                ...state,
                ...payload,
                ...action.payload,
                isLoggedIn: true,
            };
        case EVENT_DETAILS:
            return {
                ...state,
                ...payload,
                ...action.payload,
                isLoggedIn: true,
            };

        case EVENT_TYPE_LIST:
            return {
                ...state,
                ...payload,
                ...action.payload,
                isLoggedIn: true,
            };

        default:
            return state
    }
}