import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Row, Col, Image, Input, Space, Skeleton } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchMerchants } from '../../actions/event';
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import notFound from "../../assets/img/not-found.png";

const { Content } = Layout;
const { Search } = Input;

function Merchants() {
    const dispatch = useDispatch();
    const [merchants, setMerchants] = useState(0);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [noSearch, setNoSearch] = useState(false);
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    const pathID = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const loadData = async () => {
        setLoading(true);
        let data = {
            _id: pathID,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }
        dispatch(fetchMerchants({
            data,
            successCb: (response) => {
                setMerchants(response.data?.merchant_joined)
                setLoading(false);
            },
            failureCb: (response) => {
                setMerchants('')
                setLoading(false);
            }
        }))
    }

    const onSearch = (e) => {
        var filter = e.target.value.toLowerCase();
        var nodes = document.getElementsByClassName('target');

        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].innerText.toLowerCase().includes(filter)) {
                nodes[i].style.display = "block";
                setNoSearch(false)
            } else {
                nodes[i].style.display = "none";
                setNoSearch(true)
            }
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////search section */}
                    <Row gutter={24} className="pe-5 ps-5">
                        <Col className="gutter-row pb-5" span={21}>
                            <Space className="col-lg-12 search_" direction="vertical">
                                <Search
                                    placeholder="Search Merchant"
                                    onChange={onSearch}
                                    style={{
                                        height: "30px",
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="pe-5 ps-5 mt-5 target">
                            {merchants.length > 0 && merchants ? merchants.map((element, index) => {
                                return (
                                    <Col className="gutter-row pb-5 text-center" span={4}>
                                        <a href={'/#/other-profile/' + element._id}>
                                            <Image.PreviewGroup>
                                                <Image src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                                                <h6 className='pt-3'>{element.full_name}</h6>
                                            </Image.PreviewGroup>
                                        </a>
                                    </Col>
                                )
                            }) :
                                <Row className='center pt-5 mt-5'>
                                    <Col>
                                        <img src={notFound} style={{ width: "300px" }} />
                                        <h3 className='pt-5'>No list found yet!</h3>
                                    </Col>
                                </Row>
                            }
                        </Row>
                    </Skeleton>
                    {/* /////content end */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Merchants