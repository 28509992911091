import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button, Modal, Spin, Tabs } from 'antd';
import {
    DeleteFilled
} from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import notFound from "../../assets/img/not-found.png";
import 'react-phone-input-2/lib/style.css'
import { fileUrl } from '../../constants/const';
import firebase from 'firebase';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import moment from 'moment';
import { chatCounter } from '../../actions/users';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;

function Chat() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState('');
    const [allMessages, setAllMessages] = useState('');
    const [usermessage, setUserMessage] = useState('');
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    const [modal2Open, setModal2Open] = useState(false);
    const [noUser, setNoUser] = useState(false);
    const [chatWith, setChatWith] = useState('');
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const firebaseConfig = {
        apiKey: "AIzaSyABkwzAPHGydveK2dx21sKWxH-hV5ci_lk",
        authDomain: "thecorner-stone.firebaseapp.com",
        projectId: "thecorner-stone",
        storageBucket: "thecorner-stone.appspot.com",
        messagingSenderId: "350307653009",
        appId: "1:350307653009:web:56843f8a22d660dbd2b630",
        measurementId: "G-4F5SFHTDX5"
    };
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
    var db = firebase.firestore();

    async function GetChatlist() {
        setLoading(true)
        db
            .collection('Chatrooms')
            .where('members', 'array-contains', userDetail._id)
            .onSnapshot(onResult, onError);
        async function onResult(QuerySnapshot) {
            let AllChatroomMember = [];
            QuerySnapshot.forEach(doc => {
                let othersid = doc.data().members.filter(members => members != userDetail._id);
                AllChatroomMember.push({
                    Userdata: othersid[0],
                    lastmessage: doc.data().lastmessage,
                    _id: doc.data()._id,
                    UnreadCount: doc.data().UnreadCount,
                    UpdatedAt: doc.data().UpdatedAt,
                });
            });
            for (let i = 0; i < AllChatroomMember.length; i++) {
                let user = (
                    await db
                        .collection('Users')
                        .doc(AllChatroomMember[i].Userdata)
                        .get()
                ).data();
                let unreadcount = (
                    await db
                        .collection('Chatrooms')
                        .doc(AllChatroomMember[i]._id)
                        .collection('message')
                        .where('read', '==', false)
                        .where('sender', '!=', userDetail._id)
                        .get()
                ).size;
                AllChatroomMember[i].Userdata = user;
                AllChatroomMember[i].UnreadCount = unreadcount;
                // console.log(unreadcount)
                let data = {
                    chatcounter: unreadcount
                }
                dispatch(chatCounter({
                    data
                }))
            }            
            setUsers(AllChatroomMember)
            getChatHistory(AllChatroomMember[0])
            setChatWith(AllChatroomMember[0])
            setLoading(false)
        }

        function onError(error) {
            console.error("<<<<<<<<<<<<<<<<<", error);
        }
    }

    async function getChatHistory(element) {
        return await db
            .collection('Chatrooms')
            .doc(element._id)
            .collection('message')
            .orderBy('createdAt', 'asc')
            .onSnapshot(onResult, onError);
        async function onResult(QuerySnapshot) {
            let Allmessages = [];
            QuerySnapshot.forEach(doc => {
                Allmessages.push(doc.data());
            });
            setAllMessages(Allmessages)            
            const theElement = document.getElementById('chat-history');
            theElement.scrollTop = theElement.scrollHeight;
            setChatWith(element)
        }

        function onError(error) {
            console.error(error);
        }
    }

    function writeMessage(e) {
        setUserMessage(e.target.value)
    }

    async function onSendMessage() {
        if (usermessage === "")
            return false;

        let Roomid = chatWith ? chatWith._id : ""

        let reciverid =
            Roomid?.split('_')[0] == chatWith ? chatWith?.Userdata?._id : ""
                ? Roomid?.split('_')[1]
                : Roomid?.split('_')[0];

        let messageData = {
            message: usermessage,
            createdAt: new Date().valueOf(),
            sender: userDetail._id,
            reciver: reciverid,
            imageuri: '',
            read: false,
            type: "message",
        }

        await db
            .collection('Chatrooms')
            .doc(chatWith ? chatWith?._id : "")
            .collection('message')
            .add(messageData);
        setUserMessage('')
    }

    const hideModal = () => {
        setModal2Open(false);
    }

    async function onDelete() {
        try {
            await db
            .collection('Chatrooms')
            .doc(chatWith ? chatWith?._id : "")
            .delete()
            .then(() => {
                setModal2Open(false);
            });
        } catch (error) {
            setModal2Open(false);
            addToast("No chats available!", { appearance: 'error', autoDismiss: true });            
        }       
    };

    function onSearchMessage(e) {
        var filter = e.target.value.toLowerCase();
        var nodes = document.getElementsByClassName('target');

        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].innerText.toLowerCase().includes(filter)) {
                nodes[i].style.display = "block";
                setNoUser(false)
            } else {
                nodes[i].style.display = "none";
                setNoUser(true)
            }
        }
    }

    function tabPanel() {
        if (userDetail.user_type === "0") {
            return (
                <>
                    <Tabs.TabPane tab="Merchant" key="0" >
                        {users.length > 0 && users ? users.map((element, index) => {
                            if (element?.Userdata?.user_type === "1") {
                                return (
                                    <li className="clearfix plist target" onClick={() => getChatHistory(element)}>
                                        <img src={element?.Userdata?.profile_pic === '' ? defaultIcon : fileUrl + element?.Userdata?.profile_pic} alt="avatar" />
                                        <div className="about">
                                            <div className="name">{element?.Userdata?.username}</div>
                                            <div className="status">
                                                <i className="fa fa-circle online"></i> {element?.Userdata?.Online ? "Online" : "Offline"}
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        }) :
                            <li>
                                <Row className='center pt-5 mt-5'>
                                    <Col>
                                        <img src={notFound} style={{ width: "200px" }} />
                                        <br></br>
                                        <h6 className='pt-5'>No users found yet!</h6>
                                    </Col>
                                </Row>
                            </li>
                        }

                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Vendor" key="1" >
                        {users.length > 0 && users ? users.map((element, index) => {
                            if (element?.Userdata?.user_type === "2") {
                                return (
                                    <li className="clearfix plist target" onClick={() => getChatHistory(element)}>
                                        <img src={element?.Userdata?.profile_pic === '' ? defaultIcon : fileUrl + element?.Userdata?.profile_pic} alt="avatar" />
                                        <div className="about">
                                            <div className="name">{element?.Userdata?.username}</div>
                                            <div className="status">
                                                <i className="fa fa-circle online"></i> {element?.Userdata?.Online ? "Online" : "Offline"}
                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        }) :
                            <li>
                                <Row className='center pt-5 mt-5'>
                                    <Col>
                                        <img src={notFound} style={{ width: "200px" }} />
                                        <br></br>
                                        <h6 className='pt-5'>No users found yet!</h6>
                                    </Col>
                                </Row>
                            </li>
                        }

                    </Tabs.TabPane>
                </>
            )
        }
        else if (userDetail.user_type === "1" || userDetail.user_type === "2") {
            return (
                <Tabs.TabPane tab="Producer" key="0" >
                    {users.length > 0 && users ? users.map((element, index) => {
                        if (element?.Userdata?.user_type === "0") {
                            return (
                                <li className="clearfix plist target" onClick={() => getChatHistory(element)}>
                                    <img src={element?.Userdata?.profile_pic === '' ? defaultIcon : fileUrl + element?.Userdata?.profile_pic} alt="avatar" />
                                    <div className="about">
                                        <div className="name">{element?.Userdata?.username}</div>
                                        <div className="status">
                                            <i className="fa fa-circle online"></i> {element?.Userdata?.Online ? "Online" : "Offline"}
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                    }) :
                        <li>
                            <Row className='center pt-5 mt-5'>
                                <Col>
                                    <img src={notFound} style={{ width: "200px" }} />
                                    <br></br>
                                    <h6 className='pt-5'>No users found yet!</h6>
                                </Col>
                            </Row>
                        </li>
                    }

                </Tabs.TabPane>
            )
        }


    }


    useEffect(() => {
        GetChatlist();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" xs={12} lg={21}>
                            <div>
                                <h5 className='color-theme semi-bold'>Chats</h5>
                            </div>
                        </Col>
                    </Row>

                    <Row className="clearfix mt-5" >
                        <Col className="people-list mb-3" id="people-list" xs={24} sm={8} md={8} lg={8} >
                            <div className="search" id="Search">
                                <input type="text" onChange={onSearchMessage} style={{ background: "#f2f5f8", border: "1px solid #dfd1d0", color: "#242424", height: "47px" }} placeholder="search..." />
                            </div>
                            <Spin spinning={loading}>

                                <ul className="list ">
                                    <Tabs defaultActiveKey="0" className="pe-1 ps-1 chat-tab">
                                        {tabPanel()}
                                        {noUser ?
                                            <li>
                                                <Row className='center pt-5 mt-5'>
                                                    <Col>
                                                        <img src={notFound} style={{ width: "200px" }} />
                                                        <br></br>
                                                        <h6 className='pt-5'>No users found yet!</h6>
                                                    </Col>
                                                </Row>
                                            </li>

                                            : ""
                                        }
                                    </Tabs>
                                </ul>

                            </Spin>
                        </Col>

                        <Col className="chat" xs={24} sm={16} md={16} lg={16}>
                            <div className="chat-header clearfix">
                                <img src={chatWith ? chatWith?.Userdata?.profile_pic === '' ? defaultIcon : fileUrl + chatWith?.Userdata?.profile_pic : defaultIcon} alt="avatar" />
                                <div className="chat-about">
                                    {chatWith ?
                                        <div className="chat-with text-dark"><span style={{ fontWeight: "400" }}>Chat with</span> {chatWith ? chatWith?.Userdata?.username : ""}</div>
                                        : ""}
                                </div>
                                <div style={{ float: "right" }}>
                                    <DeleteFilled onClick={() => setModal2Open(true)} style={{ color: "#ec220d", fontSize: "1.5rem", cursor: "pointer" }} />
                                </div>

                            </div>
                            <div className="chat-history" id="chat-history">
                                <ul>
                                    {allMessages.length > 0 && allMessages ? allMessages.map((element, index) => {
                                        
                                        return (
                                            <li className="clearfix">
                                                <div className={element.sender === userDetail._id ? "message-data align-right" : "message-data "}>
                                                    <span className="message-data-time" >{moment(element.createdAt).format(" Do MMM h:mm a")}</span> &nbsp; &nbsp;
                                                </div>
                                                <div className={element.sender === userDetail._id ? "message other-message float-right" : "message my-message"} >
                                                    {element?.message}
                                                </div>
                                            </li>
                                        )
                                    }) :
                                        <li>
                                            <Row className='center pt-5 mt-5'>
                                                <Col>
                                                    <img src={notFound} style={{ width: "300px" }} />
                                                    <h3 className='pt-5'>No message found yet!</h3>
                                                </Col>
                                            </Row>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="chat-message clearfix">
                                <textarea
                                    name="message-to-send"
                                    id="message-to-send"
                                    placeholder="Type your message"
                                    rows="3"
                                    onChange={writeMessage}
                                    value={usermessage}
                                />
                                <i className="fa fa-file-o"></i> &nbsp;&nbsp;&nbsp;
                                <i className="fa fa-file-image-o"></i>
                                <Button onClick={() => onSendMessage()} type="primary" size='large' className='pe-5 ps-5'>Send Message</Button>
                            </div>
                        </Col>
                    </Row>
                    {/* delete modal */}

                    <Modal
                        title="Delete Chat"
                        visible={modal2Open}
                        onOk={onDelete}
                        onCancel={hideModal}
                        cancelText="No"
                        okText="Yes"
                        className='filter-modal'
                    >
                        <div className='mb-4 pt-3 text-center'>
                            <p>Are you sure you want to<br></br> delete this chat list?</p>
                        </div>
                    </Modal>

                    {/* /////content end */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Chat