import React, { useState, useEffect, useRef } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { updatePassword } from '../../actions/users';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;

function ProfileChangePassword() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    ///update password
    const onFinish = (value) => {
        console.log(value)
        if (value.new_password != value.confirm_password) {
            addToast("Both password does not match!", { appearance: 'error', autoDismiss: true });
            return false;
        }
        //////
        let data = {
            old_password: value.old_password,
            new_password: value.new_password
        }
        setLoading(true);
        dispatch(updatePassword({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#profile'
            },
            failureCb: (response) => {
                setLoading(false);
                console.log(response)
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Change Password</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" span={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5 change-password-form"
                                valuepropname="fileList"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="old_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your old password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="new_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="New Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="confirm_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Confirm Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item className='pe-5 ps-5 '>
                                    <Spin spinning={loading}>
                                        <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Save</Button>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default ProfileChangePassword