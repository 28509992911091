import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, Spin } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { sendMessage } from '../../actions/settings';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'

const { Content } = Layout;

function ContactUs() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));

    function getUser() {
        setFields([
            {
                name: ['full_name'],
                value: userDetail.full_name,
            },
            {
                name: ['user_name'],
                value: userDetail.user_name,
            }
        ])
    }

    function onFinish(value) {
        let data = {
            message: value.message,
            email: userDetail.email,
            name: userDetail.full_name,
            phone: userDetail.phone_number,
        }
        setLoading(true);
        dispatch(sendMessage({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#events'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'error', autoDismiss: true });
            }
        }))

    }

    useEffect(() => {
        getUser();
    }, [])



    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" xs={24} lg={21}>
                            <div>
                                <h5 className='color-theme semi-bold'>Contact Us</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" xs={24} lg={18}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5 change-password-form"
                                valuepropname="fileList"
                                fields={fields}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="full_name"
                                >
                                    <Input
                                        placeholder="Full Name" disabled
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="user_name"
                                >
                                    <Input
                                        placeholder="User Name" disabled
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your message!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea style={{ height: "200px" }}
                                        placeholder="Message"
                                    />
                                </Form.Item>
                                <Form.Item className='pt-4 center text-center col-lg-4 col-xs-12' >
                                    <Spin spinning={loading} >
                                        <Button type="primary" className="col-lg-4 col-xs-12" style={{ height: "50px",width:"100%" }} htmlType="submit" size='large' xs={24}>Send</Button>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default ContactUs