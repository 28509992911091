import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const fetchNotifications = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}notifications`,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const delAllNotification = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'delete',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}notifications/all`,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const updateNotification = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}producer/respond_to_request/`+data._id,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}
