import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, List, Avatar, Button, Skeleton, Modal, Form ,Rate} from 'antd';
import {
    EnvironmentFilled,
    ClockCircleFilled,
    CalendarFilled
} from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchEvents ,addFeedack} from '../../actions/event';
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import notFound from "../../assets/img/not-found.png";
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import timezone from 'moment-timezone';
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
const { Content } = Layout;

function EventHistory() {
    const dispatch = useDispatch();
    const [events, setEvents] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [eventId, setEventId] = useState('');
    const [feedbackText, setFeedbackText] = useState(' ');
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    const [value, setValue] = useState(1);
    const [confirmLoading, setConfirmLoading] = useState(false);
    let timeZ = timezone.tz.guess();
    const { addToast } = useToasts();

    const loadData = async () => {
        setLoading(true)
        let data = {
            time_zone: timeZ,
            type: 1,
            joined: 1,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchEvents({
            data,
            successCb: (response) => {
                setEvents(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setEvents('')
                setLoading(false)
            }
        }))
    }

    function feedbackPop(id) {
        setModal2Open(true);
        setEventId(id);
    }

    function onFeedback() {
        setConfirmLoading(true);       
        let data = {
            _id: eventId,
            text: feedbackText,
            rating: value
        }
        dispatch(addFeedack({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setModal2Open(false);
                setConfirmLoading(false);
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'error', autoDismiss: true });
                setModal2Open(false);
                setConfirmLoading(false);
            }
        }))
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////search section */}
                    <Row gutter={24} className="pe-5 ps-5">
                        <Col className="gutter-row pb-5" span={21}>
                            <div>
                                <h5 className='color-theme semi-bold'>Event History</h5>
                            </div>
                        </Col>
                    </Row>
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="pe-5 ps-5">
                            {events.length > 0 && events ? events.map((element, index) => {
                                return (
                                    <Col className="gutter-row pb-2 mt-3 bg-gray pt-4" span={24}>
                                        <List
                                            itemLayout="horizontal"
                                        >
                                            <List.Item className='pt-0'>
                                                <List.Item.Meta
                                                    avatar={<Avatar
                                                        style={{ width: "100px", height: "100px" }}
                                                        src=
                                                        {element.event_image === '' ? defaultIcon : fileUrl + element.event_image}
                                                    />
                                                    }
                                                    title={
                                                        <a href={"/#/event-detail/" + element._id}>
                                                            <p><span>{element.event_name}</span></p>
                                                            <p className='mb-2'><EnvironmentFilled /> <span className='text-normal ps-2'>{element.location?.name}</span></p>
                                                            <p className='mb-2'><CalendarFilled /> <span className='text-normal ps-2'>{moment(element.event_start_time).format(" Do MMM YYYY")}</span></p>
                                                            <p><ClockCircleFilled /> <span className='text-normal ps-2'>{moment(element.event_start_time).format("hh:mm A")}</span></p>
                                                        </a>
                                                    }
                                                />
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <small style={{ fontSize: "14px" }}>Today: {moment().format("hh:mm A")}</small>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Button type="primary" htmlType="submit" size='large' onClick={() => feedbackPop(element._id)} className='mt-5'>Feedback</Button>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        </List>
                                    </Col>
                                )
                            }) :
                                <Row className='center pt-5 mt-5'>
                                    <Col>
                                        <img src={notFound} style={{ width: "300px" }} />
                                        <h3 className='pt-5'>No list found yet!</h3>
                                    </Col>
                                </Row>
                            }
                        </Row>
                    </Skeleton>
                    {/* /////content end */}
                    {/* /////modal */}
                    <Modal
                        title="How was your experiance?"
                        visible={modal2Open}
                        onOk={onFeedback}
                        onCancel={() => setModal2Open(false)}
                        cancelText="Cancel"
                        okText="Send"
                        className='experiance-modal'
                        confirmLoading={confirmLoading}
                    >

                        <div className='mb-4 pt-3 text-center'>
                            <Form
                                name="store"
                                className="pt-3 change-password-form"
                                valuepropname="fileList"
                            >
                                <div className='mb-4'>
                                    <Rate tooltips={desc} onChange={setValue} value={value} />
                                    &nbsp;&nbsp;&nbsp;
                                    {value ? <Button type="primary" htmlType="submit" size='small ' className='mt-5'><span className="ant-rate-text">{desc[value - 1]}</span></Button> : ''}
                                </div>
                                <Form.Item>
                                    <textarea
                                        onChange={(e) => {
                                            setFeedbackText(e.target.value);
                                        }}
                                        className="ant-input"
                                        placeholder="Feedback..."
                                        style={{ height: "180px" }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>

                    </Modal>
                    {/* /////modal */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default EventHistory