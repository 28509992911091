import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { getTermsPrivacy } from '../../actions/settings';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import parse from 'html-react-parser';

const { Content } = Layout;

function Terms() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [terms, setTerms] = useState('')

    function getData() {
        dispatch(getTermsPrivacy({
            successCb: async (response) => {
                setTerms(response.data.terms)
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-4" span={21}>
                            <div>
                                <h5 className='color-theme semi-bold'>Terms & Conditions</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" span={18}>
                            {parse(terms)}
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Terms