//auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

//after auth
export const USER_DETAILS = "USER_DETAILS";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const DRAWER_LIST = "DRAWER_LIST";
////
export const EVENT_LIST = "EVENT_LIST";
export const EVENT_DETAILS = "EVENT_LIST";
export const EVENT_CREATE = "EVENT_CREATE";
export const EVENT_TYPE = "EVENT_TYPE";
export const EVENT_DELETE = "EVENT_DELETE";
export const EVENT_UPDATE = "EVENT_UPDATE";
/////
export const CARD_LIST = "CARD_LIST";
export const CARD_DEFAULT_LIST = "CARD_DEFAULT_LIST";
export const CHAT_NOTI = "CHAT_NOTI";


export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EVENT_TYPE_LIST = "EVENT_TYPE_LIST";
