import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Upload,Button } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchUserData, updateProfile } from '../../actions/users';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;

function ProfileUpdate() {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [fileList, setFileList] = useState([]);
    const { addToast } = useToasts();
    let profileImages = [];

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };


    const profileDetails = async () => {
        let data = {}
        dispatch(fetchUserData({
            data,
            successCb: (response) => {
                let list = response.data;
                let images = list?.profile_images;
                images.map((element, index) => {

                    let items = {
                        uid: element?._id,
                        name: 'image.png',
                        status: 'done',
                        url: fileUrl + element?.value,
                    }            
                    profileImages.push(items);
                })

                setFileList(profileImages)
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let pram = newFileList.length-1        
        const formData = new FormData();
        formData.append("image_"+pram, newFileList[newFileList.length-1].originFileObj);
        dispatch(updateProfile({
            formData,
            successCb: async (response) => {                
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                profileDetails();
            },
            failureCb: (response) => {
                addToast(response.message, { appearance: 'error', autoDismiss: true });
            }
        }))
    };


    useEffect(() => {
        profileDetails();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Add Images</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2 mt-5 pt-5" md={24} lg={24}>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {fileList.length < 6 && '+ Upload'}
                            </Upload>
                        </Col>
                        <Col className="gutter-row pb-2 mt-5 pt-5" md={24} lg={24}>
                            <a href="#/profile-update">
                                <Button type="primary" size='large' className='pe-5 ps-5'> Back to profile</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default ProfileUpdate