import { CARD_LIST,CARD_DEFAULT_LIST } from "./types";
import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const fetchSavedCards = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}/cards`,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: CARD_LIST,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchDefaultCards = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}/account`,
    }
    try {
        const res = await apiCall(config, dispatch);
        dispatch({
            type: CARD_DEFAULT_LIST,
            payload: res.data
        });

        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const addCard = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}/cards`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {        
        if (typeof failureCb === 'function') {          
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const doDefaultCard = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}/account`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const doDelDefaultCard = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'delete',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}/cards`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);       
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}
