import React, { useState, useRef, useEffect } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Layout, Button, Form, Input, Row, Col, Radio, Popconfirm, Spin, Select } from 'antd';
import { PhoneInput } from "react-contact-number-input";
import 'react-phone-input-2/lib/style.css'
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import logo from '../../assets/img/logo/logo.gif'
import bg from '../../assets/img/bg/signup.png'
import { googleKey } from '../../constants/const';
import { registerUser, getCategoriesData, getSubCategoriesData } from '../../actions/auth';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

const { Content } = Layout;
const { Option } = Select;

function Register() {
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState('');
    const [loading, setLoading] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [showErr_, setShowErr_] = useState(false);
    const [errVal, setErrVal] = useState('');
    const [catData, setCatData] = useState([]);
    const [catSubData, setSubCatData] = useState([]);
    const [number, setNumber] = useState(0);
    const [catId, setcatId] = useState('');
    const [catSubId, setcatSubId] = useState('');
    const [userType, setUserType] = useState('1');
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const inputRef = useRef();

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            let locate = {
                "cordinates": { "lat": place.geometry.location.lat(), "long": place.geometry.location.lng() }, "name": place.formatted_address
            }
            setErrVal("*")
            setShowErr(false)
            setLocation(locate);
        }
    }

    const onFinish = (values) => {
        values.location = location
        values.fcm_token = '*'
        values.country_code = countryCode
        values.phone_number = phoneNumber
        values.category_id = catId
        values.subcategory_id = catSubId
        values.user_type = !values.user_type ? "1" : values.user_type;

        setLoading(true);
        let data = values
        dispatch(registerUser({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });
                window.location.href = '#verification'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const getCategories = () => {
        dispatch(getCategoriesData({
            successCb: async (response) => {
                setCatData(response.data);
            },
            failureCb: (response) => {
                //////
                setCatData([]);
            }
        }))
    }


    const onFinishFailed = (values) => {
        if (errVal === "") {
            setShowErr(true)
        }

        else {
            setShowErr(false)
        }
        console.log(phoneNumber)
        if (phoneNumber === null) {
            setShowErr_(true)
        }
        else {
            setShowErr_(false)
        }
    }

    function digitKeyOnly(event) {
        if (event.which != 8 && isNaN(String.fromCharCode(event.which))) {
            event.preventDefault();
            setNumber(event.target.value)
            return true;
        }
        else {
            return false;
        }
    }

    function placeEnter(e) {
        if (e.target.value === "") {
            setErrVal("")
            setShowErr(true)
        }
    }

    const selectCategory = (value) => {

        if (value === "630f2e8252de247cce6c76a4") {
            setcatId(value);
            dispatch(getSubCategoriesData({
                successCb: async (response) => {
                    setSubCatData(response.data);
                },
                failureCb: (response) => {
                    //////
                    setSubCatData([]);
                }
            }))
        }
        else {
           setcatId(value);
            setSubCatData([]);
        }
    };

    const selectSubCategory = (value) => {
        setcatSubId(value)
    };

    const handleOnChange = (value) => {
        setCountryCode(value.countryCode)
        setPhoneNumber(value.phoneNumber)
        setShowErr_(false)
    }

    const handleOnChangeUserType = (value) => {
        setUserType(value)
    }

    useEffect(() => {
        getCategories();
    }, [])


    return (
        <Layout className="layout">
            <Content>
                {/* register section */}

                <div className='container-fluid'>
                    <Row gutter={24} className="bg-white" >
                        <Col className="gutter-row background-theme mnone" lg={12} xs={24}>
                            <div className='auth-bg mt-5 ball'>
                                <img src={bg} />
                            </div>
                        </Col>
                        <Col className="gutter-row auth xs-plr-1 xs-mt-0 p-5 mt-5" lg={12} xs={24}>
                            <div className="text-center center d-flex pb-5 pt-5">
                                <div className="logo">
                                    <a href='#'>
                                        <img src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4><strong>Sign Up </strong></h4>
                                <p className='text-gray'>Please fill the details and create account</p>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form pt-3 pb-5"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    name="full_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Full Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Full Name" />
                                </Form.Item>
                                <Form.Item
                                    name="user_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Username!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="ein_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your EIN Number!',
                                        },
                                    ]}
                                >
                                    <Input size="9" value={number} maxlength="9" onKeyPress={digitKeyOnly} placeholder="Enter EIN Number" />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    name="location"
                                >
                                    <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                        <StandaloneSearchBox
                                            onLoad={ref => inputRef.current = ref}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <input
                                                type="text"
                                                className="ant-input"
                                                placeholder="Business Address"
                                                onChange={(e) => placeEnter(e)}
                                            />
                                        </StandaloneSearchBox>
                                    </LoadScript>
                                    {showErr ?
                                        <span style={{ color: "#ff4d4f" }}>Please enter your business address!</span>
                                        : ""}
                                </Form.Item>
                                <Form.Item
                                    name="company"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your company name!',
                                        }
                                    ]}
                                >
                                    <Input placeholder="Company Name / Business Name" />
                                </Form.Item>
                                {userType === '1' ?
                                    <Form.Item
                                        name="category_id"
                                        rules={[{
                                            required: true,
                                            message: 'Please select category!',
                                        }]}
                                    >
                                        <Select
                                            defaultValue=""
                                            className='col-12'
                                            style={{
                                                background: "#F6F6F6",
                                            }}
                                            onChange={selectCategory}
                                        >

                                            {catData.length > 0 && catData ? catData.map((element, index) => {
                                                return (
                                                    <Option value={element._id}>{element.name}</Option>
                                                )
                                            }) :
                                                <Option value="">Select Category</Option>
                                            }
                                        </Select>
                                    </Form.Item> : ""}

                                {catId === "630f2e8252de247cce6c76a4" ?
                                    <Form.Item
                                        name="subcategory_id"
                                        rules={[{
                                            required: true,
                                            message: 'Please select subcategory!',
                                        }]}
                                    >
                                        <Select
                                            defaultValue=""
                                            className='col-12'
                                            style={{
                                                background: "#F6F6F6",
                                            }}
                                            onChange={selectSubCategory}
                                        >

                                            {catSubData.length > 0 && catSubData ? catSubData.map((element, index) => {
                                                return (
                                                    <Option value={element._id}>{element.name}</Option>
                                                )
                                            }) :
                                                <Option value="">Select Sub Category</Option>
                                            }
                                        </Select>
                                    </Form.Item>
                                    : ""}

                                <Form.Item
                                    name="phone_number"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please input your phone number!',
                                //     },
                                // ]}
                                >
                                    <PhoneInput
                                        // countryCode={currentCountryCode}
                                        onChange={handleOnChange}
                                        placeholder="**********"
                                        style={{ width: "100%" }}
                                    />
                                    {showErr_ ?
                                        <span style={{ color: "#ff4d4f" }}>Please input your phone number!</span>
                                        : ""}

                                    <div className='d-flex'>

                                        {/* <PhoneInput
                                            enableSearch={true}
                                            country={'us'}
                                            value={countryCode}
                                            onChange={(countryCode) => setCountryCode(countryCode)}
                                            style={{ width: "120px" }}
                                        />
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Enter phone number"
                                        /> */}
                                    </div>
                                </Form.Item>


                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item name="user_type" className='text-center'>
                                    <Radio.Group defaultValue={"1"}>
                                        <Popconfirm placement="top" onClick={(e) => handleOnChangeUserType("1")} title={'My company has one or more locations where members can purchase goods & services'}>
                                            <Radio value="1" >Merchant</Radio>
                                        </Popconfirm>
                                        <Popconfirm placement="top" onClick={(e) => handleOnChangeUserType("0")} title={'My company plans, coordinates, and executes events in the events industry'}>
                                            <Radio value="0">Producers</Radio>
                                        </Popconfirm>
                                        <Popconfirm placement="top" onClick={(e) => handleOnChangeUserType("2")} title={'My company’s employees often travel in order to sell our products at in-person events'}>
                                            <Radio value="2">Vendor</Radio>
                                        </Popconfirm>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Button type="primary" htmlType="submit" className="login-form-button col-lg-12 pt-4 pb-5">
                                            Sign Up
                                        </Button>
                                    </Spin>
                                </Form.Item>
                                <div className='pt-5 mt-2 text-center'>
                                    Already have an account?
                                    <a className="login-form-forgot text-dark" href="#login"><b> Login</b></a>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>

            </Content>
        </Layout>
    )
}

export default Register