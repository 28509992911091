import React, { useState, useRef, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button, DatePicker, Upload, Radio, Select } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { googleKey, baseUrl } from '../../constants/const';
import { CameraFilled } from '@ant-design/icons';
import { createEvent, getEventTypeData } from '../../actions/event';
import timezone from 'moment-timezone';
import { useToasts } from 'react-toast-notifications';
import { useDispatch ,useSelector} from 'react-redux';
import moment from 'moment';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

function EventsCreate() {
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState('');
    const inputRef = useRef();
    const [eventType, setEventType] = useState("0");
    const [eventTypeId, setEventTypeId] = useState("");
    const [getEventTypeId, setGetEventTypeId] = useState("");
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventPics, setEventPics] = useState([]);
    const [eventBluepringPics, setBlueprintEventPics] = useState([]);
    const [number, setNumber] = useState(0);
    let timeZ = timezone.tz.guess();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    
    // const accountLinked = useSelector(state => state?.users);
    // if(!accountLinked?.data?.account_linked){
    //     window.location.href = '#account-details'        
    // }

    const onChangeEventType = (e) => {
        setEventType(e.target.value);
    };

    const selectEventType = (value) => {
        setEventTypeId(value);
    };

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            let locate = {
                "cordinates": { "lat": place.geometry.location.lat(), "long": place.geometry.location.lng() }, "name": place.formatted_address
            }
            setLocation(locate);
        }
    }

    const onChangeDate = (value, dateString) => {
        setStartTime(dateString[0])
        setEndTime(dateString[1])
    };

    const eventImage = (e) => {
        setEventPics(e.file.originFileObj);
    };

    const eventBlueprintImage = (e) => {
        setBlueprintEventPics(e.file.originFileObj);
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        // console.log(current)
        return current && current < moment().startOf('day');
    };
    
    function disabledDateTime() {
        // Disable all times before current time
        const currentHour = moment().hour()+1;
        const currentMinute = moment().minute();
        return {
          disabledHours: () => [...Array(currentHour).keys()],
        };
      }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onFinish = (value) => {
        const formData = new FormData();

        const startDatetime = moment(startTime);
        const endDatetime = moment(endTime);

        const duration = moment.duration(endDatetime.diff(startDatetime));
        if(duration.asHours() < 3){
            addToast("Start Date/Time must be greater then current date time by 3 hours", { appearance: 'error', autoDismiss: true });
            return;
        }
        

        formData.append('event_name', value.event_name);
        formData.append('event_type', eventType);
        formData.append('location', JSON.stringify(location));
        formData.append('event_start_time', startTime);
        formData.append('event_end_time', endTime);
        formData.append('time_zone', timeZ);
        formData.append('vacancies', value.vacancies);
        formData.append('description', value.description);
        formData.append('event_type_id', eventTypeId);
        formData.append('search_tags', value.event_tags);
        formData.append('event_image', eventPics);
        formData.append('event_blueprint', eventBluepringPics);
        formData.append('link', value.link);
        if(eventType==="1")
        formData.append('amount', value.amount);

        setLoading(true);
        dispatch(createEvent({
            formData,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#events'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const getEventType = () => {
        dispatch(getEventTypeData({
            successCb: async (response) => {
                setGetEventTypeId(response.data);
            },
            failureCb: (response) => {
                //////
            }
        }))
    }

    function digitKeyOnly(event) {
        if (event.which != 8 && isNaN(String.fromCharCode(event.which))) {
            event.preventDefault();
            setNumber(event.target.value)
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        getEventType();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////event create section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Create Event</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" span={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5 event-create"
                                initialValues={{
                                    remember: true,
                                }}
                                getValueFromEvent={normFile}
                                onFinish={onFinish}
                                valuepropname="fileList"
                            >
                                <Form.Item label="" valuepropname="fileList">
                                    <Upload onChange={eventImage} name="logo" className="col-lg-12" listType="picture-card">
                                        {
                                            eventPics.length == 0 ?
                                                <div>
                                                    <CameraFilled />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Upload Photo
                                                    </div>
                                                </div> : ''
                                        }
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="event_type_id"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Type!',
                                    }]}
                                >
                                    <Select
                                        defaultValue=""
                                        className='col-12'
                                        style={{
                                            background: "#F6F6F6",
                                        }}
                                        onChange={selectEventType}
                                    >

                                        {getEventTypeId.length > 0 && getEventTypeId ? getEventTypeId.map((element, index) => {
                                            return (
                                                <Option value={element._id}>{element.name}</Option>
                                            )
                                        }) :
                                            <Option value="">Event Type</Option>
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="event_name"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Name!',
                                    }]}
                                >
                                    <Input placeholder="Event Name" />
                                </Form.Item>                              
                                <Form.Item
                                    name="location"
                                >
                                    <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                        <StandaloneSearchBox
                                            onLoad={ref => inputRef.current = ref}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <input
                                                type="text"
                                                className="ant-input"
                                                placeholder="Location"
                                            />
                                        </StandaloneSearchBox>
                                    </LoadScript>
                                </Form.Item>
                                <Form.Item
                                    name="event_tags"
                                >
                                    <Input placeholder="Search Tags eg:- abc,xyz" />
                                </Form.Item>

                                <Form.Item>
                                    <RangePicker
                                        showTime={{
                                            format: 'HH:mm',
                                        }}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={onChangeDate}
                                        disabledDate={disabledDate}
                                        disabledTime={disabledDateTime}

                                    />
                                </Form.Item>
                                <Form.Item
                                    name="link"
                                >
                                    <Input placeholder="Website Name" />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Time!',
                                    }]}
                                >
                                    <TextArea rows={4} placeholder="Description" />
                                </Form.Item>

                                <Radio.Group onChange={onChangeEventType} value={eventType} className="mb-4">
                                    <Radio value={"0"}>Free</Radio>
                                    <Radio value={"1"}>Paid</Radio>
                                </Radio.Group>

                                <Row gutter={16}>
                                    {eventType === "1" ?
                                        <>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="amount"
                                                    rules={[{
                                                        message: 'Please input your value!',
                                                    }]}
                                                >
                                                    <Input value={number} onKeyPress={digitKeyOnly} placeholder="Enter amount" />
                                                </Form.Item>
                                            </Col>
                                        </>
                                        : ""}
                                    <Col span={8}>
                                        <Form.Item
                                            name="vacancies"
                                            rules={[{
                                                required: true,
                                                message: 'Please input your value!',
                                            }]}
                                        >
                                            <Input placeholder="No. of spaces available:" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Form.Item label="" className='pt-3'>
                                    <Form.Item name="dragger" valuepropname="fileList" getValueFromEvent={normFile} noStyle>
                                        <Upload.Dragger onChange={eventBlueprintImage} name="files" action="" className='pt-5 pb-5'>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                Please provide the blueprint of the area so it would be <br></br>
                                                convenient for the people to find their required arena (Optinal)
                                            </p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Col className="gutter-row text-center" lg={24}>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>CREATE</Button>
                                        </Col>
                                    </Spin>
                                    
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default EventsCreate