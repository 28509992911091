import React, { useState, useEffect } from 'react';
import {
    EnvironmentFilled,
    ClockCircleFilled,
    CalendarFilled
} from '@ant-design/icons';
import { Layout, Row, Col, Skeleton, Card, Avatar, Tabs ,Image} from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchFavoriteEvents, markAsFavorite, fetchFavoriteProducers } from '../../actions/event';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import timezone from 'moment-timezone';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import notFound from "../../assets/img/not-found.png";
import bookmarkun from "../../assets/img/icons/bookmarkun.png";
import bookmark from "../../assets/img/icons/bookmark.png";
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;
const { Meta } = Card;

function Favorite() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [events, setEvents] = useState(0);
    const [producers, setProducers] = useState('');
    const [loading, setLoading] = useState(false);
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    let timeZ = timezone.tz.guess();

    const loadEventData = async (type) => {
        setLoading(true)
        let data = {
            time_zone: timeZ,
            type: 0,
            joined: type == 1 ? type : "",
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchFavoriteEvents({
            data,
            successCb: (response) => {
                setEvents(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setEvents('')
                setLoading(false)
            }
        }))
    }

    const loadProduersData = async () => {
        let data={
            user_type: userDetail.user_type
        }
        setLoading(true)
        dispatch(fetchFavoriteProducers({
            data,
            successCb: (response) => {
                setProducers(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setProducers(false)
                setLoading(false)
            }
        }))
    }

    const onChangeTab = (key) => {        
        key==="1"?loadEventData(key):loadProduersData();        
    };

    function joinedMerchants(items) {

        return (
            <>
                {items.length > 0 && items ? items.map((element, index) => {
                    return (
                        <Avatar src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                    )
                }) :
                    ''
                }
            </>
        )
    }

    function goToEvent(id) {
        document.location.href = "#event-detail/" + id
    }

    function markFavorite(id) {
        let data = {
            event_id: id,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(markAsFavorite({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                loadEventData(0);
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'success', autoDismiss: true });
            }
        }))
    }

    useEffect(() => {
        loadEventData(0);
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    <Tabs
                        defaultActiveKey="1"
                        onChange={onChangeTab}
                        className="fav-tab"
                        items={[
                            {
                                label: `Events`,
                                key: '1',
                                children: <Skeleton loading={loading} active avatar>
                                    {events.length > 0 && events ? events.map((element, index) => {
                                        return (
                                            <Row gutter={24} className="pt-1 pb-5" >
                                                <Col className="gutter-row" span={24}>
                                                    <Row gutter={24} className="event-price_top">
                                                        <Col className="gutter-row text-center" xs={6} lg={2}>
                                                            {element.event_type == 1 ?
                                                                <div className='price-type_top'>
                                                                    <p className='text-white p-2'>${element.amount}</p>
                                                                </div>
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                        <Col className="gutter-row event-avtar" onClick={() => markFavorite(element._id)} xs={14} lg={21}>
                                                            {element.is_saved ?
                                                                <img src={bookmark} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                                :
                                                                <img src={bookmarkun} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <Card
                                                        onClick={() => goToEvent(element._id)}
                                                        hoverable
                                                        cover={<div alt={events.event_name} className='card-cover' style={{ background: 'url(' + fileUrl + element.event_image + '' }} />}
                                                    >
                                                        <Row gutter={24} className="event-price">
                                                            <Col className="gutter-row text-center" xs={8} lg={2}>
                                                                {element.event_type == 1 ?
                                                                    <div className='price-type'>
                                                                        <p className='text-white p-1'>Paid</p>
                                                                    </div>
                                                                    :
                                                                    <div className='price-type-free '>
                                                                        <p className='text-white p-1'>Free</p>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col className="gutter-row event-avtar" xs={16} lg={21}>
                                                                <Avatar.Group
                                                                    maxCount={2}
                                                                    style={{ float: "right" }}>
                                                                    {joinedMerchants(element.merchant_joined)}
                                                                </Avatar.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row gutter={24}>
                                                            <Col className="gutter-row" span={22}>
                                                                <Meta title={element.event_name} />
                                                            </Col>
                                                            {/* <Col className="gutter-row" span={2}>
                                                        <img src={share} style={{ width: "20px" }} />
                                                    </Col> */}
                                                        </Row>

                                                        <Row gutter={24} className="event-locate">
                                                            <Col className="gutter-row d-flex pt-2 pb-2" span={24}>
                                                                <EnvironmentFilled />
                                                                <span className='ps-2'>{element.location?.name}</span>
                                                            </Col>
                                                            <Col className="gutter-row pt-2" xs={12} lg={4}>
                                                                <CalendarFilled />
                                                                <span className='ps-2'>{moment(element.event_start_time).format(" Do MMM YYYY")}</span>
                                                            </Col>
                                                            <Col className="gutter-row pt-2" xs={12} lg={4}>
                                                                <ClockCircleFilled />
                                                                <span className='ps-2'>{moment(element.event_start_time).format("h:mm a")}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                    }) :
                                        <Row className='center pt-5 mt-5'>
                                            <Col>
                                                <img src={notFound} style={{ width: "300px" }} />
                                                <h3 className='pt-5'>No favorite event found yet!</h3>
                                            </Col>
                                        </Row>
                                    }
                                </Skeleton>,
                            },
                            {
                                label: `Producers`,
                                key: '2',
                                children: <Skeleton loading={loading} active avatar>
                                    <Row gutter={24} className="pe-5 ps-5 mt-5 target">
                                        {producers.length > 0 && producers ? producers.map((element, index) => {
                                            return (
                                                <Col className="gutter-row pb-5 text-center" span={4}>
                                                    <a href={'/#/other-profile/' + element._id}>
                                                        <Image.PreviewGroup>
                                                            <Image src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                                                            <h6 className='pt-3'>{element.full_name}</h6>
                                                        </Image.PreviewGroup>
                                                    </a>
                                                </Col>
                                            )
                                        }) :
                                            <Row className='center pt-5 mt-5'>
                                                <Col>
                                                    <img src={notFound} style={{ width: "300px" }} />
                                                    <h3 className='pt-5'>No list found yet!</h3>
                                                </Col>
                                            </Row>
                                        }
                                    </Row>
                                </Skeleton>,
                            },
                        ]}
                    />

                    <Tabs defaultActiveKey="0" className="pe-5 ps-5 mp0 event-tab pro-e" onChange={onChangeTab}>
                        <Tabs.TabPane tab="Events" key="0" >

                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Joined" key="1">
                            <p>test</p>
                        </Tabs.TabPane>
                    </Tabs>
                    {/* /////event list section */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Favorite