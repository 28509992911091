import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button, Modal } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchUserData ,deleteMyProfile} from '../../actions/users';
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import TextArea from 'antd/lib/input/TextArea';
import { useToasts } from 'react-toast-notifications';
import { LOGOUT_SUCCESS } from '../../actions/types'
const { Content } = Layout;

function Profile() {
    const [profile, setProfile] = useState(0);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const profileDetails = async () => {
        let data = {}
        dispatch(fetchUserData({
            data,
            successCb: (response) => {
                setProfile(response.data);
                setFields([
                    {
                        name: ['full_name'],
                        value: response.data.full_name,
                    },
                    {
                        name: ['user_name'],
                        value: response.data.user_name,
                    },
                    {
                        name: ['ein_number'],
                        value: response.data.ein_number,
                    },
                    {
                        name: ['location'],
                        value: response.data.location?.name,
                    },
                    {
                        name: ['email'],
                        value: response.data.email,
                    },
                    {
                        name: ['phone_number'],
                        value: response.data.country_code + response.data.phone_number,
                    },
                    {
                        name: ['website'],
                        value: response.data.website,
                    },
                    {
                        name: ['description'],
                        value: response.data.description,
                    },
                    {
                        name: ['company'],
                        value: response.data.company,
                    },
                    {
                        name: ['category_id'],
                        value: response.data.category_id?.name,
                    },
                    {
                        name: ['subcategory_id'],
                        value: response.data.subcategory_id?.name,
                    },
                ])
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }

    const deleteConfirmModel=()=>{
        setDeleteConfirm(true)
    }

    function onDeleteProfile() {       
        setLoading(true);
        setConfirmLoading(true);
        dispatch(deleteMyProfile({            
            successCb: (response) => {
                setLoading(false);
                setConfirmLoading(false);               
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setTimeout(() => {
                    logoutService()
                }, 1000);
            },
            failureCb: (response) => {
                setLoading(false);
                setConfirmLoading(false);
                setDeleteConfirm(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function logoutService() {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({
            payload: false,
            type: LOGOUT_SUCCESS,
        });

        setTimeout(() => {
            document.location.href = "";
        }, 1000);
    }


    useEffect(() => {
        profileDetails();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Profile</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" md={24} lg={16} xs={24}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5"
                                valuepropname="fileList"
                                fields={fields}
                            >
                                <Form.Item label="" valuepropname="fileList">
                                    <div className='mb-3'>
                                        <div className="bg-cover" style={{ width: "100%", height: "250px", background: `url( ${profile.profile_pic === '' ? defaultIcon : fileUrl + profile.profile_pic})` }} ></div>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="full_name"
                                >
                                    <Input disabled placeholder="Full Name" />
                                </Form.Item>
                                <Form.Item
                                    name="user_name"
                                >
                                    <Input disabled placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                >
                                    <Input disabled placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="ein_number"
                                >
                                    <Input disabled placeholder="Enter EIN Number (Optional)" />
                                </Form.Item>
                                <Form.Item
                                    name="location"
                                >
                                    <Input disabled placeholder="location" />
                                </Form.Item>

                                <Form.Item
                                    name="phone_number"
                                >
                                    <Input disabled placeholder="phone number" />
                                </Form.Item>
                                <Form.Item
                                    name="company"
                                >
                                    <Input disabled placeholder="company" />
                                </Form.Item>
                                <Form.Item
                                    name="website"
                                >
                                    <Input disabled placeholder="website" />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                >
                                    <TextArea disabled placeholder="description" />
                                </Form.Item>
                                {profile?.user_type === "1" ?
                                    <Form.Item
                                        name="category_id"
                                        rules={[{
                                            required: true,
                                            message: 'Please select category!',
                                        }]}
                                    >

                                        <Input disabled placeholder="category" />

                                    </Form.Item>

                                    : ''}
                                {profile?.user_type === "1" && profile?.category_id?._id === '630f2e8252de247cce6c76a4' ?
                                    <Form.Item
                                        name="subcategory_id"
                                        rules={[{
                                            required: true,
                                            message: 'Please select category!',
                                        }]}
                                    >

                                        <Input disabled placeholder="sub category" />

                                    </Form.Item>

                                    : ''}
                            </Form>
                            <Row gutter={24} className="center">
                                <Col className="gutter-row text-center pt-5 pb-5" md={8} lg={8}>
                                    <a href='#change-password'>
                                        <Button type="primary" size='large' className='btn-primary-outline '>Change Password</Button>
                                    </a>
                                </Col>
                                <Col className="gutter-row text-center pt-5 pb-5" md={8} lg={8}>
                                    <a href='#profile-update'>
                                        <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Edit Profile</Button>
                                    </a>
                                </Col>
                                <Col className="gutter-row text-center pt-5 pb-5" md={8} lg={8}>
                                    <Button type="primary" onClick={()=>deleteConfirmModel()} htmlType="submit" size='large' className='pe-5 ps-5'>Delete Profile</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Modal
                        title={"Delte Confirm"}
                        visible={deleteConfirm}
                        onOk={onDeleteProfile}
                        onCancel={() => setDeleteConfirm(false)}
                        cancelText="Cancel"
                        okText="Delete"
                        className='filter-modal'
                        confirmLoading={confirmLoading}
                    >
                        <Spin spinning={loading}>
                            <div className='mb-4 pt-3 text-center'>
                                <p>Are you sure want to delete your profile?</p>
                            </div>
                        </Spin>
                    </Modal>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Profile