import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, List, Avatar, Button, Modal, Skeleton, Spin } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchNotifications, delAllNotification, updateNotification } from '../../actions/notifications';
import notFound from "../../assets/img/not-found.png";
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { fileUrl } from "../../constants/const";
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import moment from 'moment';
import { useToasts } from 'react-toast-notifications'
import firebase from 'firebase';

const { Content } = Layout;

function Notifications() {
    const dispatch = useDispatch();
    const [notifications, setNotification] = useState('');
    const [modalDelOpen, setModalDelOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));

    const firebaseConfig = {
        apiKey: "AIzaSyABkwzAPHGydveK2dx21sKWxH-hV5ci_lk",
        authDomain: "thecorner-stone.firebaseapp.com",
        projectId: "thecorner-stone",
        storageBucket: "thecorner-stone.appspot.com",
        messagingSenderId: "350307653009",
        appId: "1:350307653009:web:56843f8a22d660dbd2b630",
        measurementId: "G-4F5SFHTDX5"
    };
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
    var db = firebase.firestore();

    const getNotifications = async () => {
        setLoading(true)
        dispatch(fetchNotifications({
            successCb: (response) => {
                setNotification(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setNotification('')
                setLoading(false)
            }
        }))
    }

    function onDelete() {
        setConfirmLoading(true);
        dispatch(delAllNotification({
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setModalDelOpen(false)
                setConfirmLoading(false);
                getNotifications();
            },
            failureCb: (response) => {
                console.log(response)
                setConfirmLoading(false);
            }
        }))
    }

    function hideModal() {
        setModalDelOpen(false)
    }

    function openDelNotification(id) {
        setModalDelOpen(true)
    }

    function assignNotification(id, type, sentBy) {
        let data = {
            _id: id,
            response: type
        }
        setConfirmLoading(true);
        dispatch(updateNotification({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setConfirmLoading(false);
                getNotifications();
                if (type === "1") {
                    createChatRoom(sentBy);
                }
            },
            failureCb: (response) => {
                setConfirmLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    async function createChatRoom(sentBy) {
        let chatRoomdata = {};

        chatRoomdata = {
            _id: (sentBy + '_' + userDetail?._id)
                .split('_')
                .sort()
                .join('_'),
            members: [sentBy, userDetail?._id],
            lastmessage: '',
            UnreadCount: 0,
            UpdatedAt: '',
        };

        let response = await db
            .collection('Chatrooms')
            .doc(chatRoomdata._id)
            .set(chatRoomdata)
            .then(() => true)
            .catch(() => false);
            console.log(response)
    }

    useEffect(() => {
        getNotifications();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                       
                        minHeight: 680,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" xs={12} lg={20}>
                            <div>
                                <h5 className='color-theme semi-bold'>Notifications</h5>
                            </div>
                        </Col>

                        <Col className="gutter-row pb-2" xs={12} lg={4}>
                            {notifications.length > 0 ?
                                <div>
                                    <Button type="primary" htmlType="submit" size='large' onClick={() => openDelNotification()} className='pe-5 ps-5'>DELETE</Button>
                                </div>
                                : ""}
                        </Col>
                    </Row>
                    <Skeleton loading={loading} active avatar paragraph={{
                        rows: 4,
                    }}>
                        <Row gutter={24} >
                            {notifications.length > 0 && notifications ? notifications.map((element, index) => {
                                return (
                                    <Col className="gutter-row pb-2 mt-3 bg-gray pt-4" span={24}>
                                        <List
                                            itemLayout="horizontal"
                                        >
                                            <List.Item className='pt-0'>
                                                <List.Item.Meta
                                                    avatar={<Avatar
                                                        style={{ width: "50px", height: "50px" }}
                                                        src=
                                                        {element.sent_by?.profile_pic === '' ? defaultIcon : fileUrl + element.sent_by?.profile_pic}
                                                    />
                                                    }
                                                    title={<a href={"/#/event-detail/" + element.event_id?._id}>{element?.title}</a>}
                                                    description={<p>{decodeURI(element?.description)}</p>}
                                                />
                                                <Row gutter={24}>
                                                    <Col>
                                                        <small style={{ fontSize: "14px" }}>Last: {moment(element.createdAt).format(" Do MMM YYYY hh:mm A")}</small>
                                                    </Col>
                                                </Row>
                                            </List.Item>

                                        </List>
                                        {userDetail.user_type === "0" ?
                                            <Spin spinning={confirmLoading}>
                                                <Row gutter={24} className="pt-3 pb-3">
                                                    <Col className="gutter-row" xs={12} lg={2}>
                                                        <Button type="primary" htmlType="submit" onClick={() => assignNotification(element._id, '1', element?.sent_by?._id)} size='small' style={{ height: "35px" }} className='pe-3 ps-3'>Accept</Button>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} lg={2}>
                                                        <Button type="primary" size='small' onClick={() => assignNotification(element._id, '0', element?.sent_by?._id)} className='btn-primary-outline pe-3 ps-3' style={{ height: "35px" }}>Decline</Button>
                                                    </Col>
                                                </Row>
                                            </Spin>
                                            : ""}
                                    </Col>
                                )
                            }) :
                                <Col className='center text-center pt-5 mt-5' span={24}>
                                    <img src={notFound} style={{ width: "300px" }} />
                                    <h3 className='pt-5'>No notification found yet!</h3>
                                </Col>
                            }

                        </Row>
                    </Skeleton>
                    <Modal
                        title="Delete Notifications"
                        visible={modalDelOpen}
                        onOk={onDelete}
                        onCancel={hideModal}
                        cancelText="No"
                        okText="Yes"
                        className='filter-modal'
                        confirmLoading={confirmLoading}
                    >
                        <div className='mb-4 pt-3 text-center'>
                            <p>Are you sure you want to<br></br> delete all notifications?</p>
                        </div>
                    </Modal>

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Notifications