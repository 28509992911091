import React, { useEffect, useRef, useState } from 'react';
import { Layout, Row, Col, Card, Tabs, Modal, Spin, Button, Skeleton, Form, Input } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchMembership, fetchMyMembership, buyMembershipNow, cancleMySubcription, moreStoreSubmit ,getPaymentIntentDetails} from '../../actions/membership';
import { fetchSavedCards } from '../../actions/card';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import notFound from "../../assets/img/not-found.png";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { googleKey } from '../../constants/const';
import moment from 'moment';

const { Content } = Layout;
const { TextArea } = Input;

function Membership() {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [membership, setMembership] = useState("");
    const [myMembership, setMyMembership] = useState("");
    const [membershipId, setMembershipId] = useState("");
    const [membershipName, setMembershipName] = useState("");
    const [membershipPrice, setMembershipPrice] = useState("");
    const [modal2Open, setModal2Open] = useState(false);
    const [modal21pen, setModal21pen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [userType, setUserType] = useState(false);
    const [location, setLocation] = useState('');
    const [fields, setFields] = useState([]);
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    var payment_intent_id = ''

    const getMembership = async () => {
        dispatch(fetchMembership({
            successCb: (response) => {
                setMembership(response.data)
            },
            failureCb: (response) => {
                setMembership('')
            }
        }))
    }

    const fetchDetails = async () => {
        setLoading(true);
        dispatch(fetchSavedCards({
            successCb: (response) => {
                if (response.data.length == 0)
                    document.location.href = "#/card";

            },
            failureCb: (response) => {
                setLoading(false);
                document.location.href = "#/card";
            }
        }))
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const getMyMembership = async () => {
        setLoading(true);
        dispatch(fetchMyMembership({
            successCb: (response) => {
                setMyMembership(response)
                setLoading(false);
            },
            failureCb: (response) => {
                setLoading(false);
            }
        }))
    }

    const selectSubscription = (element) => {
        setModal2Open(true);
        setMembershipId(element.default_price);
        setMembershipName(element.name);
        setMembershipPrice(element.metadata?.price);
    }

    let popupWindow;
    let isOpened = false;

    function buyMembership() {
        let data = {
            "_id": membershipId,
        }
        setLoading(true);
        setConfirmLoading(true);
        dispatch(buyMembershipNow({
            data,
            successCb: (response) => {
                let redirectURI = response?.data?.redirect;               
                if (response?.data?.require_action) {
                    if (!isOpened) {
                        popupWindow = window.open(redirectURI, '_blank', 'width=400,height=400');
                        isOpened = true;
                        payment_intent_id = response?.data?.payment_intent_id;
                        console.log(response?.data?.payment_intent_id)
                        checkWindowClosed();
                    }
                }
                else {
                    addToast("Subscription has been purchased!", { appearance: 'success', autoDismiss: true });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
                setLoading(false);
                setConfirmLoading(false);
                setModal2Open(false);
            },
            failureCb: (response) => {
                setLoading(false);
                setConfirmLoading(false);
                setModal2Open(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function checkWindowClosed() {
        setLoading(false);
        if (popupWindow && popupWindow.closed) {
            isOpened = false; // Reset flag for next opening
            checkPaymentStatus()
            // Fire your custom event or callback here
        } else {
            setTimeout(checkWindowClosed, 1000);
        }
    }

    const checkPaymentStatus = async () => {
        setLoading(true);
        let data = {
            payment_intent_id
        }
        dispatch(getPaymentIntentDetails({
            data,
            successCb: (response) => {
                setLoading(false);
                addToast("Subscription has been purchased!", { appearance: 'success', autoDismiss: true });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function cancleMembership() {
        setModal21pen(true);
    }

    function cancleMembershipNow(cancel_type) {
        setLoading(true);
        setConfirmLoading(true);
        let data = {
            sub_id: myMembership.data?.subscription?.id,
        }
        if (cancel_type === 'immediately') {
            data.cancel_type = cancel_type
        }
        else {
            data.till_end = cancel_type
        }

        dispatch(cancleMySubcription({
            data,
            successCb: (response) => {
                setLoading(false);
                setConfirmLoading(false);
                setModal21pen(false);
                getMyMembership();
                addToast(response.message, { appearance: 'success', autoDismiss: true });
            },
            failureCb: (response) => {
                setLoading(false);
                setConfirmLoading(false);
                setModal21pen(false);
                addToast(response.message, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            setLocation(place.formatted_address);
        }
    }

    function onFinish(value) {
        let data = {
            full_name: value.full_name,
            business_name: value.business_name,
            location: location,
            description: value.description,
            email: value.email,
            phone: value.phone,
        }

        setLoading(true);
        dispatch(moreStoreSubmit({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setFields([
                    {
                        name: ['full_name'],
                        value: '',
                    },
                    {
                        name: ['business_name'],
                        value: '',
                    },
                    {
                        name: ['location'],
                        value: '',
                    },
                    {
                        name: ['description'],
                        value: '',
                    },
                    {
                        name: ['email'],
                        value: '',
                    },
                    {
                        name: ['phone'],
                        value: '',
                    },
                ])
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    const onChangeTab = (key) => {
        if (key === "0")
            getMyMembership();
    };

    useEffect(() => {
        setUserType(userDetail.user_type === "0" ? "Producer" : userDetail.user_type === "1" ? "Merchant" : "Vendor")
        fetchDetails();
        getMembership();
        getMyMembership();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5 subs-layout"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    <Row gutter={24} className="pe-5 ps-5 mp0">
                        <Col className="gutter-row pb-3" span={21}>
                            <h5 className='color-theme'>Membership : Cost Structure</h5>
                        </Col>
                    </Row>
                    {/* ////plan 1 */}
                    <Tabs defaultActiveKey="0" className='pe-5 ps-5 mp0 event-tab' onChange={onChangeTab}>
                        <Tabs.TabPane tab="My Membership" key="0" >
                            <Skeleton loading={loading} active avatar>
                                <Row gutter={24} className="pe-5 ps-5 mp0 subscription center">
                                    <Col className="gutter-row pb-5 pt-5 mt-5 pt0" lg={24} xs={24}>
                                        <div className="site-card-border-less-wrapper text-center">
                                            {myMembership.status ?
                                                <Card title="Current Plan" bordered={false} className="pb-3">
                                                    <h5>Product Name: {myMembership.data?.product?.name}</h5>
                                                    <h5>Price: ${myMembership.data?.product?.metadata?.price}</h5>
                                                    <h5>Membership End: <span style={{ color: 'red' }}>{moment(myMembership.data?.subscription?.current_period_end * 1000).format("Do MMM YYYY h:mm a")}</span></h5>
                                                    {myMembership.data?.subscription?.status === 'canceled' ?
                                                        <div className='pt-5'>
                                                            <p className='color-theme'>Membership cancelled</p>
                                                        </div>
                                                        :
                                                        !myMembership.data?.subscription?.cancel_at_period_end ?
                                                            <Spin spinning={loading}>
                                                                <Button onClick={() => cancleMembership()} type="primary" size='large' className='pe-5 ps-5 mt-5'>Cancel Membership</Button>
                                                            </Spin>
                                                            : ""
                                                    }
                                                </Card>
                                                :
                                                <Row className='center pt-5 mt-5'>
                                                    <Col>
                                                        <img src={notFound} style={{ width: "300px" }} />
                                                        <h5 className='pt-5' >No membership found!</h5>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Skeleton>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Membership Plans" className='subtab' key="2" >
                            {userType === 'Merchant' ?
                                <Tabs defaultActiveKey="1" className='mp0'>
                                    <Tabs.TabPane tab="1 Store" className="pb-5" key="1" >
                                        <Row gutter={24} className='center pt-5 mt-3'>
                                            {membership ?
                                                membership.length > 0 &&
                                                membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '1' && j.metadata["sort_order"])
                                                    .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                    .map((element, index) => {
                                                        if (element.active) {
                                                            return (
                                                                <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                    <div className="site-card-border-less-wrapper text-center">
                                                                        <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                            <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                            <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                            <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                        </Card>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    }) :
                                                <Col>
                                                    <img src={notFound} style={{ width: "300px" }} />
                                                    <h5 className='pt-5' >No membership plans found!</h5>
                                                </Col>
                                            }
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="2 Store" key="2" >
                                        <Row gutter={24} className='center pt-5 mt-3'>
                                            {membership ?
                                                membership.length > 0 &&
                                                membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '2' && j.metadata["sort_order"])
                                                    .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                    .map((element, index) => {
                                                        if (element.active) {
                                                            return (
                                                                <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                    <div className="site-card-border-less-wrapper text-center">
                                                                        <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                            <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                            <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                            <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                        </Card>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    }) :
                                                <Col>
                                                    <img src={notFound} style={{ width: "300px" }} />
                                                    <h5 className='pt-5' >No membership plans found!</h5>
                                                </Col>
                                            }
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="3 Store" key="3" >
                                        <Row gutter={24} className='center pt-5 mt-3'>
                                            {membership ?
                                                membership.length > 0 &&
                                                membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '3' && j.metadata["sort_order"])
                                                    .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                    .map((element, index) => {
                                                        if (element.active) {
                                                            return (
                                                                <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                    <div className="site-card-border-less-wrapper text-center">
                                                                        <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                            <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                            <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                            <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                        </Card>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }
                                                    }) :
                                                <Col>
                                                    <img src={notFound} style={{ width: "300px" }} />
                                                    <h5 className='pt-5' >No membership plans found!</h5>
                                                </Col>
                                            }
                                        </Row>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="More" key="4" >
                                        <Row gutter={24} className='center pt-5 mt-3'>
                                            <Col className="gutter-row pb-2" lg={16} md={24} xs={24}>
                                                <h5 className='text-center'><b>Please provide us with the following information so that we may better assist you.</b></h5>
                                                <Form
                                                    fields={fields}
                                                    name="send"
                                                    className="pt-3 pb-5 event-create"
                                                    initialValues={{
                                                        remember: true,
                                                    }}
                                                    getValueFromEvent={normFile}
                                                    onFinish={onFinish}
                                                    valuepropname="fileList"
                                                >
                                                    <Form.Item
                                                        name="full_name"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your full name!',
                                                        }]}
                                                    >
                                                        <Input placeholder="Full Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="business_name"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your business name!',
                                                        }]}
                                                    >
                                                        <Input placeholder="Business Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="location"
                                                    >
                                                        <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                                            <StandaloneSearchBox
                                                                onLoad={ref => inputRef.current = ref}
                                                                onPlacesChanged={handlePlaceChanged}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="ant-input"
                                                                    placeholder="Location"
                                                                />
                                                            </StandaloneSearchBox>
                                                        </LoadScript>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="phone"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your phone!',
                                                        }]}
                                                    >
                                                        <Input placeholder="phone" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="email"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your email!',
                                                        }]}
                                                    >
                                                        <Input placeholder="Email" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="description"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Please input your description!',
                                                        }]}
                                                    >
                                                        <TextArea rows={4} placeholder="Description" />
                                                    </Form.Item>
                                                    <Form.Item className='pe-5 ps-5 mt-5'>
                                                        <Spin spinning={loading}>
                                                            <Col className="gutter-row text-center" lg={24}>
                                                                <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Send</Button>
                                                            </Col>
                                                        </Spin>
                                                    </Form.Item>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Tabs.TabPane>
                                </Tabs>
                                :
                                userType === 'Vendor' ?
                                    <Row gutter={24} className='center pt-5 mt-3'>
                                        {membership ?
                                            membership.length > 0 &&
                                            membership.filter(j => j.metadata[userType] && j.active && j.metadata["sort_order"])
                                                .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                .map((element, index) => (
                                                    <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24} key={index}>
                                                        <div className="site-card-border-less-wrapper text-center">
                                                            <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : `% ${element.metadata?.saving} Savings`}</h5>
                                                                <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                )) :
                                            <Col>
                                                <img src={notFound} style={{ width: "300px" }} />
                                                <h5 className='pt-5' >No membership plans found!</h5>
                                            </Col>
                                        }
                                    </Row>
                                    :
                                    <Tabs defaultActiveKey="1" className='mp0'>
                                        <Tabs.TabPane tab="3 Slots" className="pb-5" key="1" >
                                            <Row gutter={24} className='center pt-5 mt-3'>
                                                {membership ?
                                                    membership.length > 0 &&
                                                    membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '3' && j.metadata["sort_order"])
                                                        .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                        .map((element, index) => {
                                                            if (element.active) {
                                                                return (
                                                                    <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                        <div className="site-card-border-less-wrapper text-center">
                                                                            <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                                <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                                <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                                <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                            </Card>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                        }) :
                                                    <Col>
                                                        <img src={notFound} style={{ width: "300px" }} />
                                                        <h5 className='pt-5' >No membership plans found!</h5>
                                                    </Col>
                                                }
                                            </Row>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="6 Slots" key="2" >
                                            <Row gutter={24} className='center pt-5 mt-3'>
                                                {membership ?
                                                    membership.length > 0 &&
                                                    membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '6' && j.metadata["sort_order"])
                                                        .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                        .map((element, index) => {
                                                            if (element.active) {
                                                                return (
                                                                    <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                        <div className="site-card-border-less-wrapper text-center">
                                                                            <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                                <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                                <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                                <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                            </Card>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                        }) :
                                                    <Col>
                                                        <img src={notFound} style={{ width: "300px" }} />
                                                        <h5 className='pt-5' >No membership plans found!</h5>
                                                    </Col>
                                                }
                                            </Row>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="12 Slots" key="3" >
                                            <Row gutter={24} className='center pt-5 mt-3'>
                                                {membership ?
                                                    membership.length > 0 &&
                                                    membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '12' && j.metadata["sort_order"])
                                                        .sort((a, b) => parseFloat(a.metadata.sort_order) - parseFloat(b.metadata.sort_order))
                                                        .map((element, index) => {
                                                            if (element.active) {
                                                                return (
                                                                    <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                        <div className="site-card-border-less-wrapper text-center">
                                                                            <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                                <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                                <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                                <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                            </Card>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                        }) :
                                                    <Col>
                                                        <img src={notFound} style={{ width: "300px" }} />
                                                        <h5 className='pt-5' >No membership plans found!</h5>
                                                    </Col>
                                                }
                                            </Row>
                                        </Tabs.TabPane>

                                        <Tabs.TabPane tab="Unlimited" key="4" >
                                            <Row gutter={24} className='center pt-5 mt-3'>
                                                {membership ?
                                                    membership.length > 0 &&
                                                    membership.filter(j => j.metadata[userType] && j.metadata["stores"] === '100000000')
                                                        .sort((a, b) => parseFloat(a.metadata.price) - parseFloat(b.metadata.price))
                                                        .map((element, index) => {
                                                            if (element.active) {
                                                                return (
                                                                    <Col className="gutter-row pb-5 " lg={8} md={12} sm={12} xs={24}>
                                                                        <div className="site-card-border-less-wrapper text-center">
                                                                            <Card title={element.name} bordered={false} style={{ width: "auto" }} className="pb-3">
                                                                                <h5>{!element.metadata?.saving ? <span>&nbsp;</span> : "% " + element.metadata?.saving + " Savings"}</h5>
                                                                                <h4 className='pt-3'><b>${element.metadata?.price}</b></h4>
                                                                                <Button type="primary" size='large' onClick={() => selectSubscription(element)} className='pe-5 ps-5 mt-3'>Buy Now</Button>
                                                                            </Card>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }
                                                        }) :
                                                    <Col>
                                                        <img src={notFound} style={{ width: "300px" }} />
                                                        <h5 className='pt-5' >No membership plans found!</h5>
                                                    </Col>
                                                }
                                            </Row>
                                        </Tabs.TabPane>
                                    </Tabs>
                            }

                        </Tabs.TabPane>
                    </Tabs>
                    {/* modal buy */}
                    <Modal
                        title={membershipName}
                        visible={modal2Open}
                        onOk={buyMembership}
                        onCancel={() => setModal2Open(false)}
                        cancelText="Cancel"
                        okText="Buy"
                        className='filter-modal'
                        confirmLoading={confirmLoading}
                    >
                        <Spin spinning={loading}>
                            <div className='mb-4 pt-3 text-center'>
                                <h5 className='mb-4'><strong>Price: <span className="color-theme">${membershipPrice}</span></strong></h5>
                                <p>Are you sure want to purchase this membership?</p>
                            </div>
                        </Spin>
                    </Modal>
                    {/* modal */}

                    {/* modal cancle membership */}
                    <Modal
                        title={myMembership.data?.product?.name}
                        visible={modal21pen}
                        onOk={cancleMembershipNow}
                        onCancel={() => setModal21pen(false)}
                        okText="OK"
                        className='filter-modal'
                        confirmLoading={confirmLoading}
                        footer={[
                            <Button key="cancelNow" type="primary" onClick={() => cancleMembershipNow('immediately')}>
                                Cancel Immediate
                            </Button>,
                            <Button key="cancelNow" type="primary" onClick={() => cancleMembershipNow('till_end')}>
                                Cancel on Subscription end
                            </Button>
                        ]}
                    >
                        <Spin spinning={loading}>
                            <div className='mb-4 pt-3 text-center'>
                                <h5 className='mb-4'><strong>Product: <span className="color-theme">{myMembership.data?.product?.name}</span></strong></h5>
                                <p>Are you sure want to cancel this membership?</p>
                            </div>
                        </Spin>
                    </Modal>

                    {/* /////modal */}

                </Content>
                <Footer_ />
            </Layout>
        </Layout >
    )
}

export default Membership