import { baseUrl, uploadUrl } from "../constants/const";
import { apiCall } from "../api";

export const getHomeData = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        url: `${uploadUrl}api/v1/app_data`
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const sendContact = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}contact_us`,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}
