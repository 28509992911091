const baseUrl = process.env.REACT_APP_MODE == "PROD" ? 'https://api.4cornerconcepts.com/api/v1/' : 'https://apidev.4cornerconcepts.com/api/v1/';
const fileUrl = process.env.REACT_APP_MODE == "PROD" ? 'https://api.4cornerconcepts.com/files/' : 'https://apidev.4cornerconcepts.com/files/';
const uploadUrl = process.env.REACT_APP_MODE == "PROD" ? 'https://api.4cornerconcepts.com/' : 'https://apidev.4cornerconcepts.com/';
const googleKey = 'AIzaSyAbcVfeiTr0sdz1M8eCYzNeUKqyU4XDMIc';
const stripeKey = process.env.REACT_APP_MODE == "PROD" ? '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' : 'pk_test_51LKZ4EHb1SUNrIKhRlVWXdqUpZRxspvlYZrhGBbHD9ic1Z7DBAgwq4mCtQeUhvrZK9syyLQvP5aCxJNWrppAQv8100Xl4Tl8R7';

const getCurrentTimeStamp = () => {
    let currentTime = new Date().getTime();
    let tzOffset = new Date().getTimezoneOffset();
    return Math.round(new Date(currentTime + tzOffset).getTime() / 1000);
}

export { baseUrl, googleKey, getCurrentTimeStamp, fileUrl, uploadUrl, stripeKey };

