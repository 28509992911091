import React from 'react'
import { Layout } from 'antd';
const { Footer } = Layout;

function Footer_() {

    return (
        <>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >
                ©2023 Created by The Cornerstone
            </Footer>
        </>
    )
}

export default Footer_