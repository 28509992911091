/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
////
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from 'react-toast-notifications';
import Home from "../src/screens/Home";
////auth screens
import Login from "./screens/auth/login";
import Register from "./screens/auth/register";
import Forgot from "./screens/auth/forgot";
import Verification from "./screens/auth/verification";
import ResetPassword from "./screens/auth/reset-password";
////events
import Events from "./screens/event";
import EventsDetails from "./screens/event/details";
import EventsCreate from "./screens/event/create";
import EventsUpdate from "./screens/event/update";
import EventHistory from "./screens/event/history";
import Merchants from "./screens/event/merchants";
import Vendors from "./screens/event/vendors";
////profile
import Profile from "./screens/profile";
import ProfileUpdate from "./screens/profile/update";
import ProfileChangePassword from "./screens/profile/change-password";
import OtherProfile from "./screens/profile/other-profile";
import AddImages from "./screens/profile/add-images";
////membership
import Membership from "./screens/membership";
import MembershipApp from "./screens/membership/app";
import Card from "./screens/card";
import CardApp from "./screens/card/app";
import AccountDetails from "./screens/card/account-details";
////favorite
import Favorite from "./screens/favorites";
////notification
import Notifications from "./screens/notifications";
////other store/offers
import Stores from "./screens/stores";
import Offers from "./screens/offers";
import AddOffer from "./screens/offers/add-offer";
import AddCustomOffer from "./screens/offers/add-custom-offer";
import EditOffer from "./screens/offers/edit-offer";
import Discounts from "./screens/offers/discounts";
////settings
import ContactUs from "./screens/settings/contact-us";
import Terms from "./screens/settings/terms";
import Privacy from "./screens/settings/privacy";
import Faq from "./screens/settings/faq";
////chat
import Chat from "./screens/chat";

import { Provider } from 'react-redux';
import { store } from '../src/store'
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import 'antd/dist/antd.css';

ReactDOM.render(

  <Provider store={store}>
    <ToastProvider styles={{
      container: (provided) => ({ ...provided, zIndex: 999999, top: 50 })
    }}>
      <HashRouter>
        <Switch>
          <Route path="/home" component={Home} />

          {/* ///auth */}
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/verification" component={Verification} />
          <Route path="/reset-password" component={ResetPassword} />

          {/* ///event */}
          <Route path="/events" component={Events} />
          <Route path="/event-detail" component={EventsDetails} />
          <Route path="/event-create" component={EventsCreate} />
          <Route path="/event-update" component={EventsUpdate} />
          <Route path="/event-history" component={EventHistory} />
          <Route path="/merchants" component={Merchants} />
          <Route path="/vendors" component={Vendors} />

          {/* ///profile */}
          <Route path="/profile" component={Profile} />
          <Route path="/profile-update" component={ProfileUpdate} />
          <Route path="/change-password" component={ProfileChangePassword} />
          <Route path="/other-profile" component={OtherProfile} />
          <Route path="/add-images" component={AddImages} />

          {/* ///membership */}
          <Route path="/membership" component={Membership} />
          <Route path="/app" component={MembershipApp} />
          <Route path="/card" component={Card} />
          <Route path="/card-app" component={CardApp} />
          <Route path="/account-details" component={AccountDetails} />
          
          {/* ///store */}
          <Route path="/stores" component={Stores} />
          <Route path="/offers" component={Offers} />
          <Route path="/add-offer" component={AddOffer} />
          <Route path="/edit-offer" component={EditOffer} />
          <Route path="/add-custom-offer" component={AddCustomOffer} />
          <Route path="/discounts" component={Discounts} />

          {/* ///favorite */}
          <Route path="/favorite" component={Favorite} />

           {/* ///notifications */}
          <Route path="/notifications" component={Notifications} />
          
           {/* ///settings */}
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/faq" component={Faq} />

          <Route path="/chat" component={Chat} />

          <Redirect from="/" to="/home" />
        </Switch>
      </HashRouter>
    </ToastProvider>
  </Provider>
  ,
  document.getElementById("root")
);
