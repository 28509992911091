import React, { useState, useEffect, useRef } from 'react';
import { Layout, Row, Col, Form, Modal, Skeleton, Button,Select} from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchStores, addNewStore, delStore } from '../../actions/stores';
import notFound from "../../assets/img/not-found.png";
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { googleKey } from '../../constants/const';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

const { Content } = Layout;
const { Option } = Select;

function Stores() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [stores, setStore] = useState('');
    const [modalDelOpen, setModalDelOpen] = useState(false);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [storeValue, setStoreValue] = useState(' ');
    const [storeId, setStoreId] = useState(' ');
    const [storeCategory, setStoreCategory] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [location, setLocation] = useState('');
    const [locationName, setLocationName] = useState('');
    const inputRef = useRef();

    const getStores = async () => {
        setLoading(true)
        dispatch(fetchStores({
            successCb: (response) => {
                setStore(response.data)
                setLoading(false)
            },
            failureCb: (response) => {
                setStore('')
                setLoading(false)
            }
        }))
    }

    const onAddStore = async () => {
        setConfirmLoading(true);
        if (storeValue === "" || storeId === "") {
            addToast("All fields are required!", { appearance: 'error', autoDismiss: true });
            return false;
        }
        let data = {
            name: storeValue,
            u_id: storeId,
            category:storeCategory,
            address:location            
        }
        dispatch(addNewStore({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setModalAddOpen(false)
                setConfirmLoading(false);
                setStoreValue(' ')
                setStoreId(' ')
                getStores();
            },
            failureCb: (response) => {
                setModalAddOpen(false)
                addToast(response, { appearance: 'error', autoDismiss: true });
                setConfirmLoading(false);
                console.log(response)
            }
        }))
    }

    const handlePlaceChanged = () => {

        const [place] = inputRef.current.getPlaces();
        if (place) {
            let locate = {
                 "lat": place.geometry.location.lat(), "long": place.geometry.location.lng(), "address": place.formatted_address
            }
            setLocation(locate);
            setLocationName(place.formatted_address)
        }
    }


    function onDelete() {
        setConfirmLoading(true);
        let data = {
            _id: storeId,
            status: 2
        }
        dispatch(delStore({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                setModalDelOpen(false)
                setConfirmLoading(false);
                getStores();
            },
            failureCb: (response) => {
                console.log(response)
                setConfirmLoading(false);
            }
        }))
    }

    function hideModal() {
        setModalAddOpen(false)
        setModalDelOpen(false)
    }

    function openStore() {
        setModalAddOpen(true)
    }

    function openDelStore(id) {
        setModalDelOpen(true)
        setStoreId(id)
    }

    const selectCategory = (id) => {
        setStoreCategory(id)
    };


    useEffect(() => {
        getStores();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    {/* /////profile detail section */}
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="">
                            <Col className="gutter-row pb-2" span={24}>
                                <div>
                                    <h5 className='color-theme semi-bold'>Stores</h5>
                                </div>
                            </Col>
                            <Col className="gutter-row pb-2" lg={20} xs={24}>
                                {stores.length > 0 && stores ? stores.map((element, index) => {
                                    return (
                                        <Row className="gutter-row p-3 pb-1 bg-gray mt-3" span={24}>
                                            <Col className="gutter-row" lg={12} xs={10}>
                                                <p>Store Name:</p>
                                                <p>Store Id:</p>
                                                <p>Category:</p>
                                                <p>Store Id:</p>
                                            </Col>
                                            <Col className="gutter-row text-right" lg={10} xs={10}>
                                                <h6>{element?.name}</h6>
                                                <h6>{element?.u_id}</h6>
                                                <h6>{element?.category}</h6>
                                                <h6>{element?.address?.address}</h6>
                                            </Col>
                                            <Col className="gutter-row text-right" lg={2} xs={4}>
                                                <DeleteFilled style={{ fontSize: "30px", color: "#424242" }} onClick={() => openDelStore(element._id)} />
                                            </Col>
                                        </Row>
                                    )
                                }) :
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No stores found yet!</h3>
                                        </Col>
                                    </Row>
                                }

                                <Col span={24} className="mt-5 text-center">
                                    <Button type="primary" onClick={() => openStore()} size='large' className='col-lg-3 col-xs-12' style={{ height: "50px" }}><PlusOutlined /> Add Store</Button>
                                </Col>

                                <Modal
                                    title="Delete Store"
                                    visible={modalDelOpen}
                                    onOk={onDelete}
                                    onCancel={hideModal}
                                    cancelText="No"
                                    okText="Yes"
                                    className='filter-modal'
                                    confirmLoading={confirmLoading}
                                >
                                    <div className='mb-4 pt-3 text-center'>
                                        <p>Are you sure you want to<br></br> delete this store?</p>
                                    </div>
                                </Modal>

                                {/* ////add store */}

                                <Modal
                                    title="Add Store"
                                    visible={modalAddOpen}
                                    onOk={onAddStore}
                                    onCancel={hideModal}
                                    cancelText="Cancel"
                                    okText="Add"
                                    className='filter-modal'
                                    confirmLoading={confirmLoading}
                                >
                                    <Form
                                        name="store"
                                        className="pt-3 pb-5 change-password-form"
                                        valuepropname="fileList"
                                    >
                                        <Form.Item>
                                            <input
                                                onChange={(e) => {
                                                    setStoreValue(e.target.value);
                                                }}
                                                className="ant-input"
                                                placeholder="Enter store name"
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <input
                                                onChange={(e) => {
                                                    setStoreId(e.target.value);
                                                }}
                                                className="ant-input"
                                                placeholder="Enter store id" />
                                        </Form.Item>
                                        <Form.Item
                                            name="location"
                                        >
                                            <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <input
                                                        type="text"
                                                        className="ant-input"
                                                        placeholder="Location"
                                                    />
                                                </StandaloneSearchBox>
                                            </LoadScript>
                                        </Form.Item>
                                        <Form.Item
                                            name="tag_id"
                                            rules={[{
                                                required: true,
                                                message: 'Please select category!',
                                            }]}
                                        >
                                            <Select
                                                defaultValue="Select Category"
                                                className='col-12'
                                                style={{
                                                    background: "#F6F6F6",
                                                }}
                                                onChange={selectCategory}
                                            >
                                                <Option value="">Select Category</Option>
                                                <Option value="restaurant">Restaurant</Option>
                                                <Option value="gas">Gas</Option>
                                                <Option value="lodging">Lodging</Option>
                                            </Select>
                                        </Form.Item>

                                    </Form>
                                </Modal>
                            </Col>
                        </Row>
                    </Skeleton>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Stores