import { baseUrl } from "../constants/const";
import { apiCall } from "../api";

export const fetchOffers = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}coupon?mine=1`,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchDiscounts = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}coupon?user_id=`+data._id,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getOfferCategories = ({
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}tags`,        
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const getOfferSubCategories = ({
    id,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}subtag?_id=`+id,        
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const delOffer = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}coupon/`+data._id,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const createOffer = ({
    formData,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'multipart/form-data' },
        url: `${baseUrl}coupon`,
        "data": formData
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const editOffer = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'patch',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}coupon/`+data._id,
        data
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}

export const fetchPerticularOffers = ({
    data,
    successCb,
    failureCb
}) => async dispatch => {
    let config = {
        method: 'get',
        headers: { Authorization: 'Bearer '+sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
        url: `${baseUrl}coupon?_id=`+data.id,
    }
    try {
        const res = await apiCall(config, dispatch);      
        successCb(res.data)

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error.data
            let message = response && response.data && response.data.errors ? response.data.errors[0] : response.message
            failureCb(message)
        }
    }
}
