import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    EnvironmentFilled,
    ClockCircleFilled,
    CalendarFilled,
    TagOutlined
} from '@ant-design/icons';
import { Layout, Row, Col, Button,Form, Card, Avatar, Modal, Spin } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchEventsDetails, deleteEvents, markAsFavorite, joinEventData, createIntent } from '../../actions/event';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fileUrl, stripeKey } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import bookmarkun from "../../assets/img/icons/bookmarkun.png";
import bookmark from "../../assets/img/icons/bookmark.png";
import { useToasts } from 'react-toast-notifications';
import { googleKey } from '../../constants/const';
import GoogleMapReact from 'google-map-react';
import { PayNow, loadStripe } from 'react-stripe-js';
import 'react-stripe-js/dist/style.css';
const { Content } = Layout;
const { Meta } = Card;

function EventsDetails() {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState(0);
    const [merchantList, setMerchantList] = useState(0);
    const [vendorList, setVendorList] = useState(0);
    const [modal2Open, setModal2Open] = useState(false);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const location = useLocation();
    const pathID = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    const eventType = useSelector(state => state?.event?.type);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [descriptionValue, setDescriptionValue] = useState(' ');

    if (!eventType)
        document.location.href = "#events"

    const eventsDetails = async () => {
        let data = {
            _id: pathID,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchEventsDetails({
            data,
            successCb: (response) => {
                setEvents(response.data)
                setMerchantList(response.data.merchant_joined);
                setVendorList(response.data.vendors_joined);
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }

    function joinedMerchants() {
        return (
            <>
                {merchantList.length > 0 && merchantList ? merchantList.map((element, index) => {
                    return (
                        <Avatar src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                    )
                }) : ''
                }
            </>
        )
    }

    function joinedVendor() {
        return (
            <>
                {vendorList.length > 0 && vendorList ? vendorList.map((element, index) => {
                    return (
                        <Avatar src={element.profile_pic === '' ? defaultIcon : fileUrl + element.profile_pic} />
                    )
                }) : ''
                }
            </>
        )
    }

    const onDelete = () => {
        let data = {
            event_id: events._id
        }
        dispatch(deleteEvents({
            data,
            successCb: (response) => {
                setModal2Open(false);
                document.location.href = "#events"
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    };

    const hideModal = () => {
        setModal2Open(false);
        setModalAddOpen(false);
    }

    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: Number(events.location?.cordinates?.lat), lng: Number(events.location?.cordinates?.long) },
            map,
            title: events.location?.name
        });
        return marker;
    };

    function mapRender() {
        if (events?.location) {
            return (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleKey }}
                    defaultCenter={{ lat: Number(events.location.cordinates.lat), lng: Number(events.location.cordinates.long) }}
                    defaultZoom={15}
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            )
        }
    }

    function markFavorite(id) {
        let data = {
            event_id: id,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(markAsFavorite({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                eventsDetails();
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    function joinEvent() {
        setModalAddOpen(true)
      
    }

    function onAddDescription() {
        setConfirmLoading(true)       
        let data = {
            description: descriptionValue,
            event_id: events._id,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }        
        dispatch(joinEventData({
            data,
            successCb: (response) => {
                setModalAddOpen(false)
                addToast("Thanks for your interest in this event. Your request has been sent to Producer. We will update you soon.", { appearance: 'success', autoDismiss: true });
                setTimeout(() => {
                    document.location.href = "#/events"
                }, 1500);
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'error', autoDismiss: true });
                setConfirmLoading(false)
            }
        }))
    }

    const [clientSecret, setClientSecret] = useState("");
    const stripe = loadStripe(stripeKey)
    const createPaymentIntent = () => {
        setLoading(true)
        if (!clientSecret) {
            let data = {
                _id: pathID,
                user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
            }
            // Create PaymentIntent as soon as the page loads
            dispatch(createIntent({
                data,
                successCb: async (response) => {
                    setClientSecret(response.data.payment_intents)
                    setLoading(false)
                },
                failureCb: (response) => {
                    //////
                    addToast(response, { appearance: 'error', autoDismiss: true });
                    setLoading(false)
                }
            }))
        }
    }

    function gotoVendors(id) {
        document.location.href = '#/vendors/' + id
    }

    function gotoMerchants(id) {
        document.location.href = '#/merchants/' + id
    }

    function eventWebsite(link) {
        console.log(link)
        if (link !== 'https:') {
            window.location = 'https://' + link;
        }
        else {
            window.location = link;
        }
        // document.location.href = '#/merchants/'+id 
    }

    useEffect(() => {
        eventsDetails();
        setConfirmLoading(false)
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////user detail and creat section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>Event</div>
                        </Col>
                    </Row>

                    {/* /////event list section */}

                    <Row gutter={24} className="pt-1 pb-5">
                        <Col className="gutter-row" span={24}>
                            <Row gutter={24} className="event-price_top">
                                <Col className="gutter-row text-center" md={4} xs={6} lg={4}>
                                    {events.event_type == 1 ?
                                        <div className='price-type_top'>
                                            <p className='text-white p-2'>${events.amount}</p>
                                        </div>
                                        : ''
                                    }
                                </Col>
                                <Col className="gutter-row event-avtar " md={20} xs={18} lg={20}>
                                    <Col className="gutter-row event-avtar" onClick={() => markFavorite(events._id)} xs={20} md={22} lg={23}>
                                        {events.is_saved ?
                                            <img src={bookmark} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                            :
                                            <img src={bookmarkun} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                        }
                                    </Col>
                                </Col>
                            </Row>

                            <Card
                                hoverable
                                cover={<img alt={events.event_name} className='card-cover' style={{ background: 'url(' + fileUrl + events.event_image + '' }} />}
                            >
                                <Row gutter={24} className="event-price">
                                    <Col className="gutter-row text-center" md={6} sm={6} xs={12} lg={4}>
                                        {events.event_type == 1 ?
                                            <div className='price-type'>
                                                <p className='text-white p-1'>Paid</p>
                                            </div>
                                            :
                                            <div className='price-type-free '>
                                                <p className='text-white p-1'>Free</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col className="gutter-row event-avtar" md={18} xs={12} lg={20}>
                                        <Avatar.Group
                                            maxCount={2}
                                            style={{ float: "right" }}>
                                            {joinedMerchants(events.merchant_joined)}
                                        </Avatar.Group>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col className="gutter-row" span={22}>
                                        <Meta title={events.event_name} />
                                    </Col>
                                </Row>
                                <Row gutter={24} className="event-locate">
                                    <Col className="gutter-row pt-2" span={24}>
                                        <Row gutter={1} className="event-locate">
                                            <Col className="gutter-row pt-2">
                                                <CalendarFilled />
                                            </Col>
                                            <Col className="gutter-row pt-2 ps-3" md={22} xs={21} lg={20}>
                                                <h6 className='semi-bold'>Event Starts</h6>
                                                <span className=''>{moment(events.event_start_time).format(" Do MMM YYYY h:mm a")}</span>
                                                <h6 className='pt-3 semi-bold'>Event End</h6>
                                                <span className=''>{moment(events.event_end_time).format(" Do MMM YYYY h:mm a")}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row pt-2" span={24}>
                                        <Row gutter={0} className="event-locate">
                                            <Col className="gutter-row pt-2">
                                                <EnvironmentFilled />
                                            </Col>
                                            <Col className="gutter-row pt-2 ps-3" md={22} xs={21} lg={20}>
                                                <h6 className='semi-bold'>Location</h6>
                                                <span>{events.location?.name}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row d-flex pt-3 pb-2" span={24}>
                                        <div style={{ height: '30vh', width: '100%' }}>
                                            {mapRender()}
                                        </div>
                                    </Col>
                                    <Col className="gutter-row pt-3" span={24}>
                                        <h5><strong>Event Type</strong></h5>
                                        <h6 className='pt-2'>{events.event_type_id?.name}</h6>
                                    </Col>
                                    <Col className="gutter-row pt-3 pb-2" span={24}>
                                        <h5><strong>Description</strong></h5>
                                        <h6 className='pt-2'>{events.description}</h6>
                                    </Col>
                                    {events.event_blueprint != "" ?
                                        <Col className="gutter-row pt-3 pb-3 bg-gray mb-3" span={24}>
                                            <h5><strong>Blueprint</strong></h5>
                                            <img style={{ width: "50px", float: "right", position: "absolute", right: "15px", top: "15px" }} src={fileUrl + events.event_blueprint} />
                                        </Col>
                                        : ""}
                                    <Col className="gutter-row pt-3 pb-3 bg-gray mt-3" span={24}>
                                        <Row gutter={24}>
                                            <Col className="gutter-row" span={8}>
                                                <h5><strong>Created By</strong></h5>
                                                <h6 className='pt-2'>{events.user_id?.full_name}</h6>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <h5><strong>Event Website</strong></h5>
                                                <h6 className='pt-2'>{events?.link === "" ? "--" : <span onClick={() => eventWebsite(events?.link)}>{events?.link}</span>}</h6>
                                            </Col>
                                            <Col className="gutter-row" span={8}>
                                                <a href={'#/other-profile/' + events.user_id?._id}>
                                                    <Avatar.Group
                                                        maxCount={2}
                                                        style={{ float: "right" }}>
                                                        <Avatar src={events.user_id?.profile_pic === '' ? defaultIcon : fileUrl + events.user_id?.profile_pic} />
                                                    </Avatar.Group>
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row pt-3 pb-3 bg-gray mt-3" span={24}>
                                        <Row gutter={24} onClick={() => gotoVendors(events._id)}>
                                            <Col className="gutter-row" span={12}>
                                                <h5><strong>Vendor</strong></h5>
                                                <h6 className='pt-2'>{vendorList.length}  Joined</h6>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Avatar.Group
                                                    maxCount={2}
                                                    style={{ float: "right" }}>
                                                    {joinedVendor(events.vendors_joined)}
                                                </Avatar.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row pt-3 pb-3 bg-gray mt-3" span={24}>
                                        <Row gutter={24} onClick={() => gotoMerchants(events._id)}>
                                            <Col className="gutter-row" span={12}>
                                                <h5><strong>Merchants</strong></h5>
                                                <h6 className='pt-2'>{merchantList.length}  Joined</h6>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Avatar.Group
                                                    maxCount={2}
                                                    style={{ float: "right" }}>
                                                    {joinedMerchants(events.merchant_joined)}
                                                </Avatar.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {userDetail.user_type === "0" ?
                                    <Row gutter={24} className="center">
                                        <Col className="gutter-row text-center pt-5 pb-0" sm={12} lg={12}>
                                            <Button onClick={() => setModal2Open(true)} type="primary" htmlType="submit" size='large' className='btn-primary-outline pe-5 ps-5'>Delete Event</Button>
                                        </Col>
                                        <Col className="gutter-row text-center pt-5 pb-5" sm={12} lg={12}>
                                            <a href={'#event-update/' + events._id}>
                                                <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Edit Event</Button>
                                            </a>
                                        </Col>
                                    </Row>
                                    :

                                    <>
                                        {eventType == 'upcoming' ?
                                            <Row gutter={24} className="center">
                                                <Col className="gutter-row text-center pt-5 pb-5" xs={24} lg={4}>
                                                    <Spin spinning={loading}>
                                                        <Button onClick={() => joinEvent()} type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Join Event</Button>
                                                        {/* {userDetail.user_type === "2" && userDetail.user_type === "1" ?
                                                            <Button onClick={() => joinEvent()} type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Join Event</Button>
                                                            :
                                                            events?.event_type == 1 ?
                                                                <PayNow
                                                                    className="ant-btn ant-btn-primary ant-btn-lg"
                                                                    title='Join Event'
                                                                    successMessage='payment done,creating order please wait....'
                                                                    stripe={stripe}
                                                                    clientSecret={clientSecret}
                                                                    onClick={() => {
                                                                        createPaymentIntent()
                                                                    }}
                                                                    onPaymentSuccess={() => {
                                                                        joinEvent();
                                                                        console.log("wow, payment done....store the order info into db now.");
                                                                    }}
                                                                />
                                                                :
                                                                <Button onClick={() => joinEvent()} type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Join Event</Button>
                                                        } */}
                                                    </Spin>
                                                </Col>

                                            </Row>
                                            : ""
                                        }
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>

                    {/* delete modal */}

                    <Modal
                        title=""
                        visible={modal2Open}
                        onOk={onDelete}
                        onCancel={hideModal}
                        cancelText="No"
                        okText="Yes"
                        className='filter-modal'
                    >
                        <div className='mb-4 pt-3 text-center'>
                            <p>Are you sure you want to<br></br> delete this event?</p>
                        </div>
                    </Modal>

                    {/* Boothspaces modal */}

                    <Modal
                                    title="Add Description (Booth spaces etc.)"
                                    visible={modalAddOpen}
                                    onOk={onAddDescription}
                                    onCancel={hideModal}
                                    cancelText="Cancel"
                                    okText="Submit"
                                    className='filter-modal'
                                    confirmLoading={confirmLoading}
                                >
                                    <Form
                                        name="description"
                                        className="pt-3 pb-5 change-password-form"
                                        valuepropname="fileList"
                                    >
                                        <Form.Item>
                                            <textarea
                                                onChange={(e) => {
                                                    setDescriptionValue(e.target.value);
                                                }}
                                                className="ant-input"
                                                placeholder="Enter description (Booth spaces etc.)"
                                            />
                                        </Form.Item>
                                     
                                    </Form>
                                </Modal>

                    {/* /////content end */}
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default EventsDetails