import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Row, Col, Form, Input, Spin, Button, Select } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchOtherUserData ,markAsFavorite} from '../../actions/users';
import { useDispatch } from 'react-redux';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";
import bookmarkun from "../../assets/img/icons/bookmarkun.png";
import bookmark from "../../assets/img/icons/bookmark.png";
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;

function OtherProfile() {
    const { addToast } = useToasts();
    const [profile, setProfile] = useState(0);
    const [picture, setPicture] = useState('');
    const dispatch = useDispatch();
    const [fields, setFields] = useState([]);
    const location = useLocation();
    const pathID = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const profileDetails = async () => {
        let data = {
            _id: pathID
        }
        dispatch(fetchOtherUserData({
            data,
            successCb: (response) => {
                setProfile(response.data);
                setPicture(response.data.profile_pic === '' ? defaultIcon : fileUrl + response.data.profile_pic);
                setFields([
                    {
                        name: ['business_hour'],
                        value: response.data.timing?.start === '' ? '--' : response.data.timing?.start + response.data.timing?.end === '' ? '--' : response.data.timing?.end,
                    },
                    {
                        name: ['full_name'],
                        value: response.data.full_name,
                    },
                    {
                        name: ['user_name'],
                        value: response.data.user_name,
                    },
                    {
                        name: ['ein_number'],
                        value: response.data.ein_number,
                    },
                    {
                        name: ['location'],
                        value: response.data.location?.name,
                    },
                    {
                        name: ['email'],
                        value: response.data.email,
                    },
                    {
                        name: ['phone_number'],
                        value: "+" + response.data.country_code + response.data.phone_number,
                    },
                    {
                        name: ['website'],
                        value: response.data.website,
                    },
                    {
                        name: ['company'],
                        value: response.data.company,
                    },
                ])
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }
    
    function markFavorite(id) {
        let data = {
            _id: id,
        }

        dispatch(markAsFavorite({
            data,
            successCb: (response) => {
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                profileDetails();
            },
            failureCb: (response) => {
                addToast(response, { appearance: 'success', autoDismiss: true });
            }
        }))
    }

    useEffect(() => {
        profileDetails();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Profile</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" md={24} xs={24} lg={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5"
                                valuepropname="fileList"
                                fields={fields}
                            >
                                <Form.Item label="" valuepropname="fileList">
                                    <div className='mb-3'>
                                        <div className="bg-cover" style={{ width: "100%", height: "250px", background: 'url(' + picture + ')' }} >

                                            <Col className="gutter-row event-avtar" onClick={() => markFavorite(profile._id)} md={18} xs={18} lg={21} style={{float: "right",top: "1rem",right: "1rem"}}>
                                                {profile.is_fav ?
                                                    <img src={bookmark} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                    :
                                                    <img src={bookmarkun} style={{ width: '30px', float: "right", cursor: "pointer" }} />
                                                }
                                            </Col>
                                        </div>
                                    </div>
                                </Form.Item>
                                {profile.user_type === "1" ?
                                    <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                        <Row gutter={24}>
                                            <Col className="gutter-row" span={12}>
                                                <h6>Business Hours:</h6>
                                            </Col>
                                            <Col className="gutter-row text-right" span={12}>
                                                <p>{profile.timing?.start === '' ? '--' : profile.timing?.start + profile.timing?.end === '' ? '--' : profile.timing?.end}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ""}

                                <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                    <Row gutter={24}>
                                        <Col className="gutter-row" span={12}>
                                            <h6>Full Name:</h6>
                                        </Col>
                                        <Col className="gutter-row text-right" span={12}>
                                            <p>{profile.full_name}</p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                    <Row gutter={24}>
                                        <Col className="gutter-row" span={12}>
                                            <h6>Username:</h6>
                                        </Col>
                                        <Col className="gutter-row text-right" span={12}>
                                            <p>{profile.user_name}</p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                    <Row gutter={24}>
                                        <Col className="gutter-row" span={12}>
                                            <h6>Email:</h6>
                                        </Col>
                                        <Col className="gutter-row text-right" span={12}>
                                            <p>{profile.email}</p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                    <Row gutter={24}>
                                        <Col className="gutter-row" span={12}>
                                            <h6>Phone:</h6>
                                        </Col>
                                        <Col className="gutter-row text-right" span={12}>
                                            <p>{profile.country_code + profile.phone_number}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                {profile.user_type === '0' ?
                                    <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                        <Row gutter={24}>
                                            <Col className="gutter-row" span={12}>
                                                <h6>Event Website:</h6>
                                            </Col>
                                            <Col className="gutter-row text-right" span={12}>
                                                <p>{profile.website === "" ? "--" : profile.website}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ""}
                                {profile.user_type === '0' ?
                                    <Col className="gutter-row pt-4 pb-2 bg-gray mt-3" span={24}>
                                        <Row gutter={24}>
                                            <Col className="gutter-row" span={12}>
                                                <h6>Company:</h6>
                                            </Col>
                                            <Col className="gutter-row text-right" span={12}>
                                                <p>{profile.company === "" ? "--" : profile.company}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    : ""}

                            </Form>
                            <label className='mb-3 bold'>Photos</label>
                                <Form.Item className='pe-5'>
                                    <Row gutter={24}>
                                    {profile? profile?.profile_images.length > 0 && profile?.profile_images ? profile?.profile_images.map((element, index) => {
                                    return (
                                        <Col className="gutter-row mb-3" span={6}>
                                            <div style={{background:`url(${fileUrl+element.value})`,width: "200px",height: "200px",backgroundPosition: "center",backgroundSize:"cover"}}>
                                                
                                            </div>
                                        </Col>
                                        )
                                    }) :
                                        <Row className='center pt-5 mt-5'>
                                            <Col>
                                                <h3 className='pt-5'>No images found yet!</h3>
                                            </Col>
                                        </Row>
                                    :""}
                                    </Row>
                                </Form.Item>
                            {profile.user_type != "0" ?
                                <Row gutter={24} className="center">
                                    <Col className="gutter-row text-center pt-5 pb-5" span={6}>
                                        <a href={'/#/discounts/' + profile._id}>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Discount Offered</Button>
                                        </a>
                                    </Col>
                                </Row>
                                : ""}
                        </Col>
                    </Row>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default OtherProfile