import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Modal, Button, Skeleton, Image } from 'antd';
import { useLocation } from 'react-router-dom';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchDiscounts } from '../../actions/offers';
import notFound from "../../assets/img/not-found.png";
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { fileUrl } from '../../constants/const';
import defaultIcon from "../../assets/img/logo/ice-logo.svg";

const { Content } = Layout;

function Discounts() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState('');
    const [modalDelOpen, setModalDelOpen] = useState(false);
    const [singleOffer, setSingleOffer] = useState('');
    const location = useLocation();
    const pathID = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const getOffers = async () => {
        setLoading(true)
        let data = {
            _id: pathID
        }
        dispatch(fetchDiscounts({
            data,
            successCb: (response) => {
                setLoading(false)
                setOffers(response.data)
            },
            failureCb: (response) => {
                setLoading(false)
                setOffers('')
            }
        }))
    }

    function hideModal() {
        setModalDelOpen(false)
    }

    function viewQr(data) {
        setModalDelOpen(true)
        setSingleOffer(data)
    }

    useEffect(() => {
        getOffers();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////profile detail section */}
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="">
                            <Col className="gutter-row pb-2" xs={24} lg={16}>
                                <div>
                                    <h5 className='color-theme semi-bold'>Discounts</h5>
                                </div>
                            </Col>
                            <Col className="gutter-row pb-2" xs={24} lg={16}>
                                {offers.length > 0 && offers ? offers.map((element, index) => {
                                    return (
                                        <Row gutter={24} className="gutter-row p-3 pb-1 bg-gray mt-3" onClick={() => viewQr(element)}>
                                            <Col className="gutter-row pt-5" span={4}>
                                                <Image style={{borderRadius:"50px",height:"100px",width:"100px",border: "2px solid #241f20"}} src={element.tag?.tag_image === '' ? defaultIcon : fileUrl + element.sub_tag?.tag_image} />
                                            </Col>
                                            <Col className="gutter-row text-center" span={16}>
                                                <p className='mb-0'>Valid From</p>
                                                <h6>{element.valid_from === '' ? '--' : element.valid_from}</h6>
                                                <p className='mb-0'>Valid Till</p>
                                                <h6>{element.valid_till === '' ? '--' : element.valid_till}</h6>
                                                <hr></hr>
                                                <p className='mb-0'>Offer Type</p>
                                                <h6>{element.type === "0" ? "Multiple Time use" : "One Time use"}</h6>
                                            </Col>
                                            <Col className="gutter-row text-right offers_ pt-5" span={4}>
                                                <div className='discount-'>
                                                    <h6 style={{fontSize:"2rem",color:"#fff",marginBottom:"5px"}}>{element.discount}%</h6>
                                                    <h6 className='text-white'>off</h6>
                                                </div>                                                
                                            </Col>                                           
                                        </Row>
                                    )
                                }) :
                                    <Row className='center pt-5 mt-5'>
                                        <Col>
                                            <img src={notFound} style={{ width: "300px" }} />
                                            <h3 className='pt-5'>No offers found yet!</h3>
                                        </Col>
                                    </Row>
                                }
                               
                                <Modal
                                    title=""
                                    visible={modalDelOpen}
                                    onCancel={hideModal}
                                    okText="Continue"
                                    className='filter-modal'
                                    onOk={hideModal}
                                >
                                    <div className='mb-4 pt-3 text-center'>
                                        <Image src={singleOffer.code} />
                                        <p>{singleOffer.description}</p>
                                        <h4>Discount Offered</h4>
                                        <h6 style={{fontSize:"2.5rem"}}><strong>{singleOffer.discount}%</strong></h6>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Skeleton>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default Discounts