import axios from "axios";
const baseUrl = 'http://solidappmaker.ml:5005/api/v1/';

export const apiCall = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: config.method,
            url: config.url,
            headers: config.headers,
            data: config.data || {},
            timeout: 70000,
        })
            .then(async (response) => {
                if(response.data.status)                
                    resolve(response);    
                else
                    reject(response);                            
            })
            .catch(async (error) => {
                let response = error.response;
                if (response.status === 401) {
                    // refreshToken()
                    document.location.href="#login"
                }
                reject(error);
            });
    });
};

export const refreshToken = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token'), 'Content-Type': 'application/json' },
            url: `${baseUrl}user/refreshToken`,
            data: {
                "userId": sessionStorage.getItem("userID")
            }
        })
            .then(async (response) => {
                resolve(response);
                console.log(response)
            })
            .catch(async (error) => {
                console.log( error)
        });
    });
};


