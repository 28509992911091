import React, { useState, useRef, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button, DatePicker, Upload, Radio, Select, Empty } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { googleKey, fileUrl } from '../../constants/const';
import { CameraFilled } from '@ant-design/icons';
import { updateEvent, getEventTypeData, fetchEventsDetails } from '../../actions/event';
import moment from 'moment';
import timezone from 'moment-timezone';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import e from 'cors';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

function EventsUpdate() {
    const [loading, setLoading] = useState(false);
    const [oldpic, setOldPic] = useState('block');
    const [location, setLocation] = useState('');
    const inputRef = useRef();
    const [eventType, setEventType] = useState("0");
    const [eventTypeId, setEventTypeId] = useState("");
    const [getEventTypeId, setGetEventTypeId] = useState("");
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventPics, setEventPics] = useState([]);
    const [events, setEvents] = useState(0);
    const [eventBluepringPics, setBlueprintEventPics] = useState([]);
    const [fields, setFields] = useState([]);
    let timeZ = timezone.tz.guess();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const location_ = useLocation();
    const pathID = location_.pathname.substring(location_.pathname.lastIndexOf('/') + 1)
    const userDetail = JSON.parse(sessionStorage.getItem('user_'));
    const [addPlaceName, setAddPlaceName] = useState('')

    const eventsDetails = async () => {
        let data = {
            _id: pathID,
            user_type: userDetail.user_type === "0" ? "producer" : userDetail.user_type === "1" ? "merchant" : "vendor"
        }

        dispatch(fetchEventsDetails({
            data,
            successCb: (response) => {
                setEvents(response.data);
                setStartTime(response.data.event_start_time);
                setEndTime(response.data.event_end_time);
                setEventTypeId(response.data.event_type_id?._id);
                let locate = {
                    "cordinates": { "lat": response.data.location?.cordinates?.lat, "long": response.data.location?.cordinates?.long }, "name": response.data.location?.name
                }
                setLocation(locate);
                setEventType(JSON.stringify(response.data.event_type))
                setAddPlaceName(response?.data?.location?.name);
                setFields([
                    {
                        name: ['event_name'],
                        value: response.data.event_name,
                    },
                    {
                        name: ['event_tags'],
                        value: response.data.search_tags
                    },
                    {
                        name: ['description'],
                        value: response.data.description
                    },
                    {
                        name: ['vacancies'],
                        value: response.data.vacancies
                    },
                    {
                        name: ['amount'],
                        value: response.data.amount
                    },
                    {
                        name: ['event_type_id'],
                        value: response.data.event_type_id?._id
                    },
                    {
                        name: ['event_type'],
                        value: response.data.event_type
                    },
                    {
                        name: ['link'],
                        value: response.data.link
                    }
                ])
                getEventType();
            },
            failureCb: (response) => {
                console.log(response)
            }
        }))
    }

    const onChangeEventType = (e) => {
        setEventType(e.target.value);
    };

    const selectEventType = (value) => {
        setEventTypeId(value);
    };

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        // console.log(">>> handlePlaceChanged ::: ",place)
        if (place) {
            let locate = {
                "cordinates": { "lat": place.geometry.location.lat(), "long": place.geometry.location.lng() }, "name": place.formatted_address
            }         
            setLocation(locate);
            setAddPlaceName(place.formatted_address);
        }
    }

    const onChangeDate = (value, dateString) => {
        setStartTime(dateString[0])
        setEndTime(dateString[1])
    };

    const eventImage = (e) => {
        setOldPic('none')
        setEventPics(e.file.originFileObj);
    };

    const eventBlueprintImage = (e) => {
        setBlueprintEventPics(e.file.originFileObj);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    ///get event types
    const getEventType = () => {
        dispatch(getEventTypeData({
            successCb: async (response) => {
                setGetEventTypeId(response.data);
            },
            failureCb: (response) => {
                //////
            }
        }))
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        console.log(current)
        return current && current < moment().startOf('day');
    };
    
    function disabledDateTime() {
        // Disable all times before current time
        const currentHour = moment().hour()+1;
        const currentMinute = moment().minute();
        return {
          disabledHours: () => [...Array(currentHour).keys()],
        };
      }

    function datepicker() {
        if (events?.event_start_time) {
            return (
                <RangePicker
                    showTime={{
                        format: 'HH:mm',
                    }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={onChangeDate}
                    defaultValue={[moment(events.event_start_time, 'YYYY-MM-DD HH:mm'), moment(events.event_end_time, 'YYYY-MM-DD HH:mm')]}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                />
            )
        }
    }

    ///update event
    const onFinish = (value) => {
        const formData = new FormData();
        
        const startDatetime = moment(startTime);
        const endDatetime = moment(endTime);

        const duration = moment.duration(endDatetime.diff(startDatetime));
        if(duration.asHours() < 3){
            addToast("Start Date/Time must be greater then current date time by 3 hours", { appearance: 'error', autoDismiss: true });
            return;
        }

        formData.append('event_name', value.event_name);
        formData.append('event_type', eventType);
        formData.append('location', JSON.stringify(location));
        formData.append('event_start_time', startTime);
        formData.append('event_end_time', endTime);
        formData.append('time_zone', timeZ);
        formData.append('vacancies', value.vacancies);
        formData.append('description', value.description);
        formData.append('event_type_id', eventTypeId);
        formData.append('search_tags', value.event_tags);
        formData.append('link', value.link);
        if(eventType ==="1")
        formData.append('amount', value.amount);

        if (eventPics.length != 0)
            formData.append('event_image', eventPics);
        if (eventBluepringPics.length != 0)
            formData.append('event_blueprint', eventBluepringPics);
        //////
        setLoading(true);
        dispatch(updateEvent({
            formData,
            pathID,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#events'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    useEffect(() => {
        eventsDetails();
    }, [])

    const onPlaceChange=(inputVal)=>{
        console.log("inputVal ::: ",inputVal)
        setAddPlaceName(inputVal)
    }

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////event update section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" span={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Update Event</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" span={16}>
                            <Form
                                fields={fields}
                                name="upload"
                                className="pt-3 pb-5"
                                initialValues={{
                                    remember: true,
                                }}
                                getValueFromEvent={normFile}
                                onFinish={onFinish}
                                valuepropname="fileList"
                            >
                                <Form.Item label="" valuepropname="fileList">
                                    <div className='mb-3' style={{ display: oldpic }}>
                                        <img style={{ width: "100px" }} src={fileUrl + events.event_image}></img>
                                    </div>

                                    <Upload onChange={eventImage} name="logo" className="col-lg-12" listType="picture-card"
                                    >
                                        {
                                            eventPics.length == 0 ?
                                                <div>
                                                    <CameraFilled />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Upload Photo
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="event_type_id"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Type!',
                                    }]}
                                >
                                    <Select
                                        defaultValue=""
                                        className='col-12'
                                        style={{
                                            background: "#F6F6F6",
                                        }}
                                        onChange={selectEventType}
                                    >

                                        {getEventTypeId.length > 0 && getEventTypeId ? getEventTypeId.map((element, index) => {
                                            return (
                                                <Option value={element._id}>{element.name}</Option>
                                            )
                                        }) :
                                            <Option value="">Event Type</Option>
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="event_name"

                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Name!',
                                    }]}

                                >
                                    <Input placeholder="Event Name" />
                                </Form.Item>

                                <Form.Item
                                    name="location"
                                >
                                  
                                    <LoadScript googleMapsApiKey={googleKey} libraries={["places"]}>
                                        <StandaloneSearchBox
                                            onLoad={ref => inputRef.current = ref}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <input
                                                type="text"
                                                className="ant-input"
                                                placeholder="Location"
                                                value={addPlaceName}
                                                onChange={(e)=> onPlaceChange(e.target.value)}
                                            />
                                        </StandaloneSearchBox>
                                    </LoadScript>
                                </Form.Item>
                                <Form.Item
                                    name="event_tags"
                                >
                                    <Input placeholder="Search Tags eg:- abc,xyz" />
                                </Form.Item>
                                <Form.Item>
                                    {datepicker()}
                                </Form.Item>
                                <Form.Item
                                    name="link"
                                >
                                    <Input placeholder="Website Name" />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    rules={[{
                                        required: true,
                                        message: 'Please input your Event Time!',
                                    }]}
                                >
                                    <TextArea rows={4} placeholder="Description" />
                                </Form.Item>
                                <Radio.Group onChange={onChangeEventType} value={eventType} className="mb-4">
                                    <Radio value={"0"}>Free</Radio>
                                    <Radio value={"1"}>Paid</Radio>
                                </Radio.Group>

                                <Row gutter={16}>
                                    {eventType === "1" ?
                                        <>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="amount"
                                                    rules={[{
                                                        required: true,
                                                        message: 'Please input your value!',
                                                    }]}
                                                >
                                                    <Input placeholder="Enter amount" />
                                                </Form.Item>
                                            </Col>
                                        </>
                                        : ""}
                                    <Col span={8}>
                                        <Form.Item
                                            name="vacancies"
                                            rules={[{
                                                required: true,
                                                message: 'Please input your value!',
                                            }]}
                                        >
                                            <Input placeholder="No. of spaces available:" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Form.Item label="" className='pt-3'>
                                    <Form.Item name="dragger" valuepropname="fileList" getValueFromEvent={normFile} noStyle>
                                        <Upload.Dragger onChange={eventBlueprintImage} name="files" action="" className='pt-5 pb-5'>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">

                                                <p className='green'>{events.event_blueprint != 0 ? events.event_blueprint : ""}</p>
                                                Please provide the blueprint of the area so it would be <br></br>
                                                convenient for the people to find their required arena (Optinal)
                                            </p>
                                        </Upload.Dragger>

                                    </Form.Item>
                                </Form.Item>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Col className="gutter-row text-center" lg={24}>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>Update</Button>
                                        </Col>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default EventsUpdate