import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Layout, Button, Form, Input, Row, Col, Spin } from 'antd';
import logo from '../../assets/img/logo/ice-logo.svg'
import bg from '../../assets/img/bg/reset-password.png'
import { resetPassword } from '../../actions/auth';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

const { Content } = Layout;

function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const gotID = useSelector(state => state.authreducer.data._id);
    
    const onFinish = (values) => {        
        if(values.password != values.cpassword){
            addToast("Both password does not match!", { appearance: 'error', autoDismiss: true });
            return;
        }

        setLoading(true);
        let data = {
            _id: gotID,
            password: values.password,
        }
        dispatch(resetPassword({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });
                window.location.href = '#login'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    };

    return (
        <Layout className="layout">
            <Content>

                {/* register section */}

                <div className='container-fluid'>
                    <Row gutter={24} className="bg-white" style={{ height: "100vh" }}>
                        <Col className="gutter-row background-theme mnone" lg={12} xs={24}>
                            <div className='auth-bg mt-5 ball text-center'>
                                <img src={bg} style={{ width: "380px" }} />
                            </div>
                        </Col>
                        <Col className="gutter-row auth xs-plr-1 xs-mt-0 p-5 mt-5" lg={12} xs={24}>
                            <div className="text-center center d-flex pb-5 pt-5">
                                <div className="logo">
                                    <a href='#'>
                                        <img style={{ height: "100px" }} src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4><strong>New Password </strong></h4>
                                <p className='text-gray'>Please enter your New password</p>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form pt-3"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="cpassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Confirm Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Confirm Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Button type="primary" htmlType="submit" className="login-form-button col-lg-12 pt-4 pb-5">
                                            SUBMIT
                                        </Button>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout >
    )
}

export default ResetPassword