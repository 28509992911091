import React, { useState } from 'react';
import { Layout, Button, Form, Input, Row, Col, Spin } from 'antd';
import logo from '../../assets/img/logo/ice-logo.svg'
import bg from '../../assets/img/bg/forgot.svg'
import { forgotPassword } from '../../actions/auth';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

const { Content } = Layout;

function Forgot() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const onFinish = (values) => {
        setLoading(true);
        let data = {
            email: values.email
        }
        dispatch(forgotPassword({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });
                window.location.href = '#verification'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    };

    return (
        <Layout className="layout">
            <Content>

                {/* forgot password section */}

                <div className='container-fluid'>

                    <Row gutter={24} className="bg-white" style={{ height: "100vh" }}>
                        <Col className="gutter-row background-theme mnone" lg={12} xs={24}>
                            <div className='auth-bg mt-5 ball'>
                                <img src={bg} />
                            </div>
                        </Col>
                        <Col className="gutter-row auth xs-plr-1 xs-mt-0 p-5 mt-5" lg={12} xs={24}>
                            <div className="text-center center d-flex pb-5 pt-5">
                                <div className="logo">
                                    <a href='#'>
                                        <img style={{ height: "150px" }} src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4><strong>Forgot Password? </strong></h4>
                                <p className='text-gray'>Enter the email associated with your account.</p>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form pt-3"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >

                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Button type="primary" htmlType="submit" className="login-form-button col-lg-12 pt-4 pb-5">
                                            NEXT
                                        </Button>
                                    </Spin>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </div>

            </Content>
        </Layout>
    )
}

export default Forgot