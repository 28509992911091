import { combineReducers } from 'redux';
import authreducer from '../reducers/auth';
import users from '../reducers/users';
import event from '../reducers/event';
import { LOGOUT_SUCCESS } from '../actions/types'

const appReducer = combineReducers({
    authreducer,   
    users,   
    event,   
});


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer;