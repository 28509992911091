import React, { useState } from 'react';
import { Layout, Row, Col, Form, Input, Spin, Button, DatePicker, Upload, Radio } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { CameraFilled } from '@ant-design/icons';
import { createOffer } from '../../actions/offers';
import moment from 'moment';
import timezone from 'moment-timezone';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';

const { Content } = Layout;
const { RangePicker } = DatePicker;

function AddCustomOffer() {
    const [loading, setLoading] = useState(false);
    const [dateType, setDateType] = useState("");
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeUse, setTimeUse] = useState([]);
    const [catImage, setCatImage] = useState('');
    const [subCatImage, setSubCatImage] = useState('');
    const [fields, setFields] = useState([]);

    let timeZ = timezone.tz.guess();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const onChangeDateType = (e) => {
        setDateType(e.target.value);
        setFields([
            {
                name: ['end_date'],
                value: "",
            }
        ])     
    };

    const onChangeTimeUse = (e) => {
        setTimeUse(e.target.value);
    };

    const catImageChange = (e) => {
        setCatImage(e.file.originFileObj);
    };

    const subCatImageChange = (e) => {
        setSubCatImage(e.file.originFileObj);
    };

    ////calander part
    const onChangeStartTime = (value, dateString) => {
        setStartTime(dateString)
    };

    const onChangeEndTime = (value, dateString) => {
        setEndTime(dateString)
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    ////calander part end

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const onFinish = (value) => {
        if(value.discount < 15){
            addToast("discount value should be greater then 15", { appearance: 'error', autoDismiss: true });
            return;
        }
        
        
        const formData = new FormData();
        formData.append('discount', value.discount);
        formData.append('description', value.offer_detail);
        formData.append('sub_tag_id', value.sub_tag_id);
        formData.append('tag_id', value.tag_id);
        formData.append('time_zone', timeZ);
        formData.append('valid_from', startTime);
        formData.append('valid_till', dateType===""?endTime:'');
        formData.append('type', timeUse);
        formData.append('custom_tag', 1);
        formData.append('custom_sub_tag', 1);
        formData.append('tag_image', catImage);
        formData.append('sub_tag_image', subCatImage);

        setLoading(true);
        dispatch(createOffer({
            formData,
            successCb: async (response) => {
                setLoading(false);
                addToast(response.message, { appearance: 'success', autoDismiss: true });
                window.location.href = '#offers'
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    }

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 280,
                    }}
                >
                    {/* /////Offer create section */}
                    <Row gutter={24} className="">
                        <Col className="gutter-row pb-2" xs={24} lg={16}>
                            <div>
                                <h5 className='color-theme semi-bold'>Add Offer</h5>
                            </div>
                        </Col>
                        <Col className="gutter-row pb-2" xs={24} lg={16}>
                            <Form
                                name="upload"
                                className="pt-3 pb-5 custom_offer_"
                                initialValues={{
                                    remember: true,
                                }}
                                getValueFromEvent={normFile}
                                onFinish={onFinish}
                                valuepropname="fileList"
                                fields={fields}
                            >
                                <Row gutter={24} className="">
                                    <Col className="gutter-row pb-2" xs={16} lg={20}>
                                        <Form.Item
                                            name="tag_id"
                                            rules={[{
                                                required: true,
                                                message: 'Enter Category',
                                            }]}
                                        >
                                            <Input placeholder="Enter Category" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row pb-2" xs={8} lg={4}>
                                        <Form.Item label="" valuepropname="fileList">                                        
                                            <Upload onChange={catImageChange} name="logo" className="col-lg-12" listType="picture-card">
                                                {catImage === "" ?
                                                    <div>
                                                        <CameraFilled />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Add Image
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24} className="">
                                    <Col className="gutter-row pb-2" xs={16} lg={20}>
                                        <Form.Item
                                            name="sub_tag_id"
                                            rules={[{
                                                required: true,
                                                message: 'Enter Sub Category',
                                            }]}
                                        >
                                            <Input placeholder="Enter Sub Category" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row pb-2" xs={8} lg={4}>
                                        <Form.Item label="" valuepropname="fileList">                                        
                                            <Upload onChange={subCatImageChange} name="logo" className="col-lg-12" listType="picture-card">
                                                {subCatImage === "" ?
                                                    <div>
                                                        <CameraFilled />
                                                        <div
                                                            style={{
                                                                marginTop: 8,
                                                            }}
                                                        >
                                                            Add Image
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={disabledDate}
                                        onChange={onChangeStartTime}
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm'),
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                 name="end_date" 
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={disabledDate}
                                        onChange={onChangeEndTime}
                                        showTime={{
                                            defaultValue: moment('00:00:00', 'HH:mm'),
                                        }}
                                    />
                                </Form.Item>

                                <Radio.Group onChange={onChangeDateType} className="mb-4">
                                    <Radio value={"0"}>No End Date</Radio>
                                </Radio.Group>

                                <Form.Item
                                    name="discount"
                                    rules={[{
                                        required: true,
                                        message: 'Enter Discount In % / Description',
                                    }]}
                                >
                                    <Input placeholder="Enter Discount In % / Description" />
                                </Form.Item>

                                <Form.Item
                                    name="offer_detail"
                                    rules={[{
                                        required: true,
                                        message: 'Enter offer detail',
                                    }]}
                                >
                                    <Input placeholder="Enter Details" />
                                </Form.Item>

                                <Radio.Group defaultValue={"1"} onChange={onChangeTimeUse} value={timeUse} className="mb-4">
                                    <Radio value={"1"}>One time use</Radio>
                                    <Radio value={"0"}>Multiple time use</Radio>
                                </Radio.Group>

                                <Form.Item className='pe-5 ps-5 mt-5'>
                                    <Spin spinning={loading}>
                                        <Col className="gutter-row text-center" lg={24}>
                                            <Button type="primary" htmlType="submit" size='large' className='pe-5 ps-5'>CREATE</Button>
                                        </Col>
                                    </Spin>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default AddCustomOffer