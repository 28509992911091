import React, { useState,useEffect } from 'react';
import { Layout, Button, Spin, Form, Input, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import logo from '../../assets/img/logo/logo.svg'
import login from '../../assets/img/bg/login.png'
import { loginUser } from '../../actions/auth';
import { useToasts } from 'react-toast-notifications';

const { Content } = Layout;

function Login() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts()
    const onFinish = (values) => {
        setLoading(true);
        let data = {
            email: values.email,
            password: values.password,
            fcm_token: '*',
        }
        dispatch(loginUser({
            data,
            successCb: async (response) => {
                setLoading(false);
                addToast(response, { appearance: 'success', autoDismiss: true });               
                document.location.href = '#events'                               
            },
            failureCb: (response) => {
                setLoading(false);
                addToast(response, { appearance: 'error', autoDismiss: true });
            }
        }))
    };

    useEffect(() => {
        // let sess = sessionStorage.getItem('token')
        // if(sess !==null){
        //     document.location.href="#/events"
        // }
    }, [])


    // 
    return (
        <Layout className="layout">
            <Content>
                {/* login section */}
                <div className='container-fluid'>
                    <Row gutter={24} className="bg-white" style={{ height: "100vh" }}>
                        <Col className="gutter-row background-theme mnone" lg={12} xs={24}>
                            <div className='auth-bg mt-5 ball'>
                                <img src={login} />
                            </div>
                        </Col>
                        <Col className="gutter-row xs-plr-1 xs-mt-0 p-5 mt-5" lg={12} xs={24}>
                            <div className="text-center auth center d-flex pb-5 pt-5">
                                <div className="logo">
                                    <a href='#'>
                                        <img src={logo} />
                                    </a>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h4><strong>Login</strong></h4>
                                <p className='text-gray'>Please login to continue using our app</p>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form pt-3"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email!',
                                        }
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        }
                                    ]}                                   
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Password"
                                        style={{height:"59px"}}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <a className="login-form-forgot pull-right text-dark" href="#forgot">
                                        Forgot password?
                                    </a>
                                </Form.Item>

                                <Form.Item className='pe-5 ps-5'>
                                    <Spin spinning={loading}>
                                        <Button type="primary" htmlType="submit" className="login-form-button col-lg-12 pt-4 pb-5">
                                            LOGIN
                                        </Button>
                                    </Spin>
                                </Form.Item>
                                <div className='pt-5 mt-5 text-center'>
                                    Don't have an account?
                                    <a className="login-form-forgot text-dark" href="#register"><b> Sign Up</b></a>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    )
}

export default Login