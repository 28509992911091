import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Collapse, Skeleton, Button, Spin } from 'antd';
import Header_ from '../../components/Header_'
import Footer_ from '../../components/Footer_'
import Sidebar_ from '../../components/Sidebar_';
import { fetchUserData ,getLinkAccount,updateProfile} from '../../actions/users';
import { useDispatch } from 'react-redux';
const { Content } = Layout;
const { Panel } = Collapse;

function AccountDetails() {
    const dispatch = useDispatch();
    const [faq, setFaq] = useState('')
    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetails] = useState('');

    var a = document.location.href; 
    var b = a.substring(a.indexOf("?")+1);
    let prm = b.split('=')
    if(prm[1]==='success'){
       updateAccount()
    }

    const loadUserData = async () => {
        dispatch(fetchUserData({
            successCb: (response) => {
                setUserDetails(response.data);
            },
            failureCb: (response) => {
                //// 
            }
        }))
    }

    function updateAccount(){
        const formData = new FormData();
        formData.append('account_linked', true);
        dispatch(updateProfile({
            formData,
            successCb: (response) => {
                console.log("yeesss")
                document.location.href="#/account-details"
            },
            failureCb: (response) => {
                //// 
            }
        }))
    }

    const linkAccount = async () => {
        dispatch(getLinkAccount({
            successCb: (response) => {
                ////
                window.open(response.data.url)
            },
            failureCb: (response) => {
                //// 
            }
        }))
    }


    useEffect(() => {
        loadUserData();
    }, [])

    return (
        <Layout
            className="site-layout"
            style={{
                marginLeft: 300,
            }}
        >
            <Sidebar_ />
            <Layout className="site-layout">
                <Header_ />
                <Content
                    className="site-layout-background p-5"
                    style={{
                        margin: '25px 30px',
                        minHeight: 680,
                    }}
                >
                    <Skeleton loading={loading} active avatar>
                        <Row gutter={24} className="">
                            <Col className="gutter-row pb-4" span={21}>
                                <div>
                                    <h5 className='color-theme semi-bold'>Account Details</h5>
                                </div>
                            </Col>
                            <Col className="gutter-row pb-2 text-center mt-5" span={24}> 
                                {userDetail?.account_linked ?
                                    <div className='mt-5'>
                                        <Spin spinning={loading}>
                                            <Button onClick={() => linkAccount()} type="primary" size='large' className='pe-5 ps-5'>+ Edit Account</Button>
                                        </Spin>
                                    </div>
                                    :
                                    <>
                                        <div className='mt-5'>
                                            <h3 className='pt-5 pb-5' >Account Saved</h3>
                                            <Spin spinning={loading}>
                                                <Button onClick={() => linkAccount()} type="primary" size='large' className='pe-5 ps-5'>+ Account Link</Button>
                                            </Spin>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Skeleton>
                </Content>
                <Footer_ />
            </Layout>
        </Layout>
    )
}

export default AccountDetails